import { ResponseCode } from 'api/';
import { getApi } from 'api/utils';
import { getSubdomain } from 'app/utils';

const api = getApi();

export const sendReceiptOnDemand = async (
  checkCode: string,
  paymentId: number,
  emailAddress: string
) => {
  const UTCOffset = new Date().getTimezoneOffset();
  const subdomain = getSubdomain();
  try {
    const response = await api.sendReceiptOnDemand(
      checkCode,
      paymentId,
      emailAddress,
      subdomain,
      UTCOffset
    );
    return {
      message: response?.data.Message,
      responseCode:
        response?.data.Status === 0 ? ResponseCode.OK : ResponseCode.Error,
    };
  } catch (error: any) {
    return {
      message:
        'Uh oh! Something has gone wrong. Please wait a moment and try again.',
      responseCode: ResponseCode.BadRequest,
    };
  }
};
