import { getApi } from 'api/utils';

const api = getApi();

export const getCheckInfo = (checkCode: string, firstLoad: boolean) =>
  api.getCheck(checkCode, firstLoad);

export const getSiteConfig = (checkCode: string) =>
  api.getSiteConfig(checkCode);

export const getThemeConfig = (subdomain: string) =>
  api.getThemeConfig(subdomain);

export const getAppliedPaymentInfo = (checkCode: string) =>
  api.getAppliedPaymentInfo(checkCode);

  export const getAmountPaid = (paymentId: any) =>
  api.getAmountPaid(paymentId);

