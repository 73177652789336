import { ResponseCode } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ApplyButton } from 'components/ApplyButton';
import { PaymentSectionDivider } from 'components/Divider/PaymentSectionDivider';
import { GiftCardSummary } from 'components/GiftCardSummary';
import { PaymentSectionHeader } from 'components/PaymentSectionHeader';
import { useCheckData } from 'features/Common/useCheckData';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import { validateGiftCardInputs } from 'features/Common/validation';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import _, { List } from 'lodash';
import {
  selectPaymentState,
  updatePaymentTotal,
} from 'pages/Payment/paymentSlice';
import { useEffect, useState } from 'react';
import {
  generalErrorMessages,
  giftCardErrorMessages,
  modalHeaders,
} from 'types/strings';
import { getBalance, getPrefixList } from './GiftCardApi';
import {
  GiftCardInputContainer,
  GiftCardSectionContainer,
  StyledGiftCardInput,
  StyledGiftCardPinInput,
} from './GiftCardComponents';
import {
  clearGiftCardInputs,
  selectGiftCardState,
  setGiftCardAmountApplied,
  setGiftCardNumber,
  setGiftCardPin,
  setTotalGiftCardBalance,
} from './GiftCardSlice';

interface GiftCardSectionProps {
  disabled: boolean;
}

export const GiftCardSection = ({ disabled }: GiftCardSectionProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { siteConfig } = useSiteConfig();
  const ePinEnabled = siteConfig?.AsvPrefixEnabled ?? true;
  const [ePinInput, setEPinInput] = useState<string>('');
  const [giftCardInput, setGiftCardInput] = useState<string>('');
  const [ePinPrefixList, setEPinPrefixList] = useState<List<string>>([]);
  const [ePinRequired, setEPinRequired] = useState<boolean>(true);
  const [disableApplyButton, setDisableApplyButton] = useState<boolean>(true);

  const { checkDetails } = useCheckData();
  const { paymentTotal } = useAppSelector(selectPaymentState);
  const giftCardState = useAppSelector(selectGiftCardState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (ePinEnabled && siteConfig?.CompanyCode) {
      getPrefixList(siteConfig?.CompanyCode).then((data) => {
        setEPinPrefixList(data.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateEPinRequired = () => {
    if (giftCardInput.length >= 5) {
      const prefix = giftCardInput.slice(0, 5);
      return _.indexOf(ePinPrefixList, prefix) < 0;
    }
    return true;
  };

  useEffect(() => {
    const isEpinRequired = ePinEnabled && validateEPinRequired();
    setEPinRequired(isEpinRequired);
    setDisableApplyButton(
      !validateGiftCardInputs(giftCardInput, ePinInput, isEpinRequired)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftCardInput, ePinInput]);

  const handleGetBalanceError = (responseCode: number) => {
    var message = giftCardErrorMessages.giftCardNotFound;

    //for the gift card lookup, if there are too many failures, backend server returns unauthorized error.
    //if the error code needs to be used, need to change backend to use another erorr as well.
    if (responseCode === ResponseCode.Unauthorized) {
      message = generalErrorMessages.tooManyFailureAttemps;
    }

    dispatch(
      setModalOptions({
        type: 'warning',
        header: modalHeaders.invalidGiftCard,
        text: message,
      })
    );
    dispatch(openModal());
  };

  const handleGetBalanceSuccess = (balance: number) => {
    if (balance > 0) {
      dispatch(setGiftCardNumber(giftCardInput));
      dispatch(setGiftCardPin(ePinEnabled && ePinRequired ? ePinInput : '-1'));
      dispatch(setTotalGiftCardBalance(balance));
      const giftCardAmountToBeApplied =
        paymentTotal < balance ? paymentTotal : balance;
      dispatch(setGiftCardAmountApplied(giftCardAmountToBeApplied));
      dispatch(updatePaymentTotal());
    } else {
      dispatch(
        setModalOptions({
          type: 'warning',
          header: modalHeaders.noBalanceGiftCard,
          text: giftCardErrorMessages.noBalance,
        })
      );
      dispatch(openModal());
    }
  };

  const handleGiftCardApply = async () => {
    if (validateGiftCardInputs(giftCardInput, ePinInput, ePinRequired)) {
      setIsProcessing(true);
      // TODO check and handle isPaymentEnabled => show success modal and send to check page
      const sendPin = ePinEnabled && ePinRequired ? ePinInput : '-1';
      if (siteConfig?.CompanyId && checkDetails?.SiteId) {
        getBalance(
          siteConfig?.CompanyId,
          checkDetails?.SiteId,
          checkDetails.Code,
          giftCardInput,
          sendPin
        ).then((response) => {
          if (response.responseCode === ResponseCode.OK) {
            handleGetBalanceSuccess(response.balance);
          } else {
            handleGetBalanceError(response.responseCode);
          }
          setIsProcessing(false);
        });
      }
    }
  };

  const handleGiftCardRemove = () => {
    if (!giftCardState.isGiftCardAmountPaid) {
      dispatch(clearGiftCardInputs());
      dispatch(updatePaymentTotal());
      setGiftCardInput('');
      setEPinInput('');
    }
  };

  return (
    <>
      <PaymentSectionDivider />
      <PaymentSectionHeader>Use Gift Card</PaymentSectionHeader>
      {giftCardState.isGiftCardApplied ? (
        <GiftCardSummary
          removeHandler={handleGiftCardRemove}
          disabled={disabled}
        />
      ) : (
        <>
          <GiftCardSectionContainer id="addGiftCardSection">
            <GiftCardInputContainer id="giftCardInputSection">
              <StyledGiftCardInput
                id="giftCardNumberInput"
                epin={ePinEnabled && ePinRequired}
                placeholder="Gift Card Number"
                type="tel"
                value={giftCardInput}
                onChange={(event: any) => setGiftCardInput(event.target.value)}
                disabled={disabled || isProcessing}
              />
              {ePinEnabled && ePinRequired && (
                <StyledGiftCardPinInput
                  id="giftCardPinInput"
                  placeholder="PIN"
                  maxLength={6}
                  type="tel"
                  value={ePinInput}
                  onChange={(event: any) => setEPinInput(event.target.value)}
                  disabled={disabled || isProcessing}
                />
              )}
            </GiftCardInputContainer>
            <ApplyButton
              id="applyGiftCard"
              onClick={handleGiftCardApply}
              disabled={disableApplyButton || isProcessing || disabled}
              loading={isProcessing}
            />
          </GiftCardSectionContainer>
        </>
      )}
    </>
  );
};
