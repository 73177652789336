const MESSAGE_MODAL_TYPES = ['message', 'error', 'warning', 'success'] as const;

export type MessageModalType = typeof MESSAGE_MODAL_TYPES[number];

export const isMessageModalType = (
  value: string
): value is MessageModalType => {
  return MESSAGE_MODAL_TYPES.includes(value as MessageModalType);
};

export type ModalType = MessageModalType | 'tip';
