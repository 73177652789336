import { CircleLoader } from 'components/MpLoaders';
import { MouseEventHandler } from 'react';
import styled, { useTheme } from 'styled-components/macro';

const StyledButton = styled.button<{ btnLoading: boolean }>`
  background-color: ${({ disabled, theme }) =>
    disabled
      ? `${theme.colors.buttonDisabled.background}}`
      : `${theme.colors.button.background}`};
  color: ${({ disabled, theme }) =>
    disabled
      ? `${theme.colors.buttonDisabled.text}`
      : `${theme.colors.button.text}`};
  width: 100%;
  padding: ${({ btnLoading }) => (btnLoading ? `6.5px` : `12.5px`)};
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2px;
  border: ${({ disabled, theme }) =>
    disabled
      ? `1px solid ${theme.colors.buttonDisabled.border}`
      : `1.5px solid ${theme.colors.button.border}`};
  display: block;
  text-decoration: none;
  font-weight: bold;
  margin-top: 8px;
  border-radius: 11px;
  min-height: 47px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 24px;
    padding: 18px 0;
    line-height: 28px;
    letter-spacing: 0.3px;
    min-height: 67px;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))
      ${({ theme }) => theme.colors.button.background};
  }
`;

interface MpButtonProps {
  id?: string;
  type?: 'primary';
  disabled?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
}

export const MpButton = ({
  id,
  disabled,
  onClick,
  children,
  className,
  loading,
}: MpButtonProps) => {
  const theme = useTheme();
  return (
    <StyledButton
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={className}
      btnLoading={loading || false}
    >
      {loading ? (
        <CircleLoader
          color={theme.colors.body.solid}
          cssOverride="display: inline-block;"
        />
      ) : (
        children
      )}
    </StyledButton>
  );
};
