import { MpInput } from 'components/MpInput';
import styled from 'styled-components';

export const LoyaltyContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WelcomeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-left: 15px;
  flex-direction: column;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-bottom: 2px;
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
  }
`;

export const LoyaltyDiscountContainer = styled.div`
  justify-content: space-between;
  text-align: left;
  font-weight: normal;

 @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const StyledMpInput = styled(MpInput)`
  width: 100%;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    padding: 24px;
    font-size: 20px;
    line-height: 18px;
  }
`;

export const RemoveContainer = styled.span<{ disabled?: boolean }>`
  color: #0a84ff;
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && `none`};
  position: relative;
  top: 16px;
`;

export const LoyaltyStatusMessage = styled.div`
  margin-left: 1.4em;
  font-size: 0.9em;
  font-style: italic;
  color: ${({ theme }) =>
  theme.colors.divider.welcome};
  text-align: left;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 1.25em;
  }
`;
