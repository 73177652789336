import { CheckItemsLoading } from 'components/CheckItems/CheckItemsLoading';
import { CheckContainer } from 'components/CheckView';
import { Item } from 'types/DTOs';
import { CheckItems } from 'components/CheckItems';

interface CheckViewProps {
  id?: string;
  items: Item[] | undefined;
  loading: boolean;
}

export const CheckItemView = ({ id, items, loading }: CheckViewProps) => {
  const coalescedItems = items ?? [];

  return (
    <CheckContainer id={id}>
      {loading ? <CheckItemsLoading /> : <CheckItems items={coalescedItems} />}
    </CheckContainer>
  );
};
