import currency from 'currency.js';
import { getPriceView, priceIsValidForDisplay } from 'features/Common/utils';
import { getPaymentTypeName } from 'pages/utils';
import styled from 'styled-components';
import { Check } from 'types/DTOs';

const CheckChargeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 24px 12px 24px;
  line-height: 21px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 24px;
    line-height: 33px;
  }
`;

interface CheckChargesProps {
  checkDetails: Check | null;
}

export const CheckCharges = ({ checkDetails }: CheckChargesProps) => {
  return (
    <div id="checkChargeContainer">
      {checkDetails?.ServiceCharges?.map((charge, index) => (
        <CheckChargeContainer key={`${charge.Name}-${index}`}>
          <div>{charge.Name}</div>
          <div>{currency(charge.Amount || '').format()}</div>
        </CheckChargeContainer>
      ))}
      <CheckChargeContainer>
        <div>Tax</div>
        <div id="tax">{currency(checkDetails?.Totals?.Tax || '').format()}</div>
      </CheckChargeContainer>
      {checkDetails?.Payments?.map((payment, index) => (
        <CheckChargeContainer key={`${payment.Id}-${index}`}>
          <div>
            {getPaymentTypeName(payment, checkDetails?.Tenders)}
          </div>
          <div>{`-${currency(payment.Amount).format()}`}</div>
        </CheckChargeContainer>
      ))}
      {priceIsValidForDisplay(checkDetails?.Totals.Discount) ? (
        <CheckChargeContainer>
          <div>Discount</div>
          <div id="discount">
            {getPriceView(checkDetails?.Totals.Discount, true, false)}
          </div>
        </CheckChargeContainer>
      ) : null}
    </div>
  );
};
