import { getApi } from 'api/utils';
import {
  creditCardPaymentRequest,
  PayNoBalanceRequest,
} from 'types/DTOs/PaymentRequests';

const api = getApi();

export const payNoBalance = (request: PayNoBalanceRequest) =>
  api.payNoBalance(request);

export const newCreditCardPayment = (request: creditCardPaymentRequest) =>
  api.newCreditCardPayment(request);
