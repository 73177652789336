import styled from 'styled-components/macro';

export const CheckContainer = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  background: ${({ theme }) => theme.colors.section.background};
  color: ${({ theme }) => theme.colors.section.text};
  border: 1px solid ${({ theme }) => theme.colors.section.border};
  max-height: 240px;
  border-radius: 4px;
  overflow: auto;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    max-height: 398px;
  }
`;
