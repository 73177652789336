import { useAppDispatch, useAppSelector } from 'app/hooks';
import { resetLoyaltySlice } from 'features/AddLoyaltySection/LoyaltySlice';
import { logRefCode } from 'features/CodeInput/codeInputApi';
import {
  CodeInputContainer,
  StyledMpButton,
  StyledMpInput,
} from 'features/CodeInput/CodeInputComponents';
import {
  loadCheckInfo,
  resetCheckData,
  selectCheckDetailsStatus,
  setCheckCode,
} from 'features/Common/checkInfoSlice';
import { resetSiteConfig } from 'features/Common/siteConfigurationSlice';
import { setCurrentPage } from 'features/Common/userPageSlice';
import { validateCheckCode } from 'features/Common/validation';
import { resetCreditCardInput } from 'features/CreditCardInputSection/CreditCardInputSlice';
import { resetGiftCardSlice } from 'features/GiftCardSection/GiftCardSlice';
import { toUpper } from 'lodash';
import { navigateToCheckPage } from 'pages/navigation';
import { resetFPAdditionalInput } from 'pages/Payment/FreedomPay/FPAdditionalInputSlice';
import { resetPaymentSlice } from 'pages/Payment/paymentSlice';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import {
  checkCodeLength,
  checkIdCookie,
  codeCookie,
  eTagCookie,
  firstLoadCookie,
  loyaltyCheckCodeCookie,
  loyaltyIsAssignedCookie,
  PageName,
  siteIdCookie,
} from 'types/constants';

interface CodeInputProps {
  queryParamCheckCode?: string | null;
  queryParamRefCode?: string | null;
}

export const CodeInput = ({
  queryParamCheckCode,
  queryParamRefCode,
}: CodeInputProps) => {
  const history = useHistory();
  const [, setCookie, removeCookie] = useCookies([
    codeCookie,
    firstLoadCookie,
    eTagCookie,
    loyaltyIsAssignedCookie,
    loyaltyCheckCodeCookie,
    checkIdCookie,
    siteIdCookie,
  ]);

  // Redux Store
  const dispatch = useAppDispatch();
  const checkDetailsStatus = useAppSelector(selectCheckDetailsStatus);

  // Local State
  const [checkCodeInputValue, setCheckCodeInputValue] = useState<string>('');
  const [enableViewCheck, setEnableViewCheck] = useState<boolean>(false);
  const [isCodeInvalidFormat, setIsCodeInvalidFormat] =
    useState<boolean>(false);
  const checkInfoLoading = checkDetailsStatus === 'loading';

  useEffect(() => {
    dispatch(setCurrentPage(PageName.Code));

    // Reset all cookie values
    resetCookie();

    // Resetting every slice
    dispatch(resetCheckData());
    dispatch(resetSiteConfig());
    dispatch(resetLoyaltySlice());
    dispatch(resetPaymentSlice());
    dispatch(resetGiftCardSlice());
    dispatch(resetCreditCardInput());
    dispatch(resetFPAdditionalInput());

    // queryParamCheckCode will exist for entry points QR and SMS, and will execute this useEffect
    if (queryParamCheckCode) {
      handleCodeSubmit(queryParamCheckCode, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Validate check code
  useEffect(() => {
    const isCodeValid = validateCheckCode(checkCodeInputValue);
    setEnableViewCheck(isCodeValid);
    setIsCodeInvalidFormat(
      checkCodeInputValue.length === checkCodeLength && !isCodeValid
    );
  }, [checkCodeInputValue]);

  //#region Functions
  const handleCodeSubmit = async (checkCode: string, isQR?: boolean) => {
    //make sure check code is all uppercase.
    checkCode = toUpper(checkCode);

    // Read the check info and then navigate user to check page
    dispatch(setCheckCode(checkCode));
    await dispatch(loadCheckInfo(checkCode)).then(() => {
      setCookie(codeCookie, checkCode, { path: '/', maxAge: 3600 * 24 });
      setCookie(firstLoadCookie, true, { path: '/', maxAge: 3600 * 24 });
      navigateToCheckPage(history);
    });

    // To log how user accessed to MP
    var refCode = 'manual';
    if (isQR) {
      refCode = queryParamRefCode ? queryParamRefCode : 'QR';
    }
    logRefCode(refCode, checkCode);
  };

  const handleViewCheckClick = () => {
    handleCodeSubmit(checkCodeInputValue);
  };

  const resetCookie = () => {
    removeCookie(codeCookie);
    removeCookie(eTagCookie);
    removeCookie(loyaltyIsAssignedCookie);
    removeCookie(loyaltyCheckCodeCookie);
    removeCookie(checkIdCookie);
    removeCookie(siteIdCookie);
    removeCookie(firstLoadCookie);
  };
  //#endregion Functions

  return (
    <CodeInputContainer>
      <StyledMpInput
        id="codeInput"
        onChange={(event) => setCheckCodeInputValue(event.target.value)}
        placeholder="Enter Receipt Code"
        maxLength={checkCodeLength}
        error={isCodeInvalidFormat}
        helperText={
          isCodeInvalidFormat
            ? 'A code is composed of three letters followed by three numbers.'
            : ''
        }
      />
      <StyledMpButton
        id="viewCheck"
        type="primary"
        disabled={!enableViewCheck || checkInfoLoading}
        onClick={handleViewCheckClick}
        loading={checkInfoLoading}
      >
        View Check
      </StyledMpButton>
    </CodeInputContainer>
  );
};
