import { CircleLoader } from 'components/MpLoaders';
import { MouseEventHandler } from 'react';
import styled, { useTheme } from 'styled-components/macro';

export const StyledApplyButton = styled.button<{ loading: string }>`
  margin-left: 10px;
  max-height: 49px;
  display: flex;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid
    ${({ disabled, theme }) =>
      disabled
        ? theme.colors.applyButton.color
        : theme.colors.applyButton.activeColor};
  color: ${({ disabled, theme }) =>
    disabled
      ? theme.colors.applyButton.color
      : theme.colors.applyButton.activeColor};
  padding: ${({ loading }) =>
    loading === 'true' ? `26px 21px` : `26px 12.5px`};

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 20px;
    max-height: 80px;
    padding: ${({ loading }) =>
      loading === 'true' ? `24px 29px` : `25px 10px`};
  }
`;

interface ApplyButtonProps {
  id?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
  loading: boolean;
}

export const ApplyButton = ({
  id,
  onClick,
  disabled,
  loading,
}: ApplyButtonProps) => {
  const theme = useTheme();
  return (
    <StyledApplyButton
      id={id}
      onClick={onClick}
      disabled={disabled}
      loading={loading.toString()}
    >
      {loading ? (
        <CircleLoader
          size={25}
          color={theme.colors.button.text}
          cssOverride="top: -5px; right: 5px;"
        />
      ) : (
        'APPLY'
      )}
    </StyledApplyButton>
  );
};
