import { useAppDispatch } from 'app/hooks';
import { setCardImageSrc } from 'features/CreditCardInputSection/CreditCardInputSlice';
import { useCreditCardInput } from 'features/CreditCardInputSection/useCreditCardInput';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

const CardImg = styled.img`
  padding-left: 16px;
  height: 16px;

  &.fadeOut {
    opacity: 0;
    transition: opacity 0.1s;
  }

  &.fadeIn {
    opacity: 1;
    transition: opacity 0.2s;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    padding-left: 24px;
    height: 0.8em;
  }
`;

const CardImageContainer = styled.div`
  width: 15%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.input.background};
  color: ${({ theme }) => theme.colors.input.text};
  border-top: 1px solid ${({ theme }) => theme.colors.input.border};
  border-bottom: 1px solid ${({ theme }) => theme.colors.input.border};
  border-left: 1px solid ${({ theme }) => theme.colors.input.border};
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  align-items: center;
  text-align: center;
  padding: 14px 0px;
  line-height: 16px;
  font-size: 14px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    padding: 20px 0px;
    font-size: 20px;
    line-height: 18px;
  }
`;

export const CardImage = () => {
  const dispatch = useAppDispatch();
  const { cardImageSrc } = useCreditCardInput();
  const [cardImageSrcClassName, setCardImageSrcClassName] =
    useState<string>('');

  // Transition animation for card image
  useEffect(() => {
    setCardImageSrcClassName('fadeOut');
    const transitionTimoutId = setTimeout(() => {
      setCardImageSrcClassName('fadeIn');
      dispatch(setCardImageSrc(cardImageSrc));
    }, 100);

    return () => clearTimeout(transitionTimoutId);
  }, [cardImageSrc, dispatch]);

  return (
    <CardImageContainer>
      <CardImg src={cardImageSrc} className={cardImageSrcClassName} />
    </CardImageContainer>
  );
};
