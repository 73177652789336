import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { PageName } from 'types/constants';

export interface UserPageState {
  currentPage: PageName;
}

const initialState: UserPageState = {
  currentPage: PageName.Code,
};

export const userPageSlice = createSlice({
  name: 'userPageState',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }: PayloadAction<string>) => {
      switch (payload) {
        case 'Code':
          state.currentPage = PageName.Code;
          break;
        case 'Check':
          state.currentPage = PageName.Check;
          break;
        case 'Payment':
          state.currentPage = PageName.Payment;
          break;
        case 'PaymentConfirmation':
          state.currentPage = PageName.PaymentConfirmation;
          break;
        default:
          state.currentPage = PageName.Code;
          break;
      }
    },
  },
});

export const { setCurrentPage } = userPageSlice.actions;

export const selectUserPageState = (state: RootState) => state.userPage;

export default userPageSlice.reducer;
