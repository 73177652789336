import { useAppDispatch, useAppSelector } from 'app/hooks';
import { TipSuggestionButton } from 'components/TipButtons/TipSuggestionButton';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import {
  selectPaymentState,
  setTipAndUpdatePaymentTotal,
} from 'pages/Payment/paymentSlice';
import { useTipCalculator } from 'pages/Payment/useTipCalculator';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Tip } from 'types';
import { PERCENT } from 'types/DTOs/TipBase';

const TipSuggestionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface TipSuggestionsProps {
  className?: string;
  loading: boolean;
}

export const TipSuggestions = ({ className, loading }: TipSuggestionsProps) => {
  const { siteConfigLoading } = useSiteConfig();
  const paymentState = useAppSelector(selectPaymentState);
  const dispatch = useAppDispatch();
  const { tips, tipBase } = useTipCalculator();

  const handleSuggestedTipClick = useCallback(
    (tip: Tip) => {
      dispatch(setTipAndUpdatePaymentTotal(tip));
    },
    [dispatch]
  );

  useEffect(() => {
    const middleTip = tips[1];
    handleSuggestedTipClick(middleTip);
  }, [handleSuggestedTipClick, tips]);

  return (
    <TipSuggestionsContainer className={className}>
      {tips.map((tip, index) => {
        return (
          <TipSuggestionButton
            type={tipBase || PERCENT}
            amount={tip.amount || 0}
            percent={tip.percent}
            selected={paymentState.selectedTip.id === index}
            loading={siteConfigLoading}
            onClick={() => handleSuggestedTipClick(tip)}
            key={`${tip}-${index}`}
            disabled={loading}
          />
        );
      })}
    </TipSuggestionsContainer>
  );
};
