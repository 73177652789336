import { MpInput } from 'components/MpInput';
import styled from 'styled-components/macro';

export const CreditCardInput = styled(MpInput)`
  & .MuiOutlinedInput-root {
    padding: 14px 0px;

    @media only screen and (min-width: ${({ theme }) =>
        theme.sizes.screen.small}) {
      padding: 20px 0px;
    }
  }
`;
