import { MpFooter } from 'components/MpFooter';
import { MpModals } from 'features/Modal';
import { useEffect } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import { GlobalStyles } from 'themes/globalStyles';
import { useTheme } from 'themes/useTheme';
import WebFont from 'webfontloader';
import routes from './routes';

function App() {
  const { theme, themeLoaded, getFonts } = useTheme();
  useEffect(() => {
    WebFont.load({
      google: {
        families: getFonts(),
      },
    });
  });

  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <SkeletonTheme
            color={theme.colors.skeleton.background}
            highlightColor={theme.colors.skeleton.highlight}
          >
            <ModalProvider>
              <Router>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  ))}
                </Switch>
              </Router>
              <MpFooter showTermsOfService />
              <MpModals />
            </ModalProvider>
          </SkeletonTheme>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
