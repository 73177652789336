import { browserName } from 'react-device-detect';

export const isDev = () => process.env.NODE_ENV === 'development';

export const getMetaContent = (name: string) =>
  document?.querySelector(`meta[name=${name}]`)?.getAttribute('content');

export const getSubdomain = () => getMetaContent('subdomain') || 'ncr';

export const isDemo = () => window.location.pathname.includes('demo');

export const createLocalizedIsoDateAtStartOfDay = (date: Date): string =>
  new Date(date.toDateString()).toISOString();

export const isAndroidDevice = () =>
  navigator.userAgent.toLowerCase().indexOf('android') > -1;

export const isGoogleChrome = () => browserName === 'Chrome';
