import { getThemeImages } from 'images/imageUtils';
import defaultLogoImg from 'images/ncr_logo.png';
import styled from 'styled-components/macro';

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.header.background};
  height: 64px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.colors.header.border};

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    height: 120px;
  }
`;

const HeaderImg = styled.img`
  height: 62.5%;
`;
export const MpHeader = () => {
  return (
    <Header>
      <HeaderImg
        id="logo-img"
        src={getThemeImages().logoImgSrc ?? defaultLogoImg}
        alt="Header image"
      />
    </Header>
  );
};
