import { useAppDispatch } from 'app/hooks';
import { BaseMpModal } from 'features/Modal/BaseMpModal';
import {
  ModalButton,
  ModalHeader,
  ModalIcon,
  ModalIconContainer,
  ModalText,
} from 'features/Modal/ModalComponents';
import { closeModal } from 'features/Modal/modalSlice';
import successIcon from 'images/check_circle_outline.svg';
import errorIcon from 'images/error.svg';
import warningIcon from 'images/warning.svg';
import { ModalType } from 'types';

const getModalIcon = (type: string) => {
  switch (type) {
    case 'error':
      return <ModalIcon src={errorIcon} alt="error icon" />;
    case 'warning':
      return <ModalIcon src={warningIcon} alt="warning icon" />;
    case 'success':
      return <ModalIcon src={successIcon} alt="success icon" />;
    default:
      return null;
  }
};

interface MessageModalProps {
  open: boolean;
  text: string;
  header: string;
  type: ModalType;
}

export const MessageModal = ({
  open,
  text,
  header,
  type,
}: MessageModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <BaseMpModal open={open}>
      <ModalIconContainer id="errorModalIcon">
        {getModalIcon(type)}
      </ModalIconContainer>
      {header ? <ModalHeader id="errorModalTitle">{header}</ModalHeader> : null}
      <ModalText id="errorModalText">{text}</ModalText>
      <ModalButton id="errorModalButton1" onClick={handleCloseModal}>
        OK
      </ModalButton>
    </BaseMpModal>
  );
};
