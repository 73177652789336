import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  formatPhoneNumber,
  removeHyphenFromPhoneNumber,
} from 'features/Common/validation';
import { FPAdditionalInput } from 'pages/Payment/FreedomPay/FPAdditionalInput';
import {
  selectFPAdditionalInput,
  setBillingCity,
  setBillingEmailAddress,
  setBillingPhoneNumber,
  setBillingState,
  setBillingStreetAddress,
  setBillingZipCode,
  setCardholderFirstName,
  setCardholderLastName,
} from 'pages/Payment/FreedomPay/FPAdditionalInputSlice';
import {
  StyledAdditionalInputsDiv,
  StyledAdditionalInputsForm,
  StyledFPInputSectionDiv,
} from 'pages/Payment/FreedomPay/FreedomPayIframeComponents';
import { generateFpInputErrorMsg } from 'pages/Payment/utils';
import {
  fpBillingPhoneNumberLength,
  fpBillingStateLength,
  fpBillingZipCodeLength,
} from 'types/constants';

interface FPAdditionalInputsProps {
  is3dsEnabled: boolean;
}

export const FPAdditionalInputs = ({
  is3dsEnabled,
}: FPAdditionalInputsProps) => {
  //#region Redux Store
  const dispatch = useAppDispatch();
  const {
    cardholderFirstName,
    cardholderLastName,
    billingEmailAddress,
    billingPhoneNumber,
    billingStreetAddress,
    billingState,
    billingCity,
    billingZipCode,
    isCardholderFirstNameValid,
    isCardholderLastNameValid,
    isBillingEmailAddressValid,
    isBillingPhoneNumberValid,
    isBillingStreetAddressValid,
    isBillingCityValid,
    isBillingStateValid,
    isBillingZipCodeValid,
    cardholderFirstNameInputClass,
    cardholderLastNameInputClass,
    billingEmailAddressInputClass,
    billingPhoneNumberInputClass,
    billingStreetAddressInputClass,
    billingCityInputClass,
    billingStateInputClass,
    billingZipCodeInputClass,
  } = useAppSelector(selectFPAdditionalInput);
  //#endregion Redux Store

  //#region utils
  const formatBillingPhoneNumber = (e: any) => {
    if (e.keyCode !== 8) {
      const number = parseInt(e.key);
      if (number >= 0 || number <= 9 || e.key === 'v') {
        dispatch(
          setBillingPhoneNumber(formatPhoneNumber(billingPhoneNumber || ''))
        );
      }
    } else {
      dispatch(
        setBillingPhoneNumber(
          removeHyphenFromPhoneNumber(billingPhoneNumber || '')
        )
      );
    }
  };

  const formatBillingState = (e: any) => {
    dispatch(setBillingState(billingState?.toUpperCase() || ''));
  };
  //#endregion utils

  //#region onBlur functions
  const onBlurCardholderFirstName = (value: string) =>
    dispatch(setCardholderFirstName(value));
  const onBlurCardholderLastName = (value: string) =>
    dispatch(setCardholderLastName(value));
  const onBlurEmailAddress = (value: string) =>
    dispatch(setBillingEmailAddress(value));
  const onBlurPhoneNumber = (value: string) => {
    dispatch(setBillingPhoneNumber(value));
  };
  const onBlurStreetAddress = (value: string) =>
    dispatch(setBillingStreetAddress(value));
  const onBlurCity = (value: string) => dispatch(setBillingCity(value));
  const onBlurState = (value: string) => dispatch(setBillingState(value));
  const onBlurZipCode = (value: string) => dispatch(setBillingZipCode(value));
  //#endregion onBlur functions

  return (
    <>
      <StyledAdditionalInputsDiv>
        <StyledAdditionalInputsForm>
          <StyledFPInputSectionDiv className="control-group">
            <FPAdditionalInput
              title="Cardholder First Name"
              id="fpCardholderFirstName"
              className={cardholderFirstNameInputClass}
              placeholder="Enter Cardholder First Name"
              type="text"
              isValid={isCardholderFirstNameValid}
              errorMsg={
                isCardholderFirstNameValid
                  ? ''
                  : generateFpInputErrorMsg('First Name')
              }
              onBlur={onBlurCardholderFirstName}
              value={cardholderFirstName || ''}
              onChange={(event: any) =>
                dispatch(setCardholderFirstName(event.target.value))
              }
            />
          </StyledFPInputSectionDiv>
          <StyledFPInputSectionDiv className="control-group">
            <FPAdditionalInput
              title="Cardholder Last Name"
              id="fpCardholderLastName"
              className={cardholderLastNameInputClass}
              placeholder="Enter Cardholder Last Name"
              type="text"
              isValid={isCardholderLastNameValid}
              errorMsg={
                isCardholderLastNameValid
                  ? ''
                  : generateFpInputErrorMsg('Last Name')
              }
              onBlur={onBlurCardholderLastName}
              value={cardholderLastName || ''}
              onChange={(event: any) =>
                dispatch(setCardholderLastName(event.target.value))
              }
            />
          </StyledFPInputSectionDiv>
          {is3dsEnabled && (
            <>
              <StyledFPInputSectionDiv className="control-group">
                <FPAdditionalInput
                  title="Email Address"
                  id="fpEmailAddress"
                  className={billingEmailAddressInputClass}
                  placeholder="Enter Email Address"
                  type="text"
                  isValid={isBillingEmailAddressValid}
                  errorMsg={
                    isBillingEmailAddressValid
                      ? ''
                      : generateFpInputErrorMsg('Email Address')
                  }
                  onBlur={onBlurEmailAddress}
                  value={billingEmailAddress || ''}
                  onChange={(event: any) =>
                    dispatch(setBillingEmailAddress(event.target.value))
                  }
                />
              </StyledFPInputSectionDiv>
              <StyledFPInputSectionDiv className="control-group">
                <FPAdditionalInput
                  title="Phone Number"
                  id="fpPhoneNumber"
                  className={billingPhoneNumberInputClass}
                  placeholder="Enter Phone Number"
                  type="text"
                  isValid={isBillingPhoneNumberValid}
                  errorMsg={
                    isBillingPhoneNumberValid
                      ? ''
                      : generateFpInputErrorMsg('Phone Number')
                  }
                  onBlur={onBlurPhoneNumber}
                  maxLength={fpBillingPhoneNumberLength}
                  value={billingPhoneNumber || ''}
                  onChange={(event: any) =>
                    dispatch(setBillingPhoneNumber(event.target.value))
                  }
                  onKeyUp={formatBillingPhoneNumber}
                />
              </StyledFPInputSectionDiv>
              <StyledFPInputSectionDiv className="control-group">
                <FPAdditionalInput
                  title="Street Address"
                  id="fpStreetAddress"
                  className={billingStreetAddressInputClass}
                  placeholder="Enter Street Address"
                  type="text"
                  isValid={isBillingStreetAddressValid}
                  errorMsg={
                    isBillingStreetAddressValid
                      ? ''
                      : generateFpInputErrorMsg('Street Address')
                  }
                  onBlur={onBlurStreetAddress}
                  value={billingStreetAddress || ''}
                  onChange={(event: any) =>
                    dispatch(setBillingStreetAddress(event.target.value))
                  }
                />
              </StyledFPInputSectionDiv>
              <StyledFPInputSectionDiv className="control-group">
                <FPAdditionalInput
                  title="City"
                  id="fpCity"
                  className={billingCityInputClass}
                  placeholder="Enter City"
                  type="text"
                  isValid={isBillingCityValid}
                  errorMsg={
                    isBillingCityValid ? '' : generateFpInputErrorMsg('City')
                  }
                  onBlur={onBlurCity}
                  value={billingCity || ''}
                  onChange={(event: any) =>
                    dispatch(setBillingCity(event.target.value))
                  }
                />
              </StyledFPInputSectionDiv>
              <StyledFPInputSectionDiv className="control-group">
                <FPAdditionalInput
                  title="State"
                  id="fpState"
                  className={billingStateInputClass}
                  placeholder="Enter State"
                  type="text"
                  isValid={isBillingStateValid}
                  errorMsg={
                    isBillingStateValid ? '' : generateFpInputErrorMsg('State')
                  }
                  value={billingState || ''}
                  onChange={(event: any) =>
                    dispatch(setBillingState(event.target.value))
                  }
                  onKeyUp={formatBillingState}
                  onBlur={onBlurState}
                  maxLength={fpBillingStateLength}
                />
              </StyledFPInputSectionDiv>
              <StyledFPInputSectionDiv className="control-group">
                <FPAdditionalInput
                  title="Zip Code"
                  id="fpZipCode"
                  className={billingZipCodeInputClass}
                  placeholder="Enter Zip Code"
                  type="text"
                  isValid={isBillingZipCodeValid}
                  errorMsg={
                    isBillingZipCodeValid
                      ? ''
                      : generateFpInputErrorMsg('Zip Code')
                  }
                  onBlur={onBlurZipCode}
                  maxLength={fpBillingZipCodeLength}
                  value={billingZipCode || ''}
                  onChange={(event: any) =>
                    dispatch(setBillingZipCode(event.target.value))
                  }
                />
              </StyledFPInputSectionDiv>
            </>
          )}
        </StyledAdditionalInputsForm>
      </StyledAdditionalInputsDiv>
    </>
  );
};
