import { useAppDispatch } from 'app/hooks';
import {
  CloseIcon,
  CloseIconContainer,
  StyledBaseModal,
} from 'features/Modal/ModalComponents';
import { closeModal } from 'features/Modal/modalSlice';
import closeIcon from 'images/close.svg';

interface BaseMpModalProps {
  open: boolean;
  children: React.ReactNode;
  onBackgroundClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const BaseMpModal = ({
  open,
  children,
  onBackgroundClick,
}: BaseMpModalProps) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <StyledBaseModal isOpen={open} onBackgroundClick={onBackgroundClick}>
      <CloseIconContainer onClick={handleCloseModal}>
        <CloseIcon src={closeIcon} alt="close modal" />
      </CloseIconContainer>
      {children}
    </StyledBaseModal>
  );
};
