import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  setSubdomainThemeInLS,
  updateDefaultThemeWithCustomProperties,
} from 'themes/utils';
import { ThemeConfigSettings } from 'types/DTOs';
import { getThemeConfig } from './commonApi';

export interface ThemeConfigState {
  themeConfig: ThemeConfigSettings | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ThemeConfigState = {
  themeConfig: null,
  status: 'idle',
};

export const loadThemeConfig = createAsyncThunk(
  'themeConfig/loadThemeConfig',
  async (subdomain: string) => {
    const response = await getThemeConfig(subdomain);
    // TODO we need to redo how we store themes to just store the whole theme object as a string
    const updatedTheme = updateDefaultThemeWithCustomProperties(response.data);
    setSubdomainThemeInLS(subdomain, updatedTheme);
    return response.data;
  }
);

export const themeConfigSlice = createSlice({
  name: 'themeConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadThemeConfig.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadThemeConfig.fulfilled, (state, action) => {
        state.status = 'idle';
        state.themeConfig = action.payload;
      })
      .addCase(loadThemeConfig.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export const selectThemeConfig = (state: RootState) =>
  state.themeConfig.themeConfig;

export const selectThemeConfigState = (state: RootState) => state.themeConfig;

export default themeConfigSlice.reducer;
