import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import {
  selectCreditCardInput,
  setIsCreditCardInputsValid,
} from './CreditCardInputSlice';

export const useCreditCardInput = () => {
  const {
    cardType,
    cardImageSrc,
    creditCardNumber,
    creditCardExp,
    creditCardCvv,
    creditCardZip,
    creditCardNumberLimit,
    creditCardExpLimit,
    creditCardCvvLimit,
    creditCardZipLimit,
    isCCNumberValid,
    isCCExpValid,
    isCCCvvValid,
    isCCZipValid,
    isCreditCardInputsValid,
  } = useAppSelector(selectCreditCardInput);
  const dispatch = useAppDispatch();

  // Set isCreditCardInputsValid true when all other inputs are valid
  useEffect(() => {
    if (isCCNumberValid && isCCExpValid && isCCCvvValid && isCCZipValid)
      dispatch(setIsCreditCardInputsValid(true));
    else dispatch(setIsCreditCardInputsValid(false));
  }, [dispatch, isCCCvvValid, isCCExpValid, isCCNumberValid, isCCZipValid]);

  return {
    cardType,
    cardImageSrc,
    creditCardNumber,
    creditCardExp,
    creditCardCvv,
    creditCardZip,
    creditCardNumberLimit,
    creditCardExpLimit,
    creditCardCvvLimit,
    creditCardZipLimit,
    isCCNumberValid,
    isCCExpValid,
    isCCCvvValid,
    isCCZipValid,
    isCreditCardInputsValid,
  };
};
