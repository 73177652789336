export const giftCardErrorMessages = {
  giftCardNotFound:
    'This gift card number could not be found, please review your gift card number and try entering it again.',
  noBalance: 'Please provide another gift card.',
  giftCardPayFailure:
    'Unable to process gift card payment at this time. Please see your server.',
};

export const loyaltyErrorMessages = {
  loyaltyVerificationFailure:
    'Unable to verify that loyalty has been applied.  Please see your server.',
  loyaltyFailure: 'Failed to apply loyalty to check. Please see your server."',
};

export const creditCardInputErrorMessages = {
  // Credit card number
  noCardNumber: 'Please provide your card information.',
  invalidCardNumber: 'Please provide a valid card.',

  // Credit card expiration date
  noCardExp: 'Please provide your card expiration date.',
  invalidCardExpFormat: 'Expiration must be of the format: MMYY.',
  invalidCardExpMonth: 'Please provide a valid month.',
  expiredCardExp: 'Please use a card that is not expired.',
  expiringTooFarCardExp:
    'We cannot accept cards that expire in more than ten years.',

  // Credit card CVV
  noCardCvv: 'Please enter a CVV.',
  invalidCardCvv: 'Please provide a valid cvv.',

  // Credit card zip
  noCardZip: 'Please enter a zip code.',
  invalidCardZip: 'Please enter a valid zip code.',

  // Others
  noOtherCardTypes:
    'We only accept Visa, Master, Discover, and American Express Cards.',
};

export const paymentErrorMessages = {
  totalExceedsMaxPaymentThreshold:
    'Total amount exceeds authorized amount for mobile payment at this location. Please see your server to pay.',
  zeroMaxPaymentThreshold:
    'Mobile payments are disabled for this location. Please see your server to pay.',
  noPaymentNecessary: 'No payment is necessary.',
  refreshPaymentInfoFailure:
    'Failed to refresh payment info. Please see your server for payment confirmation.',
  cannotStartSecurePaymentSession:
    'Could not start session with Secure Payment',
  incorrectDataEntered:
    'There was a problem with the data you entered. Please try again.',
  processingFailure:
    'Unfortunately an error occurred when processing your transaction.',
  tryAgainButTalkToServerIfPersist:
    'We were unable to process the payment with this card. Please try again or try with a different card. If the issue persists, please talk to your server.',
};

export const generalErrorMessages = {
  checkLoadFailure: 'Check data failed to load.',
  invalidCode: 'A code is composed of three letters followed by three numbers.',
  invalidCheckCode:
    'This Check Code is not valid for a currently Open/Active Check Code for today.',
  uhOhMessage:
    'Uh oh! Something has gone wrong. Please wait a moment and try again.',
  tooManyFailureAttemps: 'Too many failed attempts. Talk to server.',
};

export const modalHeaders = {
  invalidCheckCode: 'Invalid Check Code',
  paymentDeclined: 'Payment Declined',
  noPaymentNecessary: 'Check is Already Paid',
  noBalanceGiftCard: '$0 Balance',
  invalidGiftCard: 'Invalid Gift Card',
};

export const ambiguousLoyaltyMessage =
  'Loyalty may have already been applied, please ask your server';

export const freedomPayErrorMessage = {
  notSupportApplePay:
    'Please use an Apple device with a modern version of Safari. The device must have Touch ID or Face ID and must be logged into an Apple account that has ApplePay enabled.',
};
