import { setToLS } from 'app/browserStorage';
import { DefaultTheme } from 'styled-components';
import themes from 'themes/schema.json';
import { ThemeConfigSettings } from 'types/DTOs';

export const getThemeNameForSubdomain = (subdomain: string) =>
  `mp-${subdomain}-theme`;

export const setSubdomainThemeInLS = (subdomain: string, theme: DefaultTheme) =>
  setToLS(getThemeNameForSubdomain(subdomain), theme);

export const updateDefaultThemeWithCustomProperties = (
  themeSettings: ThemeConfigSettings
) => {
  const defaultThemeToUpdate = { ...themes.default };
  if (themeSettings.buttonBackgroundColor !== null) {
    defaultThemeToUpdate.colors.button.background = `#${themeSettings.buttonBackgroundColor
      .toString(16)
      .padStart(6, '0')}`;
  }

  if (themeSettings.buttonFontColor !== null) {
    defaultThemeToUpdate.colors.button.text = `#${themeSettings.buttonFontColor.toString(
      16
    )}`;
  }

  if (themeSettings.dividerColor !== null) {
    defaultThemeToUpdate.colors.header.border = `#${themeSettings.dividerColor.toString(
      16
    )}`;
  }

  return defaultThemeToUpdate;
};
