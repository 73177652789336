import {
  emailRegex,
  iframeTagName,
  phoneNumberRegex,
  stateRegex,
  tempIsoStates,
  zipRegex,
} from 'types/constants';
import { NCRSecurePaymentResponse } from 'types/DTOs';
import { MakeSecurePaymentRequest } from 'types/DTOs/NCRSecurePayment/MakeSecurePaymentRequest';
import { FPInitType } from 'types/DTOs/SiteConfiguration';

export const isIframe = (
  input: HTMLElement | null
): input is HTMLIFrameElement => {
  return input !== null && input.tagName === iframeTagName;
};

export const splitExpirationDate = (expDate: string): string[] =>
  expDate.split('/');

//#region FP Additional Inputs Validations
export const generateFpInputErrorMsg = (name: string): string => {
  return `${name} is incomplete`;
};

export const isCardholderFirstNameValid = (
  value: string | undefined
): boolean => {
  if (value === undefined) return true;
  return value.length > 0;
};

export const isCardholderLastNameValid = (
  value: string | undefined
): boolean => {
  if (value === undefined) return true;
  return value.length > 0;
};

export const isFpEmailAddressValid = (value: string | undefined): boolean => {
  if (value === undefined) return true;
  return value.length > 0 && emailRegex.test(value);
};

export const isFpPhoneNumberValid = (value: string | undefined): boolean => {
  if (value === undefined) return true;
  return value.length > 0 && phoneNumberRegex.test(value);
};

export const isFpStreetAddressValid = (value: string | undefined): boolean => {
  if (value === undefined) return true;
  return value ? value.length > 0 : false;
};

export const isFpCityValid = (value: string | undefined): boolean => {
  if (value === undefined) return true;
  return value ? value.length > 0 : false;
};

// TODO: In the future when enhancing State field to dropdown, refer to this package (https://yarnpkg.com/package/iso-3166-2)
export const isFpStateValid = (value: string | undefined): boolean => {
  if (value === undefined) return true;

  const tempValue = value.toUpperCase();
  return (
    tempValue.length > 0 &&
    stateRegex.test(tempValue) &&
    tempIsoStates.includes(tempValue)
  );
};

export const isFpZipCodeValid = (value: string | undefined): boolean => {
  if (value === undefined) return true;
  return value.length > 0 && zipRegex.test(value);
};
//#endregion FP Additional Inputs Validations

export const getFreedomPayIframeSrc = (
  response: NCRSecurePaymentResponse
): string => {
  if (!response) return '';

  const fpIframeDom = new DOMParser().parseFromString(
    response.FreedomPayIFrame || '',
    'text/html'
  );

  return (
    fpIframeDom.getElementsByTagName('iframe')[0].getAttribute('src') || ''
  );
};

export const maskGoogleApplePayCardNumber = (
  request: MakeSecurePaymentRequest,
  paymentType: FPInitType
): string => {
  var result = '';
  var cardNumber = '';

  if (
    request.googleApplePaymentKeyData &&
    request.googleApplePaymentKeyData.attributes
  ) {
    if (
      paymentType === FPInitType.GooglePay &&
      request.googleApplePaymentKeyData.attributes.MaskedCardNumber
    ) {
      cardNumber =
        request.googleApplePaymentKeyData.attributes.MaskedCardNumber;
    }
    if (
      paymentType === FPInitType.ApplePay &&
      request.googleApplePaymentKeyData.attributes.maskedCardNumber
    ) {
      cardNumber =
        request.googleApplePaymentKeyData.attributes.maskedCardNumber;
    }

    result = cardNumber
      ? 'XXXXXXXXXXXX' +
        cardNumber.substring(cardNumber.length - 4, cardNumber.length)
      : '';
  }
  return result;
};

export const removeDashFromPhoneNumber = (value: string) =>
  value.replaceAll('-', '');

export const generateIsoStateString = (value: string) =>
  `US-${value.toUpperCase()}`;

export const is3DSAuthFailed = (data: any): boolean => {
  let isFailed = false;

  //check for Failed Frictionless Authentication
  let isFailedWithCmpiLookup =
    (data.CmpiLookup?.EciFlag === '00' || data.CmpiLookup?.EciFlag === '07') &&
    data.cavv === '';

  //check for Failed Step Up Authentication
  let isFailedWithPayment =
    (data.Payment?.ExtendedData.ECIFlag === '00' ||
      data.Payment?.ExtendedData.ECIFlag === '07') &&
    data.Payment?.ExtendedData.CAVV === '';

  if (isFailedWithCmpiLookup || isFailedWithPayment) {
    isFailed = true;
  }

  return isFailed;
};
