import { CreditCardInput } from 'components/CreditCardInput';
import { InputContainer } from 'components/InputContainer';
import { JoinedInputContainer } from 'components/JoinedInputContainer';
import { MpInput } from 'components/MpInput';
import styled from 'styled-components';

export const StyledCreditCardCvvInput = styled(CreditCardInput)`
  width: 20%;

  & .MuiOutlinedInput-root {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
  }
`;

export const StyledCreditCardExpInput = styled(CreditCardInput)`
  width: 25%;

  & .MuiOutlinedInput-root {
    border-right: none;
    border-left: none;
    border-radius: 0px;
  }
`;

export const StyledCreditCardNumberInput = styled(CreditCardInput)`
  width: 65%;

  & .MuiOutlinedInput-root {
    border-right: none;
    border-left: none;
    border-radius: 0px;
  }
`;

export const StyledCreditCardZipInput = styled(MpInput)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.input.border};
`;

export const CreditCardInputContainer = styled.div<{ disabled?: boolean }>`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  opacity: ${({ disabled }) => disabled && `0.2`};
`;

export const FirstLineForCreditCardInputContainer = styled(
  JoinedInputContainer
)`
  display: flex;
  padding-right: 0px;
`;

export const SecondLineForCreditCardInputContainer = styled(InputContainer)`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;
