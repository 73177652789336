import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { formatPhoneNumber } from 'features/Common/validation';
import {
  isCardholderFirstNameValid,
  isCardholderLastNameValid,
  isFpCityValid,
  isFpEmailAddressValid,
  isFpPhoneNumberValid,
  isFpStateValid,
  isFpStreetAddressValid,
  isFpZipCodeValid,
} from 'pages/Payment/utils';

export interface FPAdditionalInputState {
  fpIframeHeight: string;
  fpSessionKey: string;
  cardholderFirstName?: string;
  cardholderLastName?: string;
  billingEmailAddress?: string;
  billingPhoneNumber?: string;
  billingStreetAddress?: string;
  billingCity?: string;
  billingState?: string;
  billingZipCode?: string;
  isCardholderFirstNameValid: boolean;
  isCardholderLastNameValid: boolean;
  isBillingEmailAddressValid: boolean;
  isBillingPhoneNumberValid: boolean;
  isBillingStreetAddressValid: boolean;
  isBillingCityValid: boolean;
  isBillingStateValid: boolean;
  isBillingZipCodeValid: boolean;
  cardholderFirstNameInputClass: string;
  cardholderLastNameInputClass: string;
  billingEmailAddressInputClass: string;
  billingPhoneNumberInputClass: string;
  billingStreetAddressInputClass: string;
  billingCityInputClass: string;
  billingStateInputClass: string;
  billingZipCodeInputClass: string;
  isFpLoading: boolean;
  isFpButtonDisabled: boolean;
}

const initialState: FPAdditionalInputState = {
  fpIframeHeight: '',
  fpSessionKey: '',
  cardholderFirstName: undefined,
  cardholderLastName: undefined,
  billingEmailAddress: undefined,
  billingPhoneNumber: undefined,
  billingStreetAddress: undefined,
  billingCity: undefined,
  billingState: undefined,
  billingZipCode: undefined,
  isCardholderFirstNameValid: true,
  isCardholderLastNameValid: true,
  isBillingEmailAddressValid: true,
  isBillingPhoneNumberValid: true,
  isBillingStreetAddressValid: true,
  isBillingCityValid: true,
  isBillingStateValid: true,
  isBillingZipCodeValid: true,
  cardholderFirstNameInputClass: '',
  cardholderLastNameInputClass: '',
  billingEmailAddressInputClass: '',
  billingPhoneNumberInputClass: '',
  billingStreetAddressInputClass: '',
  billingCityInputClass: '',
  billingStateInputClass: '',
  billingZipCodeInputClass: '',
  isFpLoading: false,
  isFpButtonDisabled: false,
};

export const fpAdditionalInputSlice = createSlice({
  name: 'fpAdditionalInput',
  initialState,
  reducers: {
    resetFPAdditionalInput: () => {
      return { ...initialState };
    },
    setFpIframeHeight: (state, { payload }: PayloadAction<string>) => {
      state.fpIframeHeight = payload;
    },
    setIsFpLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isFpLoading = payload;
    },
    setIsFpButtonDisabled: (state, { payload }: PayloadAction<boolean>) => {
      state.isFpButtonDisabled = payload;
    },
    setFpSessionKey: (state, { payload }: PayloadAction<string>) => {
      state.fpSessionKey = payload;
    },
    setCardholderFirstName: (state, { payload }: PayloadAction<string>) => {
      state.cardholderFirstName = payload;

      if (isCardholderFirstNameValid(payload)) {
        state.isCardholderFirstNameValid = true;
        state.cardholderFirstNameInputClass = 'valid';
      } else {
        state.isCardholderFirstNameValid = false;
        state.cardholderFirstNameInputClass = 'invalid';
      }
    },
    setCardholderLastName: (state, { payload }: PayloadAction<string>) => {
      state.cardholderLastName = payload;

      if (isCardholderLastNameValid(payload)) {
        state.isCardholderLastNameValid = true;
        state.cardholderLastNameInputClass = 'valid';
      } else {
        state.isCardholderLastNameValid = false;
        state.cardholderLastNameInputClass = 'invalid';
      }
    },
    setBillingEmailAddress: (state, { payload }: PayloadAction<string>) => {
      state.billingEmailAddress = payload;

      if (isFpEmailAddressValid(payload)) {
        state.isBillingEmailAddressValid = true;
        state.billingEmailAddressInputClass = 'valid';
      } else {
        state.isBillingEmailAddressValid = false;
        state.billingEmailAddressInputClass = 'invalid';
      }
    },
    setBillingPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      const phoneNumberInputVar = formatPhoneNumber(payload);
      state.billingPhoneNumber = phoneNumberInputVar;

      if (isFpPhoneNumberValid(phoneNumberInputVar)) {
        state.isBillingPhoneNumberValid = true;
        state.billingPhoneNumberInputClass = 'valid';
      } else {
        state.isBillingPhoneNumberValid = false;
        state.billingPhoneNumberInputClass = 'invalid';
      }
    },
    setBillingStreetAddress: (state, { payload }: PayloadAction<string>) => {
      state.billingStreetAddress = payload;

      if (isFpStreetAddressValid(payload)) {
        state.isBillingStreetAddressValid = true;
        state.billingStreetAddressInputClass = 'valid';
      } else {
        state.isBillingStreetAddressValid = false;
        state.billingStreetAddressInputClass = 'invalid';
      }
    },
    setBillingCity: (state, { payload }: PayloadAction<string>) => {
      state.billingCity = payload;

      if (isFpCityValid(payload)) {
        state.isBillingCityValid = true;
        state.billingCityInputClass = 'valid';
      } else {
        state.isBillingCityValid = false;
        state.billingCityInputClass = 'invalid';
      }
    },
    setBillingState: (state, { payload }: PayloadAction<string>) => {
      const stateInputVar = payload.toUpperCase();
      state.billingState = stateInputVar;

      if (isFpStateValid(stateInputVar)) {
        state.isBillingStateValid = true;
        state.billingStateInputClass = 'valid';
      } else {
        state.isBillingStateValid = false;
        state.billingStateInputClass = 'invalid';
      }
    },
    setBillingZipCode: (state, { payload }: PayloadAction<string>) => {
      state.billingZipCode = payload;

      if (isFpZipCodeValid(payload)) {
        state.isBillingZipCodeValid = true;
        state.billingZipCodeInputClass = 'valid';
      } else {
        state.isBillingZipCodeValid = false;
        state.billingZipCodeInputClass = 'invalid';
      }
    },
  },
});

export const {
  resetFPAdditionalInput,
  setFpIframeHeight,
  setFpSessionKey,
  setCardholderFirstName,
  setCardholderLastName,
  setBillingEmailAddress,
  setBillingPhoneNumber,
  setBillingStreetAddress,
  setBillingCity,
  setBillingState,
  setBillingZipCode,
  setIsFpLoading,
  setIsFpButtonDisabled,
} = fpAdditionalInputSlice.actions;

export const selectFPAdditionalInput = (state: RootState) =>
  state.fpAdditionalInput;

export default fpAdditionalInputSlice.reducer;
