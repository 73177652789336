import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseCode } from 'api/';
import { createAxiosResponse } from 'api/utils';
import { RootState } from 'app/store';
import { AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import {
  checkIdCookie,
  eTagCookie,
  firstLoadCookie,
  siteIdCookie,
} from 'types/constants';
import { AppliedPaymentInfo, Check } from 'types/DTOs';
import { getAppliedPaymentInfo, getCheckInfo } from './commonApi';
import { validateCheckCode } from './validation';

export interface CheckInfoState {
  checkCode: string;
  checkDetails: Check | null;
  checkDetailsStatus: 'idle' | 'loading' | 'failed';
  checkDetailsErrorResponse: AxiosResponse<string> | null;
  failedCheckLoadAttempts: number;
  appliedPaymentInfo: AppliedPaymentInfo | null;
  paymentInfoStatus: 'idle' | 'loading' | 'failed';
  paymentInfoErrorResponse: AxiosResponse<string> | null;
  failedPaymentInfoLoadAttempts: number;
  isFirstLoad: boolean;
  eTag: string;
  refreshIntervalId: number;
}

const initialState: CheckInfoState = {
  checkCode: '',
  checkDetails: null,
  checkDetailsStatus: 'idle',
  checkDetailsErrorResponse: null,
  failedCheckLoadAttempts: 0,
  appliedPaymentInfo: null,
  paymentInfoStatus: 'idle',
  failedPaymentInfoLoadAttempts: 0,
  paymentInfoErrorResponse: null,
  isFirstLoad: true,
  eTag: '',
  refreshIntervalId: 0,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(loadCheckInfo('ncr135'))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadCheckInfo = createAsyncThunk<
  { data: Check; etag: string },
  string,
  {
    state: any;
    rejectValue: { data: string; status: ResponseCode; headers: any };
  } //TODO: Make this a type
>(
  'checkInfo/loadCheckInfo',
  async (checkCode: string, { rejectWithValue, getState }) => {
    try {
      if (validateCheckCode(checkCode)) {
        const firstLoad = getState().checkInfo.isFirstLoad;
        const { data, headers } = await getCheckInfo(checkCode, firstLoad);
        const etag = headers.etag;

        // The value we return becomes the `fulfilled` action payload
        return { data, etag };
      }
      return rejectWithValue(
        createAxiosResponse(
          `${checkCode} is not a valid check code`,
          ResponseCode.BadRequest
        )
      );
    } catch (error: any) {
      console.log(error);
      if (!error.data || !error.status) {
        throw error;
      }
      return rejectWithValue({
        data: error.data,
        status: error.status,
        headers: error.headers,
      });
    }
  }
);

export const loadPaymentInfo = createAsyncThunk<
  AppliedPaymentInfo,
  string,
  { rejectValue: { data: string; status: ResponseCode; headers: any } }
>(
  'checkInfo/loadPaymentInfo',
  async (checkCode: string, { rejectWithValue }) => {
    try {
      if (validateCheckCode(checkCode)) {
        const { data } = await getAppliedPaymentInfo(checkCode);
        return data;
      }
      return rejectWithValue(
        createAxiosResponse(
          `${checkCode} is not a valid check code`,
          ResponseCode.BadRequest
        )
      );
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const checkInfoSlice = createSlice({
  name: 'checkInfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCheckData: () => {
      return { ...initialState };
    },
    setCheckCode: (state, { payload }: PayloadAction<string>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.checkCode = payload;
      state.checkDetails = null;
    },
    setCheckInfo: (state, { payload }: PayloadAction<CheckInfoState>) => {
      return payload;
    },
    setPaymentInfo: (state, { payload }: PayloadAction<AppliedPaymentInfo>) => {
      state.appliedPaymentInfo = payload;
    },
    setRefreshIntervalId: (state, { payload }: PayloadAction<any>) => {
      state.refreshIntervalId = payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadCheckInfo.pending, (state) => {
        if (state.isFirstLoad) {
          state.checkDetailsStatus = 'loading';
        }
        state.checkDetailsErrorResponse = null;
      })
      .addCase(loadCheckInfo.fulfilled, (state, { payload }) => {
        state.checkDetailsStatus = 'idle';
        state.checkDetails = payload.data;
        state.failedCheckLoadAttempts = 0;
        state.eTag = payload.etag;

        const cookies = new Cookies();
        cookies.set(checkIdCookie, payload?.data.CheckId, {
          path: '/',
          maxAge: 3600 * 24,
        });
        cookies.set(siteIdCookie, payload?.data.SiteId, {
          path: '/',
          maxAge: 3600 * 24,
        });
        cookies.set(eTagCookie, payload?.etag, {
          path: '/',
          maxAge: 3600 * 24,
        });
      })
      .addCase(loadCheckInfo.rejected, (state, { payload }) => {
        state.checkDetailsStatus = 'failed';
        // Storing the whole AxiosResponse generates a non-serializable error from redux so using this helper function strips out the non-serializable parts we probably won't use anyway
        state.checkDetailsErrorResponse = payload
          ? createAxiosResponse(payload?.data, payload?.status, payload.headers)
          : createAxiosResponse('Unknown error', ResponseCode.Error);
        state.failedCheckLoadAttempts += 1;
      })
      .addCase(loadPaymentInfo.pending, (state) => {
        if (state.isFirstLoad) {
          state.paymentInfoStatus = 'loading';
        }
        state.paymentInfoErrorResponse = null;
      })
      .addCase(loadPaymentInfo.fulfilled, (state, { payload }) => {
        const cookies = new Cookies();

        state.paymentInfoStatus = 'idle';
        state.appliedPaymentInfo = payload;
        state.failedPaymentInfoLoadAttempts = 0;
        state.isFirstLoad = false;

        cookies.set(firstLoadCookie, false, {
          path: '/',
          maxAge: 3600 * 24,
        });
      })
      .addCase(loadPaymentInfo.rejected, (state, { payload }) => {
        state.paymentInfoStatus = 'failed';
        state.failedPaymentInfoLoadAttempts += 1;
        state.paymentInfoErrorResponse = payload
          ? createAxiosResponse(payload?.data, payload?.status, payload.headers)
          : createAxiosResponse('Unknown error', ResponseCode.Error);
      });
  },
});

export const {
  resetCheckData,
  setCheckInfo,
  setCheckCode,
  setPaymentInfo,
  setRefreshIntervalId,
} = checkInfoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCheckCode = (state: RootState) => state.checkInfo.checkCode;
export const selectCheckInfo = (state: RootState) => state.checkInfo;
export const selectCheckDetails = (state: RootState) =>
  state.checkInfo.checkDetails;
export const selectAppliedPaymentInfo = (state: RootState) =>
  state.checkInfo.appliedPaymentInfo;
export const selectCheckDetailsStatus = (state: RootState) =>
  state.checkInfo.checkDetailsStatus;

export default checkInfoSlice.reducer;
