//#region FreedomPay
export interface FreedomPaymentKeyData {
  attributes: FPKeyDataAttribute[];
  paymentKeys: string[];
  paymentType: string;
}

export interface FPKeyDataAttribute {
  Key: string;
  Value: string;
}
//#endregion FreedomPay

//#region Google/ApplePay
export interface GoogleApplePaymentKeyData {
  attributes: any;
  paymentKeys: string[];
  paymentType: string;
}
//#endregion Google/ApplePay

//#region Enum
export enum FreedomPayMessageType {
  ERROR = 1,
  SET_HEIGHT = 2,
  SUBMIT = 3,
  THREEDS_HANDLE = 16,
}
//#endregion Enum
