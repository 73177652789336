import styled from "styled-components";

export const SubtotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 24px 12px 24px;
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 28px;
    line-height: 33px;
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 24px 24px 24px;
  font-weight: bold;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 32px;
    line-height: 33px;
  }
`;