import { Divider } from 'components/Divider';
import styled from 'styled-components';

interface IFrameProps{
  disabled?:boolean;
}

export const StyledFreedomPayIFrame = styled.iframe<{
  height: string;
  is3dsEnabled: boolean;
}>`
  width: 100%;
  height: ${({ height }) => height || '100%'};
  background-color: white;
  border: 0;
  margin-top: ${({ is3dsEnabled }) => (is3dsEnabled ? '0px' : '-10px')};
`;

export const StyledAdditionalInputsDiv = styled.div`
  border: none;
  display: block;
  line-height: 1.5;
  font: 16px Arial, sans-serif;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .control-group {
    margin-bottom: 1rem;
    position: relative;
  }
`;

export const StyledAdditionalInputsForm = styled.form`
  display: block;
  padding: 10px 10px 0 10px;
  width: 100%;
  background-color: white;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
`;

export const StyledFPInputSectionDiv = styled.div`
  text-align: left;
`;

export const StyledFPIFrameSectionDiv = styled.div<{
  disabled: boolean;
}>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`;

export const StyledFPInputLabel = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
  color: black;
`;

export const StyledFPInputDiv = styled.div`
  display: block;
`;

export const StyledFPInput = styled.input`
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #939393;
  border-radius: 0.25rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  height: calc(2.25rem + 2px);
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;

  &:focus {
    border-color: #2990ff;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    outline: 0;

    &.valid {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 25%);
    }

    &.invalid {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    }
  }

  &.valid {
    border-color: #28a745;
  }

  &.invalid {
    border-color: #dc3545;
  }

  ::placeholder {
    color: rgb(117, 117, 117);
  }
`;

export const StyledFPInputValidationDiv = styled.div`
  display: block;
  color: #dc3545;
  font-size: 80%;
  margin-top: 0.25rem;
  width: 100%;
  line-height: 1.5;
`;

export const StyledFPPayButtonDiv = styled.div<{
  disabled: boolean;
}>`
  padding: 10px;
  background-color: white;
  margin-bottom: 100px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`;

export const StyledFPPayButton = styled.button`
  background-color: #6c757d;
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  width: 100%;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
    outline: 0;
  }

  &:hover {
    background-color: #5a6268;
    border-color: #5a6268;
  }
`;

export const StyledFPGoogleDiv = styled.div<{
  disabled: boolean;
}>`
  border: none;
  padding: 10px 10px 10px 10px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`;

export const StyledFPGoogleApplePayIframe = styled.iframe<IFrameProps>`
  width: 100%;
  border: none;
  padding: 8px 0 0 0;
  height: 44px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'}; /* Disable pointer events */
`;


export const StyledDividerDiv = styled.div`
  width: 100%;
  padding: 8px 0 12px 0;
  font-size: 13px;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 21px;
  }
`;

export const StyledDivierSpan = styled.span`
  text-align: center;
  display: table;
  margin: 0 auto;
  vertical-align: middle;
`;

export const StyledGoogleApplePayLoadingBtn = styled.button`
  background-color: ${({ theme }) =>
    `${theme.colors.buttonDisabled.background}}`};
  color: ${({ theme }) => `${theme.colors.buttonDisabled.text}`};
  width: 100%;
  padding: 12.5px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 0.2px;
  border: ${({ theme }) => `1px solid ${theme.colors.buttonDisabled.border}`};
  display: block;
  margin-top: 8px;
  border-radius: 11px;
  min-height: 40px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 10px;
    padding: 8px 0;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
`;

export const StyledFpIframeDivider = styled(Divider)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.check};
  margin-top: 32px;
`;
