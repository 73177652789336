import styled from 'styled-components/macro';

const DividerLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.welcome};
  width: 200px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    width: 416px;
  }
`;

const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface DividerProps {
  className?: string;
}

export const Divider = ({ className }: DividerProps) => {
  return (
    <DividerContainer>
      <DividerLine className={className} />
    </DividerContainer>
  );
};
