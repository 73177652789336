import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  loadCheckInfo,
  loadPaymentInfo,
  selectCheckInfo,
  setCheckCode,
} from 'features/Common/checkInfoSlice';
import {
  loadSiteConfig,
  selectSiteConfig,
} from 'features/Common/siteConfigurationSlice';
import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { codeCookie } from 'types/constants';

export const useCheckData = () => {
  // TODO: update this name everywhere to match the backend logging name - CheckCode or maybe we just stop the cookie logging
  const [cookies] = useCookies([codeCookie]);

  // Redux Store
  const dispatch = useAppDispatch();
  const {
    checkCode,
    checkDetails,
    checkDetailsStatus,
    checkDetailsErrorResponse,
    failedCheckLoadAttempts,
    failedPaymentInfoLoadAttempts,
    appliedPaymentInfo,
    paymentInfoStatus,
    paymentInfoErrorResponse,
    isFirstLoad,
    eTag,
    refreshIntervalId,
  } = useAppSelector(selectCheckInfo);
  const { siteConfig, siteConfigStatus, failedSiteConfigLoadAttempts } =
    useAppSelector(selectSiteConfig);

  // Local State
  const checkDataError =
    checkDetailsStatus === 'failed' || paymentInfoStatus === 'failed';
  const checkDataLoading =
    checkDetailsStatus === 'loading' || paymentInfoStatus === 'loading';
  const balanceIsDue = appliedPaymentInfo
    ? !!(!appliedPaymentInfo.Closed && appliedPaymentInfo.Balance > 0)
    : true;
  const loyaltyIsPresentOnCheck = checkDetails && checkDetails?.LoyaltyNumber;
  const isPartiallyPaid = (appliedPaymentInfo?.AmountPaid || 0) > 0;

  //#region Functions
  const checkInfoHasErrorOrNoData = useCallback(
    () =>
      checkDetailsStatus === 'failed' ||
      (checkDetailsStatus === 'idle' && !checkDetails),
    [checkDetails, checkDetailsStatus]
  );

  const paymentInfoHasErrorOrNoData = useCallback(
    () =>
      paymentInfoStatus === 'failed' ||
      (paymentInfoStatus === 'idle' && !appliedPaymentInfo),
    [appliedPaymentInfo, paymentInfoStatus]
  );

  const siteConfigHasErrorOrNoData = useCallback(
    () =>
      siteConfigStatus === 'failed' ||
      (siteConfigStatus === 'idle' && !siteConfig),
    [siteConfig, siteConfigStatus]
  );
  //#endregion Functions

  // Re-load check data or payment data when there is an error or no data
  useEffect(() => {
    if (
      failedCheckLoadAttempts <= 1 &&
      failedPaymentInfoLoadAttempts <= 1 &&
      failedSiteConfigLoadAttempts <= 1 &&
      !checkDataLoading
    ) {
      if (checkInfoHasErrorOrNoData()) {
        dispatch(loadCheckInfo(cookies.code));
        dispatch(setCheckCode(cookies.code));
      }

      if (paymentInfoHasErrorOrNoData()) {
        dispatch(loadPaymentInfo(cookies.code));
      }

      if (siteConfigHasErrorOrNoData()) {
        dispatch(loadSiteConfig(cookies.code));
      }
    }

    // TODO: Should customers navigate to code page again if either of one out of check/payment/siteconfig info keeps failing to load?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    checkInfoHasErrorOrNoData,
    paymentInfoHasErrorOrNoData,
    siteConfigHasErrorOrNoData,
    failedCheckLoadAttempts,
    cookies,
  ]);

  return {
    checkCode,
    checkDetails,
    checkDetailsStatus,
    appliedPaymentInfo,
    paymentInfoStatus,
    checkDataError,
    checkDataLoading,
    checkDetailsErrorResponse,
    paymentInfoErrorResponse,
    balanceIsDue,
    loyaltyIsPresentOnCheck,
    isPartiallyPaid,
    isFirstLoad,
    eTag,
    refreshIntervalId,
  };
};
