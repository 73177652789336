import {
  CheckItemContainer,
  CheckItemValue,
  CheckRowContainer,
} from 'components/CheckView';
import { getPriceView } from 'features/Common/utils';
import Skeleton from 'react-loading-skeleton';

interface CheckViewRowProps {
  id?: string;
  label?: string;
  price?: string | number;
  loading?: boolean;
  loadingLength?: number;
  bold?: boolean;
  className?: string;
  showIfZero?: boolean;
  showAsNegative?: boolean;
}

export const CheckRow = ({
  id,
  label,
  price,
  loading,
  loadingLength,
  bold,
  className,
  showIfZero,
  showAsNegative,
}: CheckViewRowProps) => {
  return (
    <CheckRowContainer id={id}>
      <CheckItemContainer>
        <CheckItemValue bold={bold} className={className}>
          {loading ? <Skeleton width={loadingLength || 140} /> : label}
        </CheckItemValue>
        <CheckItemValue bold={bold} className={className}>
          {loading ? (
            <Skeleton width={55} />
          ) : (
            getPriceView(price, showAsNegative, showIfZero)
          )}
        </CheckItemValue>
      </CheckItemContainer>
    </CheckRowContainer>
  );
};
