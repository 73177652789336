import { useAppDispatch } from 'app/hooks';
import { ContentContainer } from 'components/ContentContainer';
import { MpHeader } from 'components/Header';
import { Thankyou } from 'components/Thankyou';
import { PaidInfo } from 'components/Thankyou/PaidInfo';
import { ReceiptSent } from 'components/Thankyou/ReceiptSent';
import { setCurrentPage } from 'features/Common/userPageSlice';
import { removeFreedomPayScript } from 'features/Common/utils';
import { EmailInput } from 'features/EmailInput';
import successIcon from 'images/check_circle_outline.svg';
import {
  PaidSuccessIcon,
  SuccessfulPaymentContainer,
} from 'pages/PaymentConfirmation/ConfirmationPageComponents';
import { useEffect, useState } from 'react';
import { PageName } from 'types/constants';

export const PaymentConfirmationPage = () => {
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [emailId, setEmailId] = useState<string>('');
  const handleEmailSend = (emailId: string) => {
    setEmailId(emailId);
    setEmailSent(true);
  };
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      window.scrollTo(0, 0);
      dispatch(setCurrentPage(PageName.PaymentConfirmation));
      removeFreedomPayScript();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <MpHeader />
      <ContentContainer>
        <SuccessfulPaymentContainer>
          <PaidSuccessIcon src={successIcon} />
          {emailSent ? <ReceiptSent emailId={emailId} /> : <PaidInfo />}
        </SuccessfulPaymentContainer>
        {!emailSent && <EmailInput onEmailSent={handleEmailSend} />}
        <Thankyou slideUp={emailSent} />
      </ContentContainer>
    </>
  );
};
