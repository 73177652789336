import styled from 'styled-components/macro';

export const TipButton = styled.div<{ selected: boolean; disabled: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 31%;
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.colors.tipButton.activeBackground
      : theme.colors.tipButton.background};
  border: 1px solid ${({ theme }) => theme.colors.tipButton.border};
  font-feature-settings: 'pnum' on, 'lnum' on;
  min-height: 54px;
  border-radius: 11px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    min-height: 80px;
  }
`;
