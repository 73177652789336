import styled from 'styled-components';

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-top: 15px;
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
