import { isDemo } from 'app/utils';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { ResponseCode } from '.';
import { mockApi } from './mock/mockApi';
import { mpApi } from './mpApi';

export const getApi = () => (isDemo() ? mockApi : mpApi);

export function createAxiosResponse<T>(
  data: T,
  responseCode: ResponseCode = ResponseCode.OK,
  headers: any = {}
): AxiosResponse<T> {
  return {
    data,
    status: responseCode,
    statusText: ResponseCode[responseCode],
    headers,
    config: {} as InternalAxiosRequestConfig,
  };
}
