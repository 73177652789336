import { TipButton } from 'components/TipButtons';
import currency, { Options } from 'currency.js';
import { CircleLoader } from 'react-spinners';
import styled from 'styled-components/macro';
import { TipBase } from 'types/DTOs';
import { PERCENT } from 'types/DTOs/TipBase';
import { TipBaseSubText } from './TipBaseSubText';

interface TipSuggestionButtonProps {
  selected: boolean;
  type: TipBase; // Currently either 'percent' or 'dollars'
  percent?: number;
  amount: number;
  loading?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  disabled: boolean;
}

const PercentTip = styled.div`
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-weight: bold;
    font-size: 24px;
    line-height: 39px;
  }
`;

const DollarTip = styled.div``;

const removeCentsIfZero = (
  amt: currency | undefined,
  options: Options | undefined
) => {
  return amt?.cents() === 0
    ? `$${amt.dollars()}`
    : `$${amt?.dollars()}.${amt?.cents()}`;
};

export const TipSuggestionButton = ({
  selected,
  type,
  percent,
  amount,
  loading,
  onClick,
  disabled,
}: TipSuggestionButtonProps) => {
  return (
    <TipButton
      data-percent={type === PERCENT ? percent : amount}
      selected={selected}
      onClick={loading ? () => {} : onClick}
      disabled={disabled}
    >
      {loading ? (
        <CircleLoader size={25} color="white" />
      ) : type === PERCENT ? (
        <>
          <PercentTip id='tip-percentage'>{percent}%</PercentTip>
          <TipBaseSubText id='tip-total'>{currency(amount).format()}</TipBaseSubText>
        </>
      ) : (
        <DollarTip id='tip-percentage'>
          {currency(amount, { format: removeCentsIfZero }).format()}
        </DollarTip>
      )}
    </TipButton>
  );
};
