import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface GiftCardState {
  isGiftCardApplied: boolean; // true => GC applied or loyalty card having ASV balance applied
  giftCardAmountApplied: number; // The amount of dollars applied by ASV
  giftCardNumber?: string;
  giftCardPin?: string;
  totalGiftCardBalance: number;
  isGiftCardAmountPaid: boolean; // true => GC amount has been paid, required if errors occur in payment
  balanceRemaining: number; // balance remaining after GC amount is paid
  tipPaid: number; // tip paid using GC
  giftCardPaymentId?: number;
  isLoyaltyASVCard: boolean; // true => loyalty is having ASV balance
}

const initialState: GiftCardState = {
  isGiftCardApplied: false,
  giftCardAmountApplied: 0,
  giftCardNumber: undefined,
  giftCardPin: undefined,
  totalGiftCardBalance: 0,
  isGiftCardAmountPaid: false,
  balanceRemaining: 0,
  tipPaid: 0,
  giftCardPaymentId: undefined,
  isLoyaltyASVCard: false,
};

export const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    resetGiftCardSlice: () => {
      return { ...initialState };
    },
    setTipPaid: (state, { payload }: PayloadAction<number>) => {
      state.tipPaid = payload;
    },
    setBalanceRemaining: (state, { payload }: PayloadAction<number>) => {
      state.balanceRemaining = payload;
    },
    setGiftCardAmountApplied: (state, { payload }: PayloadAction<number>) => {
      state.giftCardAmountApplied = payload;
      state.isGiftCardApplied = true;
    },
    resetGiftCardApplied: (state) => {
      state.giftCardAmountApplied = 0;
      state.isGiftCardApplied = false;
    },
    setGiftCardNumber: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.giftCardNumber = payload;
    },
    setGiftCardPin: (state, { payload }: PayloadAction<string | undefined>) => {
      state.giftCardPin = payload;
    },
    setTotalGiftCardBalance: (state, { payload }: PayloadAction<number>) => {
      state.totalGiftCardBalance = payload;
    },
    setIsGiftCardAmountPaid: (state, { payload }: PayloadAction<boolean>) => {
      state.isGiftCardAmountPaid = payload;
    },
    setGiftCardPaymentId: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.giftCardPaymentId = payload;
      state.isGiftCardAmountPaid = true;
    },
    setIsLoyaltyASVCard: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoyaltyASVCard = payload;
    },
    clearGiftCardInputs: (state) => {
      state.isGiftCardApplied = false;
      state.giftCardAmountApplied = 0;
      state.giftCardNumber = undefined;
      state.giftCardPin = undefined;
      state.totalGiftCardBalance = 0;
      state.isGiftCardAmountPaid = false;
      state.balanceRemaining = 0;
      state.tipPaid = 0;
      state.giftCardPaymentId = undefined;
      state.isLoyaltyASVCard = false;
    },
  },
});

export const {
  resetGiftCardSlice,
  setTipPaid,
  setBalanceRemaining,
  setGiftCardAmountApplied,
  resetGiftCardApplied,
  setGiftCardNumber,
  setGiftCardPin,
  setTotalGiftCardBalance,
  setIsGiftCardAmountPaid,
  setGiftCardPaymentId,
  setIsLoyaltyASVCard,
  clearGiftCardInputs,
} = giftCardSlice.actions;

export const selectGiftCardState = (state: RootState) => state.giftCard;

export default giftCardSlice.reducer;
