import { useAppDispatch, useAppSelector } from 'app/hooks';
import { store } from 'app/store';
import { getSubdomain, isDemo } from 'app/utils';
import currency from 'currency.js';
import { setPreDiscountItemsTotal } from 'features/AddLoyaltySection/LoyaltyApi';
import {
  selectLoyaltyState,
  verifyLoyaltyStatus,
} from 'features/AddLoyaltySection/LoyaltySlice';
import {
  loadCheckInfo,
  loadPaymentInfo,
  setPaymentInfo,
} from 'features/Common/checkInfoSlice';
import { getAppliedPaymentInfo } from 'features/Common/commonApi';
import {
  validateCreditCardInfo,
  validateMaxPaymentThreshold,
} from 'features/Common/validation';
import {
  newCreditCardPayment,
  payNoBalance,
} from 'features/CreditCardInputSection/edcApi';
import { useCreditCardInput } from 'features/CreditCardInputSection/useCreditCardInput';
import { giftCardPay } from 'features/GiftCardSection/GiftCardApi';
import {
  selectGiftCardState,
  setBalanceRemaining,
  setGiftCardPaymentId,
  setIsGiftCardAmountPaid,
  setTipPaid,
} from 'features/GiftCardSection/GiftCardSlice';
import { ModalOptions } from 'features/Modal/modalSlice';
import {
  navigateToCheckPage,
  navigateToHomePage,
  navigateToPaymentConfirmationPage,
} from 'pages/navigation';
import { openDrawer } from 'pages/Payment/Drawer/drawerSlice';
import { StyledPayButton } from 'pages/Payment/PaymentPageComponents';
import {
  selectPaymentState,
  setPaidAmount,
  setPaymentId,
  updatePaymentTotal,
} from 'pages/Payment/paymentSlice';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalType } from 'types';
import {
  edcPayButtonId,
  ncrSecurePaymentPayButtonId,
  PageName,
} from 'types/constants';
import { AppliedPaymentInfo, Check, SiteConfiguration } from 'types/DTOs';
import { GiftCardPayRequest } from 'types/DTOs/GiftCard';
import {
  creditCardPaymentRequest,
  PayNoBalanceRequest,
} from 'types/DTOs/PaymentRequests';
import { PaymentMethod } from 'types/DTOs/SiteConfiguration';
import {
  generalErrorMessages,
  modalHeaders,
  paymentErrorMessages,
} from 'types/strings';

interface PaymentButtonProps {
  checkCode: string;
  checkDetails: Check | null;
  appliedPaymentInfo: AppliedPaymentInfo | null;
  siteConfig: SiteConfiguration | null;
  disabled: boolean;
  loading: boolean;
  setIsPaymentProcess: (value: React.SetStateAction<boolean>) => void;
  setIsIframeLoading: (value: boolean) => void;
  clearSensitiveData: (clearGCInputs: boolean) => void;
  displayModal: ({ type, header, text }: ModalOptions) => void;
}

export const PayButton = ({
  checkCode,
  checkDetails,
  appliedPaymentInfo,
  siteConfig,
  disabled,
  loading,
  setIsPaymentProcess,
  setIsIframeLoading,
  clearSensitiveData,
  displayModal,
}: PaymentButtonProps) => {
  // React Store
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { selectedTip, paymentTotal } = useAppSelector(selectPaymentState);
  const { loyaltyDiscountAmountApplied, loyaltyIsAssigned } =
    useAppSelector(selectLoyaltyState);
  const {
    giftCardNumber,
    giftCardPin,
    isGiftCardApplied,
    isLoyaltyASVCard,
    isGiftCardAmountPaid,
    tipPaid,
  } = useAppSelector(selectGiftCardState);
  const {
    creditCardNumber,
    creditCardNumberLimit,
    creditCardExp,
    creditCardCvv,
    creditCardCvvLimit,
    creditCardZip,
  } = useCreditCardInput();

  // Local States
  const payButtonId = useMemo(() => {
    const paymentMethod = siteConfig?.PaymentMethod;
    if (
      paymentMethod === PaymentMethod.CP ||
      paymentMethod === PaymentMethod.FP
    )
      return ncrSecurePaymentPayButtonId;

    return edcPayButtonId;
  }, [siteConfig]);
  const payButtonMessage = useMemo(
    () =>
      paymentTotal < 0.01
        ? `Complete Payment`
        : `Pay ${currency(paymentTotal).format()}`,
    [paymentTotal]
  );

  //#region Utils
  // Verify if the check is available to be paid
  const isPaymentEnabled = (paymentInfo: AppliedPaymentInfo) => {
    // Returns null if checkInfo isn't available
    if (!checkDetails) {
      return null;
    }

    // Returns false if this check is closed
    if (checkDetails.Closed) {
      return false;
    }

    const tipRemaining = currency(selectedTip.amount).subtract(
      store.getState().giftCard.tipPaid
    ).value;
    if (tipRemaining >= 0.01) {
      return true;
    }

    // Caution:
    // Balance can be negative. A negative balance means that cash payment was made at a POS terminal,
    // and that the guest is due change.  In that case, the balance will be the negative of the change.
    // So, be sure this logic interprets a negative balance as "fully paid".
    if (paymentInfo.Balance) {
      // TODO: Add a case when 100% loyalty is applied and only tip should be paid.
      // (ex. Balance is 0, but selected tip does still exist.)

      return paymentInfo.Balance >= 0.01 && !paymentInfo.Closed;
    }
  };

  const errorHandler = (
    modalType: ModalType,
    modalHeader: string,
    modalText: string,
    navigatePage?: PageName
  ) => {
    displayModal({
      type: modalType,
      header: modalHeader,
      text: modalText,
    });

    if (navigatePage) {
      switch (navigatePage) {
        case PageName.Code:
          navigateToHomePage(history);
          break;
        case PageName.Check:
          navigateToCheckPage(history);
          break;
      }
    }
  };
  //#endregion Utils

  //#region Payment Handlers
  const handleSecurePayment = (paymentMethod: PaymentMethod) => {
    dispatch(openDrawer());
    setIsPaymentProcess(false);

    if (paymentMethod === PaymentMethod.FP) setIsIframeLoading(true);
  };

  const handlePortalPayment = async (
    GCSplitRemaining?: number,
    GCTipRemaining?: number
  ) => {
    const paymentMethod = siteConfig?.PaymentMethod;
    if (!paymentMethod) {
      errorHandler('error', 'Error', generalErrorMessages.uhOhMessage);
      setIsPaymentProcess(false);
      return;
    }

    if (
      paymentMethod === PaymentMethod.CP ||
      paymentMethod === PaymentMethod.FP
    ) {
      handleSecurePayment(paymentMethod);
      return;
    }

    if (paymentMethod === PaymentMethod.EDC) {
      handleEdcPayment(GCSplitRemaining, GCTipRemaining);
      return;
    }

    errorHandler('error', 'Error', generalErrorMessages.uhOhMessage);
    setIsPaymentProcess(false);
    return;
  };

  const handlePaymentBtn = async () => {
    try {
      setIsPaymentProcess(true);
      // Refresh paymentInfo
      // Ensure check still has a balance.
      // This prevents double-paying when the check was already paid at a POS terminal.
      const { data: paymentInfo } = await getAppliedPaymentInfo(checkCode);
      if (paymentInfo === null) {
        errorHandler(
          'error',
          'Error',
          paymentErrorMessages.refreshPaymentInfoFailure,
          PageName.Check
        );
        setIsPaymentProcess(false);
        return;
      }
      dispatch(setPaymentInfo(paymentInfo));

      // If total to pay is 0, make a payment of 0 balance
      if (paymentInfo.Balance <= 0 && selectedTip.amount === 0) {
        // TODO: After loyalty is implemented especially 100% loyalty, use NoPayBalance call
        const result = await noBalancePay();
        if (result.type === 'success') {
          clearSensitiveData(true);
          navigateToPaymentConfirmationPage(history);
        } else {
          errorHandler(
            'error',
            'Error',
            result.text ? result.text : paymentErrorMessages.processingFailure
          );
          setIsPaymentProcess(false);
        }
        return;
      }

      // If the check is unable to be paid, transition back to check page
      if (!isPaymentEnabled(paymentInfo)) {
        errorHandler(
          'message',
          modalHeaders.noPaymentNecessary,
          paymentErrorMessages.noPaymentNecessary,
          PageName.Check
        );
        setIsPaymentProcess(false);
        return;
      }

      // Try to store subtotal (items total) for email receipt
      const response = await setPreDiscountItemsTotal(
        checkCode,
        checkDetails?.PreDiscountItemsTotal || 0
      );
      if (response.status < 0) {
        errorHandler('error', 'Error', paymentErrorMessages.processingFailure);
        return;
      }

      if (isGiftCardApplied && !isGiftCardAmountPaid) {
        // make GC payment and refresh payment
        // this called portal payment is there is remaining balance
        handleGiftCardPayment();
      } else {
        handlePortalPayment();
      }
    } catch (error: any) {
      errorHandler(
        'error',
        modalHeaders.paymentDeclined,
        generalErrorMessages.uhOhMessage
      );
      setIsPaymentProcess(false);
    }
  };
  //#endregion Payment Handlers

  //#region NO Balance Payment
  const noBalancePay = async (): Promise<ModalOptions> => {
    try {
      // Refresh paymentInfo
      const { data } = await getAppliedPaymentInfo(checkCode);
      if (data === null) {
        navigateToCheckPage(history);
        return {
          type: 'error',
          text: paymentErrorMessages.refreshPaymentInfoFailure,
        };
      }
      dispatch(setPaymentInfo(data));

      // Attempt to make a payment
      if (appliedPaymentInfo) {
        const noBalancePaymentRequest: PayNoBalanceRequest = {
          code: checkCode,
          balance: appliedPaymentInfo.Balance,
          tax: appliedPaymentInfo.Tax,
          tip: selectedTip.amount,
          loyaltySavings: loyaltyDiscountAmountApplied,
          loyaltyApplied: loyaltyIsAssigned,
          gratuity: appliedPaymentInfo.Gratuity,
          subdomain: getSubdomain(),
        };
        const noBalancePaymentResult = await payNoBalance(
          noBalancePaymentRequest
        );
        const { data } = await getAppliedPaymentInfo(checkCode);
        dispatch(setPaymentInfo(data));

        if (data === null) {
          // If failed to refresh paymentInfo, send user back to check page with an error modal
          navigateToCheckPage(history);
          return {
            type: 'error',
            text: paymentErrorMessages.refreshPaymentInfoFailure,
          };
        } else {
          if (
            noBalancePaymentResult.data === null ||
            noBalancePaymentResult.data.Status < 0
          ) {
            // When payment got declined, show error modal
            return {
              type: 'error',
              header: modalHeaders.paymentDeclined,
              text: noBalancePaymentResult.data.Message || '',
            };
          }

          // If balance does still exist, stay in payment page with new info
          if (data.Balance > 0) {
            dispatch(loadCheckInfo(checkCode));
            dispatch(loadPaymentInfo(checkCode));

            // TODO: Need a better message
            return {
              type: 'error',
              header: modalHeaders.paymentDeclined,
              text: generalErrorMessages.uhOhMessage,
            };
          }

          // If payment completed, send to confirmation page
          dispatch(setPaymentId(noBalancePaymentResult.data.PaymentId));
          return { type: 'success' };
        }
      } else {
        return {
          type: 'error',
          header: modalHeaders.paymentDeclined,
          text: generalErrorMessages.uhOhMessage,
        };
      }
    } catch (error) {
      return {
        type: 'error',
        header: modalHeaders.paymentDeclined,
        text: generalErrorMessages.uhOhMessage,
      };
    }
  };
  //#endregion NO Balance Payment

  //#region Gift Card / ASV
  const handleGiftCardPayment = () => {
    makeGiftCardPayment()
      .then((resultModalOption) => {
        // Open a modal if gift card payment wasn't successful
        if (resultModalOption.type !== 'success') {
          // refresh check info for NCRMP-860: loyalty is applied when combo card used as GC
          dispatch(loadCheckInfo(checkCode));
          dispatch(loadPaymentInfo(checkCode));
          dispatch(verifyLoyaltyStatus());
          displayModal(resultModalOption);
          setIsPaymentProcess(false);
        }
      })
      .catch((error) => {
        clearSensitiveData(true);
        displayModal({
          type: 'error',
          header: modalHeaders.paymentDeclined,
          text: generalErrorMessages.uhOhMessage,
        });
        setIsPaymentProcess(false);
      });
  };

  const makeGiftCardPayment = async (): Promise<ModalOptions> => {
    try {
      if (appliedPaymentInfo && giftCardNumber && giftCardPin) {
        // TODO: Add loyaltyIsAssigned and loyaltySavings after loyalty implementation
        const giftCardPayRequest: GiftCardPayRequest = {
          code: checkCode,
          balance: appliedPaymentInfo.Balance,
          tax: appliedPaymentInfo.Tax,
          tip: selectedTip.amount,
          loyaltySavings: paymentTotal <= 0 ? loyaltyDiscountAmountApplied : 0,
          loyaltyApplied: loyaltyIsAssigned,
          gratuity: appliedPaymentInfo.Gratuity,
          cardNumber: giftCardNumber,
          pin: giftCardPin,
          subdomain: getSubdomain(),
        };
        const giftCardPayResult = await giftCardPay(giftCardPayRequest);

        if (giftCardPayResult.data && giftCardPayResult.data.Status === 1) {
          return {
            type: 'warning',
            text: giftCardPayResult.data.Message,
          };
        } else if (giftCardPayResult.data.Status !== 0) {
          // When gift card payment got declined, show error modal and reset GC inputs
          clearSensitiveData(!isLoyaltyASVCard);
          dispatch(updatePaymentTotal());
          return {
            type: 'error',
            text: giftCardPayResult.data.Message,
          };
        }
        // Gift card is successfully paid
        // Store the tip paid and balance remaining after GC payment
        dispatch(setBalanceRemaining(giftCardPayResult.data.SplitRemaining));
        dispatch(
          setTipPaid(
            currency(selectedTip.amount).subtract(
              giftCardPayResult.data.TipRemaining
            ).value
          )
        );
        dispatch(setIsGiftCardAmountPaid(true));
        dispatch(setGiftCardPaymentId(giftCardPayResult.data.PaymentId));

        // Refresh check info and fetch payment info
        dispatch(loadCheckInfo(checkCode));
        const { data } = await getAppliedPaymentInfo(checkCode);

        if (data === null) {
          // Balance will be refreshed here (covers balance already paid through GC)
          dispatch(loadPaymentInfo(checkCode));
          return {
            type: 'error',
            text: paymentErrorMessages.refreshPaymentInfoFailure,
          };
        }

        dispatch(setPaymentInfo(data));
        dispatch(setPaidAmount(data.AmountPaid));
        dispatch(updatePaymentTotal());

        if (
          !isPaymentEnabled(data) &&
          giftCardPayResult.data.TipRemaining <= 0.01
        ) {
          dispatch(setPaymentId(giftCardPayResult.data.PaymentId));
          clearSensitiveData(true);
          setIsPaymentProcess(false);
          navigateToPaymentConfirmationPage(history);
        } else {
          handlePortalPayment(
            giftCardPayResult.data.SplitRemaining,
            giftCardPayResult.data.TipRemaining
          );
        }
        return { type: 'success' };
      } else {
        return {
          type: 'error',
          header: modalHeaders.paymentDeclined,
          text: generalErrorMessages.uhOhMessage,
        };
      }
    } catch (error: any) {
      clearSensitiveData(true);
      return {
        type: 'error',
        header: modalHeaders.paymentDeclined,
        text: generalErrorMessages.uhOhMessage,
      };
    }
  };
  //#endregion Gift Card / ASV

  //#region EDC Flow
  const handleEdcPayment = (
    GCSplitRemaining?: number,
    GCTipRemaining?: number
  ) => {
    // Try to make a new credit card payment
    makeEdcPayment(GCSplitRemaining, GCTipRemaining)
      .then((resultModalOption) => {
        // Open a modal if payment wasn't successful
        if (resultModalOption.type === 'success') {
          clearSensitiveData(true);
          navigateToPaymentConfirmationPage(history);
        } else if (resultModalOption.type === 'warning') {
          clearSensitiveData(true);
        } else {
          clearSensitiveData(false);
          displayModal(resultModalOption);
          setIsPaymentProcess(false);
        }
      })
      .catch((error) => {
        clearSensitiveData(false);
        displayModal({
          type: 'error',
          header: modalHeaders.paymentDeclined,
          text: generalErrorMessages.uhOhMessage,
        });
        setIsPaymentProcess(false);
      });
  };

  const makeEdcPayment = async (
    GCSplitRemaining?: number,
    GCTipRemaining?: number
  ): Promise<ModalOptions> => {
    try {
      const { data: paymentInfo } = await getAppliedPaymentInfo(checkCode);
      if (paymentInfo) {
        // If the check balance is zero and loyalty is not applied, transition back to check page
        if (
          (paymentInfo.Balance === 0 && !loyaltyIsAssigned) ||
          (paymentInfo.Closed && !loyaltyIsAssigned)
        ) {
          setIsPaymentProcess(false);
          errorHandler(
            'message',
            modalHeaders.noPaymentNecessary,
            paymentErrorMessages.noPaymentNecessary,
            PageName.Check
          );
          return {
            type: 'warning',
            header: modalHeaders.noPaymentNecessary,
            text: paymentErrorMessages.noPaymentNecessary,
          };
        }
      }

      // Re-validate credit card input values
      const ccValidationResult = validateCreditCardInfo(
        creditCardNumber,
        creditCardNumberLimit,
        creditCardExp,
        creditCardCvv,
        creditCardCvvLimit,
        creditCardZip
      );
      if (ccValidationResult.text) {
        return ccValidationResult;
      }

      // Validate paymentTotal compared to the site's maximum payment threshold
      var totalValidationResult = validateMaxPaymentThreshold(
        paymentTotal,
        siteConfig?.MaxPaymentThreshold
      );
      if (totalValidationResult.text) {
        totalValidationResult.header = modalHeaders.paymentDeclined;
        return totalValidationResult;
      }
      const tipRemaining =
        GCTipRemaining ?? currency(selectedTip.amount).subtract(tipPaid).value;

      // Attempt to make a payment
      if (appliedPaymentInfo) {
        const paymentRequest: creditCardPaymentRequest = {
          code: checkCode,
          balance: GCSplitRemaining ?? appliedPaymentInfo.Balance,
          tax: appliedPaymentInfo.Tax,
          tip: tipRemaining,
          loyaltySavings: loyaltyDiscountAmountApplied,
          loyaltyApplied: loyaltyIsAssigned,
          gratuity: appliedPaymentInfo.Gratuity,
          cardNumber: creditCardNumber,
          cardExpiration: creditCardExp.replace(/\//g, ''),
          signature: '',
          cvv: creditCardCvv,
          zip: creditCardZip,
          subdomain: getSubdomain(),
        };
        const paymentResult = await newCreditCardPayment(paymentRequest);
        const { data } = await getAppliedPaymentInfo(checkCode);

        if (data === null) {
          // If failed to refresh paymentInfo, send user back to check page with an error modal
          navigateToCheckPage(history);
          return {
            type: 'error',
            text: paymentErrorMessages.refreshPaymentInfoFailure,
          };
        } else {
          if (data.Status === -600 && paymentResult.data.PaymentId <= 0) {
            return {
              type: 'error',
              header: modalHeaders.paymentDeclined,
              text: generalErrorMessages.uhOhMessage,
            };
          }
          dispatch(setPaymentInfo(data));
          dispatch(setPaidAmount(data.AmountPaid));

          if (paymentResult.data === null || paymentResult.data.Status < 0) {
            // If we successfully refreshed payment info and there's no outstanding balance, then always treat payment as successful.
            // This covers a rare case where the initial payment succeeds but appears to fail, due to e.g. HTTP response timeout.
            if (data.Balance <= 0 && data.Status === 0) {
              await dispatch(loadCheckInfo(checkCode));
              return { type: 'success' };
            }

            // When payment got declined, show error modal
            return {
              type: 'error',
              header: modalHeaders.paymentDeclined,
              text: paymentResult.data.Message || '',
            };
          }

          // If balance does still exist, stay in payment page with new info
          if (data.Balance > 0 && !isDemo()) {
            dispatch(loadCheckInfo(checkCode));
            dispatch(loadPaymentInfo(checkCode));
            // TODO: Need a better message for balance remaining after payment
            return {
              type: 'error',
              header: modalHeaders.paymentDeclined,
              text: generalErrorMessages.uhOhMessage,
            };
          }

          // If payment completed, send to confirmation page
          dispatch(setPaymentId(paymentResult.data.PaymentId));
          return { type: 'success' };
        }
      } else {
        return {
          type: 'error',
          header: modalHeaders.paymentDeclined,
          text: generalErrorMessages.uhOhMessage,
        };
      }
    } catch (error) {
      return {
        type: 'error',
        header: modalHeaders.paymentDeclined,
        text: generalErrorMessages.uhOhMessage,
      };
    }
  };
  //#endregion EDC Flow

  return (
    <StyledPayButton
      id={payButtonId}
      disabled={disabled}
      loading={loading}
      onClick={handlePaymentBtn}
    >
      {payButtonMessage}
    </StyledPayButton>
  );
};
