import { ResponseCode } from 'api/';
import { useAppDispatch } from 'app/hooks';
import { CheckCodeHeader } from 'components/CheckCodeHeader';
import { ContentContainer } from 'components/ContentContainer';
import { MpHeader } from 'components/Header';
import { MpButton } from 'components/MpButton';
import currency from 'currency.js';
import { CheckItemView } from 'features/CheckItemView';
import {
  setCheckInfo,
  setRefreshIntervalId,
} from 'features/Common/checkInfoSlice';
import { getAppliedPaymentInfo, getCheckInfo } from 'features/Common/commonApi';
import { useCheckData } from 'features/Common/useCheckData';
import { setCurrentPage } from 'features/Common/userPageSlice';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import { CheckCharges } from 'pages/Check/CheckCharges';
import {
  SubtotalContainer,
  TotalContainer,
} from 'pages/Check/CheckPageComponents';
import { navigateToPaymentPage } from 'pages/navigation';
import { closeDrawer } from 'pages/Payment/Drawer/drawerSlice';
import { resetFPAdditionalInput } from 'pages/Payment/FreedomPay/FPAdditionalInputSlice';
import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import {
  checkDataPollingIntervalMs,
  codeCookie,
  eTagCookie,
  firstLoadCookie,
  PageName,
} from 'types/constants';
import {
  generalErrorMessages,
  modalHeaders,
  paymentErrorMessages,
} from 'types/strings';

export const CheckPage = () => {
  const history = useHistory();

  // Redux Store
  const {
    checkDetails,
    checkDetailsStatus,
    checkDetailsErrorResponse,
    paymentInfoStatus,
    checkDataError,
    balanceIsDue,
    refreshIntervalId,
    appliedPaymentInfo,
  } = useCheckData();
  const dispatch = useAppDispatch();
  const cookies = new Cookies();

  //#region Local States
  const checkInfoLoading = checkDetailsStatus === 'loading';
  const paymentInfoLoading = paymentInfoStatus === 'loading';
  const checkDataLoading = checkInfoLoading || paymentInfoLoading;
  //#endregion Local States

  //#region useEffects
  useEffect(() => {
    dispatch(resetFPAdditionalInput());
    dispatch(closeDrawer());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Polling Check Data on Check Page
  useEffect(
    () => {
      dispatch(setCurrentPage(PageName.Check));

      const interval = setInterval(async () => {
        const checkCode = cookies.get(codeCookie);
        const isFirstLoad = cookies.get(firstLoadCookie) === 'true';
        const { data: checkDetails, headers } = await getCheckInfo(
          checkCode,
          isFirstLoad
        );
        const { data: appliedPaymentInfo } = await getAppliedPaymentInfo(
          checkCode
        );
        const isETagSame = headers.etag === cookies.get(eTagCookie);

        if (!isFirstLoad && !isETagSame) {
          cookies.set(eTagCookie, headers.etag, {
            path: '/',
            maxAge: 3600 * 24,
          });

          dispatch(
            setCheckInfo({
              checkCode: checkCode,
              checkDetails: checkDetails,
              checkDetailsStatus: 'idle',
              checkDetailsErrorResponse: null,
              failedCheckLoadAttempts: 0,
              appliedPaymentInfo: appliedPaymentInfo,
              paymentInfoStatus: 'idle',
              paymentInfoErrorResponse: null,
              failedPaymentInfoLoadAttempts: 0,
              isFirstLoad: isFirstLoad,
              eTag: headers.etag,
              refreshIntervalId: refreshIntervalId,
            })
          );
        }
      }, checkDataPollingIntervalMs);

      dispatch(setRefreshIntervalId(interval));

      return () => {
        clearInterval(interval);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // As long as there is no balance due, displays modal saying no need to pay
  useEffect(() => {
    if (!paymentInfoLoading && !balanceIsDue && appliedPaymentInfo?.Closed) {
      dispatch(
        setModalOptions({
          type: 'success',
          header: modalHeaders.noPaymentNecessary,
          text: paymentErrorMessages.noPaymentNecessary,
        })
      );
      dispatch(openModal());
    }
  }, [balanceIsDue, paymentInfoLoading, appliedPaymentInfo?.Closed, dispatch]);

  // Handling when failing to load check data
  useEffect(() => {
    if (checkDataError) {
      if (
        checkDetailsErrorResponse?.status === ResponseCode.NotFound ||
        checkDetailsErrorResponse?.status === ResponseCode.BadRequest
      ) {
        dispatch(
          setModalOptions({
            type: 'error',
            header: modalHeaders.invalidCheckCode,
            text: generalErrorMessages.invalidCheckCode,
          })
        );
      } else {
        dispatch(
          setModalOptions({
            type: 'error',
            header: 'Error',
            text: generalErrorMessages.checkLoadFailure,
          })
        );
      }
      dispatch(openModal());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, checkDataError, checkDetailsErrorResponse?.status]);
  //#endregion useEffects

  const handleCheckOutBtn = () => {
    dispatch(closeDrawer());
    navigateToPaymentPage(history);
  };

  return (
    <>
      <MpHeader />
      <ContentContainer>
        <CheckCodeHeader />
        <CheckItemView
          id="checkItemsContainer"
          items={checkDetails?.Items}
          loading={checkDataLoading}
        />
        <SubtotalContainer>
          {checkDataLoading ? <Skeleton width={75} /> : <div>Subtotal</div>}
          {checkDataLoading ? (
            <Skeleton width={66} />
          ) : (
            <div id="subtotal">
              {currency(checkDetails?.Totals?.ItemsAndMods || '').format()}
            </div>
          )}
        </SubtotalContainer>
        {!checkDataLoading && <CheckCharges checkDetails={checkDetails} />}
        <TotalContainer>
          {checkDataLoading ? <Skeleton width={75} /> : <div>Total</div>}
          {checkDataLoading ? (
            <Skeleton width={66} />
          ) : (
            <div id="total">
              {currency(checkDetails?.Totals?.Total || '').format()}
            </div>
          )}
        </TotalContainer>
        <MpButton
          id="checkoutBtn"
          onClick={handleCheckOutBtn}
          disabled={checkDataLoading || !balanceIsDue || checkDataError}
          loading={checkDataLoading}
        >
          {balanceIsDue ? 'Checkout Now' : 'Fully Paid'}
        </MpButton>
      </ContentContainer>
    </>
  );
};
