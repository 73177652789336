import { MpButton } from 'components/MpButton';
import { MpInput } from 'components/MpInput';
import styled from 'styled-components';

export const CodeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 32px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-top: 48px;
  }
`;

export const StyledMpButton = styled(MpButton)`
  margin-top: 16px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-top: 24px;
  }
`;

export const StyledMpInput = styled(MpInput)`
  & .MuiInputBase-input {
    text-transform: uppercase;
  }
`;
