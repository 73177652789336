import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './../../app/hooks';
import { selectCheckCode } from './checkInfoSlice';
import { loadSiteConfig, selectSiteConfig } from './siteConfigurationSlice';
import { validateCheckCode } from './validation';

export const useSiteConfig = () => {
  const {
    siteConfig,
    siteConfigStatus,
    failedSiteConfigLoadAttempts,
    isApplePayEnabled,
    isGooglePayEnabled,
  } = useAppSelector(selectSiteConfig);
  const checkCode = useAppSelector(selectCheckCode);
  const dispatch = useAppDispatch();

  const thereIsAnErrorOrNoData = useCallback(
    () =>
      siteConfigStatus === 'failed' ||
      (siteConfigStatus === 'idle' && siteConfig === null),
    [siteConfig, siteConfigStatus]
  );

  //#region useEffect
  // TODO: probably need to enhance this to include payment info failing to load but it'd be weird for check to succeed and not payment
  useEffect(() => {
    if (thereIsAnErrorOrNoData() && validateCheckCode(checkCode)) {
      if (failedSiteConfigLoadAttempts <= 1) {
        dispatch(loadSiteConfig(checkCode));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkCode,
    dispatch,
    failedSiteConfigLoadAttempts,
    thereIsAnErrorOrNoData,
  ]);
  //#endregion useEffect

  const siteConfigError = siteConfigStatus === 'failed';
  const siteConfigLoading = siteConfigStatus === 'loading';

  return {
    siteConfigStatus,
    siteConfig,
    siteConfigLoading,
    siteConfigError,
    isApplePayEnabled,
    isGooglePayEnabled,
  };
};
