import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'app/store';
import currency from 'currency.js';
import { setGiftCardAmountApplied } from 'features/GiftCardSection/GiftCardSlice';
import { Tip } from 'types';
import { PERCENT } from 'types/DTOs/TipBase';

export interface PaymentState {
  selectedTip: Tip;
  cpSessionId?: string;
  cpStatusCode?: string;
  paymentStateId?: string;
  paymentId?: number;
  paymentTotal: number;
  paidAmount: number;
}

const initialState: PaymentState = {
  selectedTip: { id: undefined, percent: 0, type: PERCENT, amount: 0 },
  cpSessionId: undefined,
  cpStatusCode: undefined,
  paymentStateId: undefined,
  paymentId: undefined,
  paymentTotal: 0,
  paidAmount: 0,
};

export const setTipAndUpdatePaymentTotal = createAsyncThunk<
  number,
  Tip,
  { dispatch: AppDispatch; state: RootState }
>('payment/setTipAndUpdatePaymentTotal', (tip: Tip, { dispatch, getState }) => {
  dispatch(setSelectedTip(tip));
  const rootState = getState();
  if (rootState.giftCard.isGiftCardAmountPaid) {
    return currency(rootState.checkInfo.appliedPaymentInfo?.Balance || 0)
      .add(tip.amount)
      .subtract(rootState.giftCard.tipPaid).value;
  } else if (rootState.giftCard.isGiftCardApplied) {
    // This is for after GC is applied
    // Whenever tip is updated, check if available GC balance can cover the balance + tip
    const totalToBePaid = currency(
      rootState.checkInfo.appliedPaymentInfo?.Balance || 0
    ).add(tip.amount).value;
    const giftCardAmountToBeApplied =
      totalToBePaid < rootState.giftCard.totalGiftCardBalance
        ? totalToBePaid
        : rootState.giftCard.totalGiftCardBalance;
    dispatch(setGiftCardAmountApplied(giftCardAmountToBeApplied));
    return currency(totalToBePaid).subtract(giftCardAmountToBeApplied).value;
  }
  return currency(rootState.checkInfo.appliedPaymentInfo?.Balance || 0).add(
    tip.amount
  ).value;
});

// TODO (L): Check dispatch type any vs AppDispatch https://github.com/rematch/rematch/issues/568
export const updatePaymentTotal = createAsyncThunk<
  number,
  void,
  { dispatch: any; state: RootState }
>('payment/updatePaymentTotal', (_: any, { getState }) => {
  const rootState = getState();
  if (rootState.giftCard.giftCardAmountApplied) {
    /*
    need to use subtotal and tax not balance. it is because balance includes gift card as well. 
    When gift card is added, balance doesn't include gift card balance. Balance includes it after click Pay button.
    but we need to calculate gift card right after add a gift card.
    */
    return currency(rootState.checkInfo.appliedPaymentInfo?.Subtotal || 0)
      .add(rootState.checkInfo.appliedPaymentInfo?.Tax || 0)
      .add(rootState.payment.selectedTip.amount)
      .subtract(rootState.giftCard.giftCardAmountApplied).value;
  }
  return currency(rootState.checkInfo.appliedPaymentInfo?.Balance || 0).add(
    rootState.payment.selectedTip.amount
  ).value;
});

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetPaymentSlice: () => {
      return { ...initialState };
    },
    setSelectedTip: (state, { payload }: PayloadAction<Tip>) => {
      state.selectedTip = payload;
    },
    setCpSessionResult: (
      state,
      {
        payload,
      }: PayloadAction<{ sessionId: string | undefined; statusCode: string }>
    ) => {
      state.cpSessionId = payload.sessionId;
      state.cpStatusCode = payload.statusCode;
    },
    setPaymentStateId: (state, { payload }: PayloadAction<string>) => {
      state.paymentStateId = payload;
    },
    setPaymentId: (state, { payload }: PayloadAction<number | undefined>) => {
      state.paymentId = payload;
    },
    setPaymentTotal: (state, { payload }: PayloadAction<number>) => {
      state.paymentTotal = payload;
    },
    setPaidAmount: (state, { payload }: PayloadAction<number>) => {
      state.paidAmount = payload + state.selectedTip.amount;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        setTipAndUpdatePaymentTotal.fulfilled,
        (state, { payload }: PayloadAction<number>) => {
          state.paymentTotal = payload;
        }
      )
      .addCase(
        updatePaymentTotal.fulfilled,
        (state, { payload }: PayloadAction<number>) => {
          state.paymentTotal = payload;
        }
      );
  },
});

export const {
  resetPaymentSlice,
  setSelectedTip,
  setPaymentTotal,
  setCpSessionResult,
  setPaymentStateId,
  setPaymentId,
  setPaidAmount,
} = paymentSlice.actions;

export const selectPaymentState = (state: RootState) => state.payment;
export const selectSelectedTip = (state: RootState) =>
  state.payment.selectedTip;
export const selectPaymentTotal = (state: RootState) =>
  state.payment.paymentTotal;

export default paymentSlice.reducer;
