import { useAppSelector } from 'app/hooks';
import { isDev } from 'app/utils';
import { selectThemeConfig } from 'features/Common/themeConfigSlice';
import { getThemeImages } from 'images/imageUtils';
import defaultDoneImg from 'images/ncr_done.png';
import { slideInUp } from 'react-animations';
import styled, { css, keyframes } from 'styled-components';

const ThankyouImg = styled.img`
  margin-top: 24px;
  height: auto;
  max-width: 100%;
`;

const ThankyouTextBold = styled.div.attrs({ children: 'Thank you' })`
  font-weight: 1000;
  font-size: 24px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 36px;
    font-weight: 1100;
  }
`;

const ThankyouTextContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.welcome.name}, sans-serif;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }
`;

const defaultThankyouText = (
  <>
    <ThankyouTextBold />
    <>For your business</>
  </>
);

interface ThankyouProps {
  slideUp?: boolean;
}

const slideUpAnimation = keyframes`${slideInUp} from { 30% } to { 70% }`;

const SlidingContainer = styled.div`
  animation: ${({ slideUp }: ThankyouProps) =>
    slideUp && css`0.2s ${slideUpAnimation}`};
`;

export const Thankyou = ({ slideUp }: ThankyouProps) => {
  const themeConfig = useAppSelector(selectThemeConfig);
  return (
    <SlidingContainer slideUp={slideUp}>
      <ThankyouImg
        src={isDev() ? defaultDoneImg : getThemeImages().doneImgSrc ?? ''}
      />
      <ThankyouTextContainer>
        {themeConfig?.thankYouText ?? defaultThankyouText}
      </ThankyouTextContainer>
    </SlidingContainer>
  );
};
