import { CustomTips } from 'components/CustomTips';
import { PaymentSectionDivider } from 'components/Divider/PaymentSectionDivider';
import { PaymentSectionHeader } from 'components/PaymentSectionHeader';
import { TipSuggestions } from 'components/TipSuggestions';
import styled from 'styled-components/macro';

const TipsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTipSuggestions = styled(TipSuggestions)`
  margin-bottom: 8px;
`;

interface TipSectionProps {
  loading: boolean;
}

export const TipSection = ({ loading }: TipSectionProps) => {
  return (
    <>
      <PaymentSectionDivider />
      <PaymentSectionHeader>Suggested Tip</PaymentSectionHeader>
      <TipsContainer>
        <StyledTipSuggestions loading={loading} />
        <CustomTips loading={loading} />
      </TipsContainer>
    </>
  );
};
