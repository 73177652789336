import { getFromLS } from 'app/browserStorage';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getSubdomain } from 'app/utils';
import {
  loadThemeConfig,
  selectThemeConfigState,
} from 'features/Common/themeConfigSlice';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Font } from 'themes/theme';
import { getThemeNameForSubdomain } from 'themes/utils';

export const useTheme = () => {
  const defaultTheme = useMemo(() => getFromLS('mp-ncr-theme'), []);
  const [theme, setTheme] = useState(defaultTheme);
  const [themeLoaded, setThemeLoaded] = useState(false);
  const subdomain = getSubdomain();
  const dispatch = useAppDispatch();
  const themeConfig = useAppSelector(selectThemeConfigState);

  const getFonts = () => {
    const allFonts = _.values(theme.fonts).map(
      (font: Font) => `${font.name}:${font.weights}`
    );
    return allFonts;
  };

  useEffect(() => {
    dispatch(loadThemeConfig(subdomain));
  }, [dispatch, subdomain]);

  useEffect(() => {
    const localTheme = getFromLS(getThemeNameForSubdomain(subdomain));
    localTheme ? setTheme(localTheme) : setTheme(defaultTheme);
    setThemeLoaded(themeConfig.status !== 'loading');
  }, [defaultTheme, themeConfig.status, themeConfig.themeConfig, subdomain]);

  return { theme, themeLoaded, getFonts };
};
