import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body.gradient};
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: ${({ theme }) => theme.colors.text.primary};
    font-family: ${({ theme }) => theme.fonts.default.name}, 'sans-serif';
    text-align: center;
    text-shadow: none;
    box-sizing: border-box;
    margin: 0;
  }

  a, a:visited {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  input::placeholder{
    text-transform: none;
    color: ${({ theme }) => theme.colors.input.text};
  }
`;
