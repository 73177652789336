import {
  StyledFPPayButton,
  StyledFPPayButtonDiv,
} from 'pages/Payment/FreedomPay/FreedomPayIframeComponents';

interface FpDrawerButtonProps {
  id: string;
  onClick: () => void;
  buttonMsg: string;
  disabled: boolean;
}

export const FpDrawerButton = ({
  id,
  onClick,
  buttonMsg,
  disabled,
}: FpDrawerButtonProps) => {
  return (
    <StyledFPPayButtonDiv disabled={disabled}>
      <StyledFPPayButton id={id} onClick={onClick} type="submit">
        {buttonMsg}
      </StyledFPPayButton>
    </StyledFPPayButtonDiv>
  );
};
