import styled from 'styled-components';

const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
  color: black;
  padding: 0 15px 0 15px;
`;

const DividerLeftLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.welcome};
  border-color: #6c757d;
  width: 100%;
  margin: 0 5px 7px 0;
`;

const DividerRightLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.welcome};
  border-color: #6c757d;
  width: 100%;
  margin: 0 0 7px 5px;
`;

interface FpIframeDividerProps {
  children: React.ReactNode;
}

export const FpIframeDivider = ({ children }: FpIframeDividerProps) => {
  return (
    <DividerContainer>
      <DividerLeftLine />
      {children}
      <DividerRightLine />
    </DividerContainer>
  );
};
