import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseCode } from 'api';
import { createAxiosResponse } from 'api/utils';
import { RootState } from 'app/store';
import { AxiosResponse } from 'axios';
import { CustomWindow } from 'CustomWindow';
import { createBrowserHistory } from 'history';
import { Cookies } from 'react-cookie';
import { SiteConfiguration } from 'types/DTOs';
import { PaymentMethod } from 'types/DTOs/SiteConfiguration';
import { getSiteConfig } from './commonApi';
import { validateCheckCode } from './validation';

export interface SiteConfigState {
  siteConfig: SiteConfiguration | null;
  siteConfigStatus: 'idle' | 'loading' | 'failed';
  failedSiteConfigLoadAttempts: number;
  errorResponse: AxiosResponse<string> | null;
  isGooglePayEnabled: boolean;
  isApplePayEnabled: boolean;
}

const initialState: SiteConfigState = {
  siteConfig: null,
  siteConfigStatus: 'idle',
  failedSiteConfigLoadAttempts: 0,
  errorResponse: null,
  isGooglePayEnabled: false,
  isApplePayEnabled: false,
};

declare let window: CustomWindow;

export const loadSiteConfig = createAsyncThunk<
  SiteConfiguration,
  string,
  { rejectValue: { data: string; status: ResponseCode; headers: any } } //TODO: Make this a type
>(
  'siteConfig/loadSiteConfig',
  async (checkCode: string, { rejectWithValue }) => {
    try {
      if (validateCheckCode(checkCode)) {
        const { data } = await getSiteConfig(checkCode);

        //initialize appInsights with instrumentation value
        if (data.AppInsightsInstrumentation) {
          const browserHistory = createBrowserHistory({ basename: '' });
          const reactPlugin = new ReactPlugin();
          const appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: data.AppInsightsInstrumentation ?? '',
              extensions: [reactPlugin],
              extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
              },
            },
          });
          appInsights.loadAppInsights();
          appInsights.addTelemetryInitializer((envelope) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({ 'ai.cloud.role': data.AppInsightsRoleName });
            envelope.tags.push({
              'ai.cloud.roleInstance': data.AppInsightsRoleInstance,
            });
          });
          window.appInsights = appInsights;
        } else {
          window.appInsights = undefined;
        }

        return data;
      }
      return rejectWithValue(
        createAxiosResponse(
          `${checkCode} is not a valid check code`,
          ResponseCode.BadRequest
        )
      );
    } catch (error: any) {
      // TODO could be pulled out to a helper. all this logic is the same as other slices
      if (!error.data || !error.status) {
        throw error;
      }
      return rejectWithValue({
        data: error.data,
        status: error.status,
        headers: error.headers,
      });
    }
  }
);

export const siteConfigSlice = createSlice({
  name: 'siteConfig',
  initialState,
  reducers: {
    resetSiteConfig: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadSiteConfig.pending, (state) => {
        state.siteConfigStatus = 'loading';
      })
      .addCase(loadSiteConfig.fulfilled, (state, action) => {
        const siteConfigResp = action.payload;

        state.siteConfigStatus = 'idle';
        state.siteConfig = siteConfigResp;
        state.failedSiteConfigLoadAttempts = 0;
        state.isGooglePayEnabled =
          siteConfigResp.PaymentMethod === PaymentMethod.FP &&
          siteConfigResp.FPEnableGooglePay;
        state.isApplePayEnabled =
          siteConfigResp.PaymentMethod === PaymentMethod.FP &&
          siteConfigResp.FPEnableApplePay;

        const cookies = new Cookies();
        cookies.set('CompanyId', action.payload.CompanyId, {
          path: '/',
          maxAge: 3600 * 24,
        });
      })
      .addCase(loadSiteConfig.rejected, (state, { payload }) => {
        state.siteConfigStatus = 'failed';
        state.failedSiteConfigLoadAttempts += 1;
        state.errorResponse = payload
          ? createAxiosResponse(payload?.data, payload?.status, payload.headers)
          : createAxiosResponse('Unknown error', ResponseCode.Error);
      });
  },
});

export const { resetSiteConfig } = siteConfigSlice.actions;
export const selectSiteConfig = (state: RootState) => state.siteConfig;

export default siteConfigSlice.reducer;
