import { MpInput } from 'components/MpInput';
import { useEffect, useState } from 'react';
import { TipBase } from 'types/DTOs';

interface CustomTipInputProps {
  id?: string;
  className?: string;
  max?: number;
  onValueChange: (value: number) => void;
  value: number;
  tipBase: TipBase;
}

const VALID_FIRST = /^[1-9]{1}$/;

export const CustomTipInput = ({
  id,
  className = '',
  max = Number.MAX_SAFE_INTEGER,
  onValueChange,
  value,
  tipBase,
}: CustomTipInputProps) => {
  //#region utils
  const convertInputToNum = (value: string): number => {
    if (tipBase === 'dollars') {
      return Number(value.replace(new RegExp(/\D/g), ''));
    } else {
      if (value.indexOf('%') < 0) {
        return Number(value.slice(0, value.length - 1));
      } else {
        return Number(value.replace(new RegExp(/\D/g), ''));
      }
    }
  };

  const formatValueToDisplay = (value: number) => {
    if (value === 0) return '';
    else {
      return tipBase === 'dollars'
        ? (value / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        : `${value}%`;
    }
  };
  //#endregion utils

  //#region Local States
  const [valueToDisplay, setValueToDisplay] = useState<string>(
    formatValueToDisplay(value)
  );
  const valueAbsTrunc = Math.trunc(Math.abs(value));
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    throw new Error(`invalid value property`);
  }
  //#endregion Local States

  // Reformat the display value when tip base is switched
  useEffect(() => {
    setValueToDisplay(formatValueToDisplay(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipBase]);

  const customInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentVal = event.target.value;

    // If it's the first key entered
    if (value === 0) {
      if (VALID_FIRST.test(currentVal)) {
        const firstValue = Number(currentVal);
        onValueChange(firstValue);
        setValueToDisplay(formatValueToDisplay(firstValue));
        return;
      } else {
        // Do nothing when something other than digits was entered for the first value
        return;
      }
    }

    // If it's not the first key
    if (value !== 0) {
      const tempVal = convertInputToNum(currentVal);
      onValueChange(tempVal);
      setValueToDisplay(formatValueToDisplay(tempVal));
      return;
    }
  };

  return (
    <MpInput
      id={id}
      className={className}
      inputMode="numeric"
      onChange={customInputHandler}
      placeholder="Tip Amount"
      value={valueToDisplay}
      autoFocus
    />
  );
};

export default CustomTipInput;
