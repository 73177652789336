import { CustomWindow } from './CustomWindow';
declare let window: CustomWindow;

export const traceAIEvent = (message: any, properties: any) => {
  const appInsights = window.appInsights;

  if (appInsights) {
    appInsights.trackTrace(message, properties);
  }
};
