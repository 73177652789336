import { CircleLoader } from 'components/MpLoaders';
import {
  Column,
  PageContainer,
  StyledText,
} from 'features/SecurePaymentProcessing/SecurePaymentProcessingComponents';
import { useEffect, useState } from 'react';

export interface SecurePaymentProcessingProps {
  message?: string;
  messageDelayMs?: number;
}

export const SecurePaymentProcessing = ({
  message,
  messageDelayMs,
}: SecurePaymentProcessingProps) => {
  const [showMessage, setShowMessage] = useState<boolean>(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (messageDelayMs) {
      setShowMessage(false);
      timeout = setTimeout(() => {
        setShowMessage(true);
      }, messageDelayMs);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [messageDelayMs]);

  return (
    <PageContainer>
      <Column>
        <CircleLoader size={100} />
        {message && showMessage && <StyledText>{message}</StyledText>}
      </Column>
    </PageContainer>
  );
};
