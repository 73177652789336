import { useAppSelector } from 'app/hooks';
import { MessageModal } from 'features/Modal/MessageModal';
import { CustomTipModal } from 'features/TipSection/CustomTipModal';
import { isMessageModalType } from 'types/ModalType';

export const MpModals = () => {
  const {
    open,
    options: { text, header, type },
  } = useAppSelector((state) => state.modal);
  return (
    <>
      <MessageModal
        open={open && isMessageModalType(type)}
        text={text || ''}
        header={header || ''}
        type={type}
      />
      <CustomTipModal open={open && type === 'tip'} />
    </>
  );
};
