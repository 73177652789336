import { Divider } from '@mui/material';
import { MpButton } from 'components/MpButton';
import styled from 'styled-components';

export const EmailReceiptInstruction = styled.div`
  font-family: ${({ theme }) => theme.fonts.default.name}, sans-serif;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  text-align: center;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 18px;
  }
`;

export const StyledDivider = styled(Divider)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.welcome};
  margin-top: 24px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    width: 288px;
  }
`;

export const EmailInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SendReceiptLabel = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.divider.welcome};
  font-size: 14px;
  line-height: 16px;
  margin: 17px 0px 16px 0px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
    margin: 24px 0px 23px 0px;
  }
`;

export const StyledMpButton = styled(MpButton)`
  margin-top: 16px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-top: 24px;
  }
`;
