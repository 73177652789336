import { CheckRow } from 'components/CheckRow';
import { CheckDivider } from 'components/Divider/CheckDivider';

export const CheckItemsLoading = () => (
  <>
    <CheckRow loading loadingLength={170} />
    <CheckDivider />
    <CheckRow loading loadingLength={115} />
    <CheckDivider />
    <CheckRow loading loadingLength={140} />
  </>
);
