import { PaymentSectionDivider } from 'components/Divider/PaymentSectionDivider';
import warningIcon from 'images/warning.svg';
import styled from 'styled-components';

const PartiallyPaidContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
`;

const PartialPaymentMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.5rem;
`;

const WarningImg = styled.img`
  width: 3rem;
  height: 3rem;
  padding-right: 0.5rem;
`;

const PartiallyPaidLabel = styled.label`
  text-align: center;
  padding-right: 0.3rem;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  }
`;

export const PartiallyPaid = () => {
  return (
    <>
      <PartiallyPaidContainer>
        <PartialPaymentMessage>
          <WarningImg src={warningIcon} />
          <PartiallyPaidLabel>Partially Paid</PartiallyPaidLabel>
        </PartialPaymentMessage>
        <span>Please enter new payment info to finish paying</span>
      </PartiallyPaidContainer>
      <PaymentSectionDivider />
    </>
  );
};
