import { useAppDispatch } from 'app/hooks';
import { validateCreditCardZip } from 'features/Common/validation';
import { StyledCreditCardZipInput } from 'features/CreditCardInputSection/CreditCardInputComponents';
import {
  setCreditCardZip,
  setIsCCZipValid,
} from 'features/CreditCardInputSection/CreditCardInputSlice';
import { useCreditCardInput } from 'features/CreditCardInputSection/useCreditCardInput';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import { useCallback } from 'react';

interface CreditCardZipInputProps {
  loading: boolean;
  disabled?: boolean;
}

export const CreditCardZipInput = ({
  loading,
  disabled,
}: CreditCardZipInputProps) => {
  const dispatch = useAppDispatch();
  const { creditCardZip, creditCardZipLimit } = useCreditCardInput();

  const handleKeyUp = useCallback(() => {
    dispatch(setIsCCZipValid(false));

    if (creditCardZip.length === creditCardZipLimit) {
      // Validation for the credit card's zip code
      const errorMessage = validateCreditCardZip(creditCardZip);

      if (errorMessage) {
        dispatch(
          setModalOptions({
            type: 'error',
            header: 'Invalid Credit Card',
            text: errorMessage,
          })
        );
        dispatch(openModal());
      } else {
        dispatch(setIsCCZipValid(true));
      }
    }
  }, [creditCardZip, creditCardZipLimit, dispatch]);

  return (
    <StyledCreditCardZipInput
      id="ccInfo_zipcode"
      onChange={(event) => dispatch(setCreditCardZip(event.target.value))}
      onKeyUp={handleKeyUp}
      placeholder="Zip Code"
      maxLength={creditCardZipLimit}
      type="tel"
      value={creditCardZip}
      disabled={disabled || loading}
    />
  );
};
