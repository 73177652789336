import { ModalOptions } from 'features/Modal/modalSlice';
import {
  checkCodeRegex,
  emailRegex,
  numberRegex,
  zipRegex,
} from 'types/constants';
import { minimumCreditCardNumberLimit } from 'types/DTOs/CreditCardLimit';
import {
  creditCardInputErrorMessages,
  paymentErrorMessages,
} from 'types/strings';

// Luhn Check for verifying a credit card number (http://jsperf.com/jquery-validate-js/3)
const luhnCheck = (creditCardNumber: string) => {
  var nCheck = 0;
  var nDigit = 0;
  var bEven = false;

  creditCardNumber = creditCardNumber.replace(/\D/g, '');
  for (var n = creditCardNumber.length - 1; n >= 0; n--) {
    var cDigit = creditCardNumber.charAt(n);
    nDigit = parseInt(cDigit, 10);
    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }
    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};

// Validation for check code
export const validateCheckCode = (code: string | undefined) => {
  if (code === undefined) {
    return false;
  }
  const validRegex = checkCodeRegex.test(code);
  const validLength = code.length === 6;
  return validRegex && validLength;
};

// Validation for gift card number
export const validateGiftCardNumber = (giftCardNumber: string | undefined) => {
  if (giftCardNumber === undefined) {
    return false;
  }
  const validRegex = numberRegex.test(giftCardNumber);
  const validLength = giftCardNumber.length >= 8;
  return validLength && validRegex;
};

// Validation for gift card pin
export const validateGiftCardPin = (giftCardPin: string | undefined) => {
  if (giftCardPin === undefined) {
    return false;
  }
  const validRegex = numberRegex.test(giftCardPin);
  const validLength = giftCardPin.length === 6;
  return validLength && validRegex;
};

// Validation for gift card number and pin
export const validateGiftCardInputs = (
  giftCardNumber: string | undefined,
  giftCardPin: string | undefined,
  isEpinRequired: boolean
) => {
  const validGiftCard = validateGiftCardNumber(giftCardNumber);
  const validPin = validateGiftCardPin(giftCardPin);
  return validGiftCard && (isEpinRequired ? validPin : true);
};

// Validation for credit card number input
export const validateCreditCardNumber = (
  creditCardNumber: string,
  maxLength: number
) => {
  var errorMessage = '';

  if (creditCardNumber === '') {
    errorMessage = creditCardInputErrorMessages.noCardNumber;
  } else if (
    !creditCardNumber.match(numberRegex) ||
    creditCardNumber.length > maxLength ||
    creditCardNumber.length < minimumCreditCardNumberLimit
  ) {
    errorMessage = creditCardInputErrorMessages.invalidCardNumber;
  } else if (!luhnCheck(creditCardNumber)) {
    errorMessage = creditCardInputErrorMessages.invalidCardNumber;
  }

  return errorMessage;
};

// Validation for credit card expiration date input
export const validateCreditCardExp = (exp: string) => {
  var errorMessage = '';
  exp = exp.replace(/\//g, '');

  if (exp !== null) {
    var time = new Date();
    var currentYear = time.getFullYear() % 100;
    var currentMonth = time.getMonth() + 1;
    var month = parseInt(exp.substring(0, 2));
    var year = parseInt(exp.substring(2));

    if (!exp.match(numberRegex) || !year) {
      errorMessage = creditCardInputErrorMessages.invalidCardExpFormat;
    } else if (month > 12 || month < 1) {
      errorMessage = creditCardInputErrorMessages.invalidCardExpMonth;
    } else if (year < currentYear) {
      errorMessage = creditCardInputErrorMessages.expiredCardExp;
    } else if (year > currentYear + 10) {
      errorMessage = creditCardInputErrorMessages.expiringTooFarCardExp;
    } else if (year === currentYear && month < currentMonth) {
      errorMessage = creditCardInputErrorMessages.expiredCardExp;
    }
  }

  return errorMessage;
};

// Validation for credit card CVV input
export const validateCreditCardCvv = (cvv: string, maxLength: number) => {
  var errorMessage = '';

  if (cvv !== null) {
    if (cvv.length <= 0) {
      errorMessage = creditCardInputErrorMessages.noCardCvv;
    } else if (cvv.length < maxLength) {
      errorMessage = creditCardInputErrorMessages.invalidCardCvv;
    } else if (!cvv.match(numberRegex)) {
      errorMessage = creditCardInputErrorMessages.invalidCardCvv;
    }
  }

  return errorMessage;
};

// Validation for credit card Zip code input
export const validateCreditCardZip = (zip: string) => {
  var errorMessage = '';

  if (zip !== null) {
    if (zip.length <= 0) {
      errorMessage = creditCardInputErrorMessages.noCardZip;
    } else if (!zipRegex.test(zip)) {
      errorMessage = creditCardInputErrorMessages.invalidCardZip;
    }
  }

  return errorMessage;
};

// Validation for all credit card inputs for EDC payment flow
export const validateCreditCardInfo = (
  ccNumber: string,
  ccNumberLimit: number,
  ccExp: string,
  ccCvv: string,
  ccCvvLimit: number,
  ccZip: string
): ModalOptions => {
  var returnOption: ModalOptions = {
    type: 'warning',
    header: 'Invalid Credit Card',
    text: '',
  };

  const errorMessageForCCNumber = validateCreditCardNumber(
    ccNumber,
    ccNumberLimit
  );
  if (errorMessageForCCNumber) {
    returnOption.text = errorMessageForCCNumber;
    return returnOption;
  }

  const errorMessageForCCExp = validateCreditCardExp(ccExp);
  if (errorMessageForCCExp) {
    returnOption.text = errorMessageForCCNumber;
    return returnOption;
  }

  const errorMessageForCCCvv = validateCreditCardCvv(ccCvv, ccCvvLimit);
  if (errorMessageForCCCvv) {
    returnOption.text = errorMessageForCCCvv;
    return returnOption;
  }

  const errorMessageForCCZip = validateCreditCardZip(ccZip);
  if (errorMessageForCCZip) {
    returnOption.text = errorMessageForCCZip;
    return returnOption;
  }

  return returnOption;
};

// Validation for maximum payment threshold
export const validateMaxPaymentThreshold = (
  total: number,
  maxPaymentThreshold: number | undefined
): ModalOptions => {
  var returnOption: ModalOptions = {
    type: 'error',
    header: 'ERROR',
    text: '',
  };

  if (maxPaymentThreshold) {
    if (maxPaymentThreshold <= 0) {
      returnOption.text = paymentErrorMessages.zeroMaxPaymentThreshold;
      return returnOption;
    } else if (total > maxPaymentThreshold) {
      returnOption.text = paymentErrorMessages.totalExceedsMaxPaymentThreshold;
      return returnOption;
    }
  }

  return returnOption;
};

// Validation for email address
export const validateEmail = (email: string) => {
  const validRegex = emailRegex.test(email);
  return validRegex;
};

export const formatPhoneNumber = (loyaltyPhoneNumber: string) => {
  var formattedPhoneNumber = '';
  var val = loyaltyPhoneNumber.replace(/\D/g, '');
  const phoneNumberLength = loyaltyPhoneNumber.length;
  formattedPhoneNumber = val.substring(0, 3);
  if (phoneNumberLength >= 3 && val.substring(3, 6).length >= 1) {
    formattedPhoneNumber += '-' + val.substring(3, 6);
  }
  if (phoneNumberLength > 6 && val.substring(6).length >= 1) {
    formattedPhoneNumber += '-' + val.substring(6);
  }

  return formattedPhoneNumber;
};

export const removeHyphenFromPhoneNumber = (loyaltyPhoneNumber: string) => {
  var formattedPhoneNumber = '';
  if (loyaltyPhoneNumber.length === 4 || loyaltyPhoneNumber.length === 8) {
    formattedPhoneNumber = loyaltyPhoneNumber.substring(
      0,
      loyaltyPhoneNumber.length - 1
    );
  } else {
    formattedPhoneNumber = loyaltyPhoneNumber;
  }
  return formattedPhoneNumber;
};
