import { ResponseCode } from 'api/';
import { getApi } from 'api/utils';
import { GiftCardPayRequest } from 'types/DTOs/GiftCard/GiftCardPayRequest';

const api = getApi();

export const getPrefixList = async (companyCode: string) =>
  api.getEpinPrefixList(companyCode);

export const getBalance = async (
  companyId: number,
  siteId: number,
  checkCode: string,
  cardNumber: string,
  pin: string
) => {
  try {
    var data = await api.getGiftCardBalance(
      companyId,
      siteId,
      checkCode,
      cardNumber,
      pin
    );

    return {
      balance: data.data.Balance,
      responseCode: ResponseCode.OK,
    };
  } catch (error: any) {
    var status = ResponseCode.Error;

    if (error.status === ResponseCode.Unauthorized) {
      status = ResponseCode.Unauthorized;
    }
    return {
      responseCode: status,
    };
  }
};

export const giftCardPay = async (request: GiftCardPayRequest) =>
  api.giftCardPay(request);
