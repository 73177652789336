import { ContentContainer } from 'components/ContentContainer';
import { MpHeader } from 'components/Header';
import { Hero } from 'components/Hero';
import { Welcome } from 'components/Hero/Welcome';
import { CodeInput } from 'features/CodeInput';
import { useLocation } from 'react-router-dom';


export const CodePage = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const queryParamCheckCode = queryParams.get('q');
  const queryParamRefCode = queryParams.get('ref');

  return (
    <>
      <MpHeader />
      <Hero />
      <ContentContainer>
        <Welcome />
        <CodeInput
          queryParamCheckCode={queryParamCheckCode}
          queryParamRefCode={queryParamRefCode}
        />
      </ContentContainer>
    </>
  );
};
