import { Check } from 'types/DTOs';

export const mockCheck: Check = {
  EmployeeId: 8091,
  Items: [
    {
      Id: 1049852,
      ItemId: 6301,
      Level: 0,
      Name: 'Coca-Cola',
      Price: 2.39,
      Quantity: 1,
      Type: 1,
    },
    {
      Id: 1049859,
      ItemId: 501,
      Level: 0,
      Name: 'Chips & Salsa',
      Price: 2.99,
      Quantity: 1,
      Type: 1,
    },
    {
      Id: 1049864,
      ItemId: 4515,
      Level: 0,
      Name: 'Original Burger Grill',
      Price: 7.99,
      Quantity: 1,
      Type: 1,
    },
    {
      Id: 1049869,
      ItemId: 6519,
      Level: 0,
      Name: 'Monster Cheese Cake',
      Price: 5.99,
      Quantity: 1,
      Type: 1,
    },
  ],
  Messages: [],
  Payments: [],
  Server: 'John Doe',
  StoreName: 'NCR Grill',
  LoyaltyName: '',
  LoyaltyNumber: '',
  ServiceCharges: [],
  Totals: {
    Gratuity: '0.00',
    GratuityPercentage: '0.00',
    PaidGratuity: '0.00',
    PaidTax: '0.00',
    Subtotal: '19.36',
    Tax: '1.30',
    Tip: '0.00',
    Total: '20.66',
    LoyaltySavings: '0.00',
    ServiceCharge: 0.0,
    ItemsAndMods: 19.36,
    Discount: 0,
  },
  Id: 0,
  Subtotal: 0,
  Tax: 0,
  Tip: 0,
  LoyaltySavings: 0,
  LoyaltyApplied: false,
  Gratuity: 0,
  Total: 0,
  Code: 'NCR135',
  Closed: false,
  Created: new Date('11/2/2021 15:00:00'),
  SiteId: 111111,
  Table: 1,
  TableName: 'Table 1',
  TableId: 123456,
  CheckId: 87585746,
  Tenders: [
    {
      Id: 35,
      Type: 'GiftCard',
    },
    {
      Id: 70,
      Type: 'CardPayment',
    },
  ],
};
