import { useAppSelector } from 'app/hooks';
import currency from 'currency.js';
import { selectLoyaltyState } from 'features/AddLoyaltySection/LoyaltySlice';
import { selectCheckDetails } from 'features/Common/checkInfoSlice';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import { useCallback, useMemo } from 'react';
import { Tip } from 'types';
import { DOLLARS, PERCENT } from 'types/DTOs/TipBase';

export const useTipCalculator = () => {
  // Redux Store
  const { siteConfig } = useSiteConfig();
  const checkDetails = useAppSelector(selectCheckDetails);
  const loyaltyState = useAppSelector(selectLoyaltyState);

  // Local States
  const tipBase = useMemo(() => siteConfig?.TipBase, [siteConfig]);
  const tipIsPercentBased = useMemo(() => tipBase === PERCENT, [tipBase]);
  const tipCalculationOnTotal = useMemo(
    () => siteConfig?.TipCalculationBase === 'total',
    [siteConfig]
  );
  const loyaltyIsPresentOnCheck = useMemo(
    () => checkDetails && checkDetails?.LoyaltyNumber,
    [checkDetails]
  );

  const getAmountToTipOn = () => {
    if (loyaltyState.loyaltyIsAssigned || loyaltyIsPresentOnCheck) {
      return tipCalculationOnTotal
        ? checkDetails?.PreDiscountTotal
        : checkDetails?.PreDiscountItemsTotal;
    }

    return tipCalculationOnTotal
      ? checkDetails?.Total
      : checkDetails?.Totals.ItemsAndMods;
  };

  const amountToTipOn = useMemo(
    () => getAmountToTipOn(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkDetails, siteConfig]
  );

  const getDollarValueFromPercent = useCallback(
    (percent: number) => {
      const tipPercentage = percent / 100;
      return amountToTipOn
        ? currency(amountToTipOn).multiply(tipPercentage).value
        : 0;
    },
    [amountToTipOn]
  );

  const getPercentFromDollarValue = useCallback(
    (tipAmount: number) => {
      return amountToTipOn
        ? parseFloat(((tipAmount / (amountToTipOn || 1)) * 100).toFixed(2))
        : 0;
    },
    [amountToTipOn]
  );

  const tips: Tip[] = useMemo(() => {
    const tipAmounts = [
      siteConfig?.TipOne && siteConfig?.TipOne > 0 ? siteConfig?.TipOne : 18,
      siteConfig?.TipTwo && siteConfig?.TipTwo > 0 ? siteConfig?.TipTwo : 20,
      siteConfig?.TipThree && siteConfig?.TipThree > 0
        ? siteConfig?.TipThree
        : 22,
    ];

    return tipAmounts.map((tipAmount, index) => ({
      id: index,
      type: tipIsPercentBased ? PERCENT : DOLLARS,
      percent: tipIsPercentBased
        ? tipAmount
        : getPercentFromDollarValue(tipAmount),
      amount: tipIsPercentBased
        ? getDollarValueFromPercent(tipAmount)
        : tipAmount,
    }));
  }, [
    getDollarValueFromPercent,
    getPercentFromDollarValue,
    siteConfig?.TipOne,
    siteConfig?.TipThree,
    siteConfig?.TipTwo,
    tipIsPercentBased,
  ]);

  return {
    tips,
    tipBase,
    getPercentFromDollarValue,
    getDollarValueFromPercent,
  };
};
