import styled from 'styled-components/macro';

export const InputContainer = styled.div`
  line-height: 14px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    line-height: 18px;
  }
`;
