import { useAppSelector } from 'app/hooks';
import { CheckRow } from 'components/CheckRow';
import { CheckContainer, CheckRowContainer } from 'components/CheckView';
import { CheckDivider } from 'components/Divider/CheckDivider';
import { selectLoyaltyState } from 'features/AddLoyaltySection/LoyaltySlice';
import {
  SubtotalRow,
  TotalRow,
} from 'features/CheckSummary/CheckSummaryComponents';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import { priceIsValidForDisplay } from 'features/Common/utils';
import { selectGiftCardState } from 'features/GiftCardSection/GiftCardSlice';
import { selectPaymentState } from 'pages/Payment/paymentSlice';
import { getPaymentTypeName } from 'pages/utils';
import { Payment, ServiceCharge, Totals } from 'types/DTOs';
import { Tender } from 'types/DTOs/Tender';
import { PERCENT } from 'types/DTOs/TipBase';

interface CheckSummaryProps {
  totals: Totals | undefined;
  payments: Payment[] | undefined;
  tenders: Tender[] | undefined;
  preLoyaltyItemsTotal: number | 0;
  serviceCharges: ServiceCharge[] | undefined;
  loading: boolean;
  isGiftCardAmountPaid: boolean;
}

export const CheckSummary = ({
  totals,
  payments,
  tenders,
  preLoyaltyItemsTotal,
  serviceCharges,
  loading,
  isGiftCardAmountPaid,
}: CheckSummaryProps) => {
  const { selectedTip, paymentTotal } = useAppSelector(selectPaymentState);
  const { loyaltyDiscountAmountApplied, loyaltyIsAssigned } =
    useAppSelector(selectLoyaltyState);
  const { giftCardAmountApplied, isLoyaltyASVCard } =
    useAppSelector(selectGiftCardState);
  const { siteConfig } = useSiteConfig();
  return (
    <CheckContainer>
      <CheckRowContainer>
        <SubtotalRow
          id="totals-subtotal-value"
          label="Subtotal"
          price={
            loyaltyIsAssigned ? preLoyaltyItemsTotal : totals?.ItemsAndMods
          }
          loading={loading}
          loadingLength={90}
          bold
          showIfZero
        />
        <CheckRow
          id="totals-tax-value"
          label="Tax"
          price={totals?.Tax}
          loading={loading}
          loadingLength={24}
          showIfZero
        />
        {serviceCharges?.map((charge, index) => (
          <CheckRow
            id="ExtraChargesRow"
            label={charge.Name}
            price={charge.Amount}
            loading={loading}
            loadingLength={80}
            key={`${charge.Name}-${index}`}
          />
        ))}
        {siteConfig?.IsUseTipConfiguration ? (
          <CheckRow
            id="totals-tip-value"
            label={
              selectedTip.type === PERCENT
                ? `Tip (${selectedTip.percent}%)`
                : 'Tip'
            }
            price={selectedTip.amount}
            loading={loading}
            loadingLength={24}
            showIfZero
          />
        ) : null}
        {priceIsValidForDisplay(totals?.Discount) ? (
          <CheckRow
            label="Discount"
            price={totals?.Discount}
            loading={loading}
            loadingLength={72}
            showAsNegative
          />
        ) : null}
        {priceIsValidForDisplay(totals?.Gratuity) ? (
          <CheckRow
            label="Gratuity"
            price={totals?.Gratuity}
            loading={loading}
          />
        ) : null}
        {payments?.map((payment, index) => (
          <CheckRow
            label={getPaymentTypeName(payment, tenders)}
            price={payment.Amount}
            loading={loading}
            loadingLength={72}
            showAsNegative
            key={`${payment.Id}-${index}`}
          />
        ))}
        {priceIsValidForDisplay(loyaltyDiscountAmountApplied) ? (
          <CheckRow
            label="Loyalty Discount"
            price={loyaltyDiscountAmountApplied}
            loading={loading}
            loadingLength={72}
            showAsNegative
          />
        ) : null}
        {priceIsValidForDisplay(giftCardAmountApplied) &&
        !isGiftCardAmountPaid ? (
          <CheckRow
            label={isLoyaltyASVCard ? 'Loyalty Dollars' : 'Gift Card'}
            price={giftCardAmountApplied}
            loading={loading}
            loadingLength={70}
            showAsNegative
          />
        ) : null}
        <CheckDivider />
        <TotalRow
          id="total-value"
          label="Total"
          price={paymentTotal}
          loading={loading}
          loadingLength={62}
          bold
          showIfZero
        />
      </CheckRowContainer>
    </CheckContainer>
  );
};
