import { getApi } from 'api/utils';

const api = getApi();

export const getLoyaltyByPhoneNumber = (siteId: number, phoneNumber: string) =>
  api.getLoyaltyByPhoneNumber(siteId, phoneNumber);

export const assignLoyalty = (
  code: string,
  cardNumber: string,
  companyId: number
) => api.assignLoyalty(code, cardNumber, companyId);

export const getLoyaltyStatus = (checkCode: string) =>
  api.getLoyaltyStatus(checkCode);

export const setPreDiscountItemsTotal = (
  checkCode: string,
  preDiscountItemsTotal: number
) => api.setPreDiscountItemsTotal(checkCode, preDiscountItemsTotal);

export const setLoyaltySavingsAndItemsTotal = (
  checkCode: string,
  loyaltySavingsOnItems: number,
  loyaltySavingsOnOtherCharges: number,
  itemsTotal: number
) =>
  api.setLoyaltySavingsAndItemsTotal(
    checkCode,
    loyaltySavingsOnItems,
    loyaltySavingsOnOtherCharges,
    itemsTotal
  );

export const resetLoyaltySavingsAndItemsTotal = (checkCode: string) =>
  api.resetLoyaltySavingsAndItemsTotal(checkCode);
