import { useAppDispatch } from 'app/hooks';
import { focusOnNextInput } from 'features/Common/utils';
import { validateCreditCardCvv } from 'features/Common/validation';
import { StyledCreditCardCvvInput } from 'features/CreditCardInputSection/CreditCardInputComponents';
import {
  setCreditCardCvv,
  setIsCCCvvValid,
} from 'features/CreditCardInputSection/CreditCardInputSlice';
import { useCreditCardInput } from 'features/CreditCardInputSection/useCreditCardInput';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import { useCallback, useEffect } from 'react';
import { creditCardCvvInputId, creditCardZipInputId } from 'types/constants';

interface CreditCardCvvInputProps {
  loading: boolean;
  disabled?: boolean;
}

export const CreditCardCvvInput = ({
  loading,
  disabled,
}: CreditCardCvvInputProps) => {
  const dispatch = useAppDispatch();
  const { creditCardCvv, creditCardCvvLimit } = useCreditCardInput();

  // For when a card type is changed so cvvLimit gets changed
  useEffect(() => {
    if (creditCardCvv.length === creditCardCvvLimit) {
      dispatch(setIsCCCvvValid(true));
    } else {
      dispatch(setIsCCCvvValid(false));
    }
  }, [creditCardCvv.length, creditCardCvvLimit, dispatch]);

  const handleKeyUp = useCallback(() => {
    dispatch(setIsCCCvvValid(false));

    if (
      creditCardCvv.length === creditCardCvvLimit &&
      !validateCreditCardCvv(creditCardCvv, creditCardCvvLimit)
    ) {
      // If valid CVV, focus on zip input
      dispatch(setIsCCCvvValid(true));
      focusOnNextInput(creditCardCvvInputId, creditCardZipInputId);
    }
  }, [creditCardCvv, creditCardCvvLimit, dispatch]);

  const handleBlur = () => {
    if (creditCardCvv.length > 0) {
      const errorMessage = validateCreditCardCvv(
        creditCardCvv,
        creditCardCvvLimit
      );

      if (errorMessage) {
        dispatch(
          setModalOptions({
            type: 'error',
            header: 'Invalid Credit Card',
            text: errorMessage,
          })
        );
        dispatch(openModal());
      }
    }
  };

  return (
    <StyledCreditCardCvvInput
      id="ccInfo_cvv"
      onChange={(event) => dispatch(setCreditCardCvv(event.target.value))}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      placeholder="CVV"
      maxLength={creditCardCvvLimit}
      type="tel"
      value={creditCardCvv}
      disabled={disabled || loading}
    />
  );
};
