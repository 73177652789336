import { CheckPage } from './pages/Check';
import { CodePage } from './pages/Code';
import { PaymentPage } from './pages/Payment';
import { CallbackPage } from './pages/Callback';
import { PaymentConfirmationPage } from './pages/PaymentConfirmation';
import { MpRoute } from './types';

// Note: If you add a route here, you will need to add it in the reactRoutes array in Global.RegisterCustomRoutes in Facade
export const rootRoute = '';
export const checkviewRoute = 'checkview';
export const paymentRoute = 'payment';
export const callbackRoute = 'callback';
export const paymentConfirmationRoute = 'payConfirmation';

const MainRoutes: MpRoute[] = [
  {
    path: `/${paymentConfirmationRoute}`,
    name: 'PaymentConfirmation',
    component: PaymentConfirmationPage,
  },
  {
    path: `/${paymentRoute}`,
    name: 'Payment',
    component: PaymentPage,
  },
  {
    path: `/${checkviewRoute}`,
    name: 'Check',
    component: CheckPage,
  },
  {
    path: `/${callbackRoute}`,
    name: 'Callback',
    component: CallbackPage,
  },
  {
    path: `/${rootRoute}`,
    name: 'Code',
    component: CodePage,
  },
];

const AddDemoRoutes = (Routes: MpRoute[]) => {
  const RoutesWithDemos = [...Routes];
  for (var i = Routes.length - 1; i >= 0; i--) {
    RoutesWithDemos.unshift({
      path: `/demo${Routes[i].path}`,
      name: `${Routes[i].name}Demo`,
      component: Routes[i].component,
    });
  }
  return RoutesWithDemos;
};

export default AddDemoRoutes(MainRoutes);
