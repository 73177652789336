import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalType } from 'types';

export interface ModalOptions {
  type: ModalType;
  header?: string;
  text?: string;
}

export interface ModalState {
  open: boolean;
  options: ModalOptions;
}

const initialState: ModalState = {
  open: false,
  options: {
    type: 'message',
    header: '',
    text: '',
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state) => {
      state.open = true;
    },
    closeModal: (state) => {
      state.open = false;
    },
    setModalOptions: (state, { payload }: PayloadAction<ModalOptions>) => {
      state.options = payload;
    },
  },
});

export const { openModal, closeModal, setModalOptions } = modalSlice.actions;

export default modalSlice.reducer;
