import { useAppDispatch } from 'app/hooks';
import {
  focusOnNextInput,
  getCardType,
  getCreditCardNumberLimits,
  getImageSrcForCardType,
} from 'features/Common/utils';
import { validateCreditCardNumber } from 'features/Common/validation';
import { StyledCreditCardNumberInput } from 'features/CreditCardInputSection/CreditCardInputComponents';
import {
  setCardImageSrc,
  setCardType,
  setCreditCardCvvLimit,
  setCreditCardNumber,
  setCreditCardNumberLimit,
  setIsCCNumberValid,
} from 'features/CreditCardInputSection/CreditCardInputSlice';
import { useCreditCardInput } from 'features/CreditCardInputSection/useCreditCardInput';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import { KeyboardEvent, useCallback } from 'react';
import { creditCardExpInputId, creditCardNumberInputId } from 'types/constants';

interface CreditCardNumberInputProps {
  loading: boolean;
  disabled?: boolean;
}

export const CreditCardNumberInput = ({
  loading,
  disabled,
}: CreditCardNumberInputProps) => {
  const dispatch = useAppDispatch();
  const { cardType, creditCardNumber, creditCardNumberLimit } =
    useCreditCardInput();

  // check for validity of CC number when user has entered CC but moved to another input
  const handleBlur = () => {
    if (creditCardNumber.length > 0) {
      const errorMessage = validateCreditCardNumber(
        creditCardNumber,
        creditCardNumberLimit
      );

      if (errorMessage) {
        dispatch(
          setModalOptions({
            type: 'error',
            header: 'Invalid Credit Card',
            text: errorMessage,
          })
        );
        dispatch(openModal());
      }
    }
  };

  const handleKeyUp = useCallback(
    (event: KeyboardEvent<HTMLDivElement>): void => {
      dispatch(setIsCCNumberValid(false));

      // Define what card type it is
      const newCardType = getCardType(creditCardNumber);
      const newCreditCardLimit = getCreditCardNumberLimits(newCardType);

      if (newCardType !== cardType) {
        // Reset the card type
        dispatch(setCardType(newCardType));

        // Change this card's image src
        const cardImageSrc = getImageSrcForCardType(newCardType);
        dispatch(setCardImageSrc(cardImageSrc));

        // Set this card's limit for card number and cvv
        dispatch(
          setCreditCardNumberLimit(newCreditCardLimit.creditCardNumberLimit)
        );
        dispatch(setCreditCardCvvLimit(newCreditCardLimit.cvvLimit));
      }

      if (
        creditCardNumber.length === newCreditCardLimit.creditCardNumberLimit &&
        !validateCreditCardNumber(creditCardNumber, creditCardNumberLimit)
      ) {
        // If valid CC number, focus on exp input
        dispatch(setIsCCNumberValid(true));
        focusOnNextInput(creditCardNumberInputId, creditCardExpInputId);
      }
    },
    [cardType, creditCardNumber, creditCardNumberLimit, dispatch]
  );

  return (
    <StyledCreditCardNumberInput
      id="ccInfo_cardNumber"
      onChange={(event) => dispatch(setCreditCardNumber(event.target.value))}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      placeholder="Credit Card Number"
      maxLength={creditCardNumberLimit}
      type="tel"
      value={creditCardNumber}
      disabled={disabled || loading}
    />
  );
};
