import styled from 'styled-components/macro';
import { InputContainer } from '../InputContainer';

export const JoinedInputContainer = styled(InputContainer)`
  border: ${({ theme }) => theme.colors.input.border};
  border-radius: ${({ theme }) => theme.sizes.radius.border};

  > * {
    margin: 0px;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    padding-top: 20px 16px 20px 0px;
  }
`;
