import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

interface DrawerOptions {
  title: string;
}

export interface DrawerState {
  open: boolean;
  options: DrawerOptions;
}

const initialState: DrawerState = {
  open: false,
  options: {
    title: '',
  },
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state) => {
      state.open = true;
    },
    closeDrawer: (state) => {
      state.open = false;
    },
    setDrawerOptions: (state, { payload }: PayloadAction<DrawerOptions>) => {
      state.options = payload;
    },
  },
});

export const { openDrawer, closeDrawer, setDrawerOptions } =
  drawerSlice.actions;

export const selectDrawerState = (state: RootState) => state.drawer;

export default drawerSlice.reducer;
