export const customTipIds = { NONE: 'none', CUSTOM: 'custom' };
export const checkCodeLength = 6;
export const fpBillingPhoneNumberLength = 12;
export const fpBillingStateLength = 2;
export const fpBillingZipCodeLength = 5;

//#region Regexs
export const checkCodeRegex = /[a-zA-Z]{3}\d{3}/;
export const numberRegex = /^[0-9]+$/;
export const zipRegex = /^\d{5}$|^\d{5}-\d{4}$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberRegex = /^\d{3}-\d{3}-\d{4}$/;
export const stateRegex = /^[a-zA-Z]{2}$/;
//#endregion Regexs

//#region Interval Periods
export const loyaltyPollingPeriodMs = 3000;
export const loyaltyPollingTimeoutMs = 15000;
export const checkDataPollingIntervalMs = 120000;
//#endregion Interval Periods

//#region Cookie Names
export const loyaltyIsAssignedCookie = 'IsLoyaltyAssigned';
export const loyaltyCheckCodeCookie = 'CheckCodeAfterLoyalty';
export const codeCookie = 'code';
export const eTagCookie = 'eTag';
export const checkIdCookie = 'checkId';
export const siteIdCookie = 'siteId';
export const firstLoadCookie = 'firstLoad';
//#endregion Cookie Names

//#region Component Ids
export const creditCardNumberInputId = 'ccInfo_cardNumber';
export const creditCardExpInputId = 'ccInfo_expiration';
export const creditCardCvvInputId = 'ccInfo_cvv';
export const creditCardZipInputId = 'ccInfo_zipcode';
export const edcPayButtonId = 'payBtn';
export const ncrSecurePaymentPayButtonId = 'payBtnWithNCRSecurePayment';
export const cpIframeId = 'ncrSecurePaymentIframe';
export const fpIframeId = 'freedomPayIframe';
export const fpGPayDivId = 'fp_gpay_div';
export const iframeTagName = 'IFRAME';
export const fpPayButtonId = 'fpPayBtn';
export const fpPayWithCreditCardButtonId = 'freedomPayWithCreditCardButton';
export const fpBillingInfoContinueButtonId = 'freedomPayContinueButton';
export const fpPaymentMethodSelectionPageId =
  'freedomPaymentMethodSelectionPage';
//#endregion Component Ids

//#region Arrays
export const tempIsoStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'DC',
  'AS',
  'GU',
  'MP',
  'PR',
  'UM',
  'VI',
];
export const isoStates = {
  AL: 'US-AL',
  ALABAMA: 'US-AL',
  AK: 'US-AK',
  ALASKA: 'US-AK',
  AZ: 'US-AZ',
  ARIZONA: 'US-AZ',
  AR: 'US-AR',
  ARKANSAS: 'US-AR',
  CA: 'US-CA',
  CALIFORNIA: 'US-CA',
  CO: 'US-CO',
  COLORADO: 'US-CO',
  CT: 'US-CT',
  CONNECTICUT: 'US-CT',
  DE: 'US-DE',
  DELAWARE: 'US-DE',
  FL: 'US-FL',
  FLORIDA: 'US-FL',
  GA: 'US-GA',
  GEORGIA: 'US-GA',
  HI: 'US-HI',
  HAWAII: 'US-HI',
  ID: 'US-ID',
  IDAHO: 'US-ID',
  IL: 'US-IL',
  ILLINOIS: 'US-IL',
  IN: 'US-IN',
  INDIANA: 'US-IN',
  IA: 'US-IA',
  IOWA: 'US-IA',
  KS: 'US-KS',
  KANSAS: 'US-KS',
  KY: 'US-KY',
  KENTUCKY: 'US-KY',
  LA: 'US-LA',
  LOUISIANA: 'US-LA',
  ME: 'US-ME',
  MAINE: 'US-ME',
  MD: 'US-MD',
  MARYLAND: 'US-MD',
  MA: 'US-MA',
  MASSACHUSETTS: 'US-MA',
  MI: 'US-MI',
  MICHIGAN: 'US-MI',
  MN: 'US-MN',
  MINNESOTA: 'US-MN',
  MS: 'US-MS',
  MISSISSIPPI: 'US-MS',
  MO: 'US-MO',
  MISSOURI: 'US-MO',
  MT: 'US-MT',
  MONTANA: 'US-MT',
  NE: 'US-NE',
  NEBRASKA: 'US-NE',
  NV: 'US-NV',
  NEVADA: 'US-NV',
  NH: 'US-NH',
  'NEW HAMPSHIRE': 'US-NH',
  NJ: 'US-NJ',
  'NEW JERSEY': 'US-NJ',
  NM: 'US-NM',
  'NEW MEXICO': 'US-NM',
  NY: 'US-NY',
  'NEW YORK': 'US-NY',
  NC: 'US-NC',
  'NORTH CAROLINA': 'US-NC',
  ND: 'US-ND',
  'NORTH DAKOTA': 'US-ND',
  OH: 'US-OH',
  OHIO: 'US-OH',
  OK: 'US-OK',
  OKLAHOMA: 'US-OK',
  OR: 'UR-OR',
  OREGON: 'US-OR',
  PA: 'US-PA',
  PENNSYLVANIA: 'US-PA',
  RI: 'US-RI',
  'RHODE ISLAND': 'US-RI',
  SC: 'US-SC',
  'SOUTH CAROLINA': 'US-SC',
  SD: 'US-SD',
  'SOUTH DAKOTA': 'US-SD',
  TN: 'US-TN',
  TENNESSEE: 'US-TN',
  TX: 'US-TX',
  TEXAS: 'US-TX',
  UT: 'US-UT',
  UTAH: 'US-UT',
  VT: 'US-VT',
  VERMONT: 'US-VT',
  VA: 'US-VA',
  VIRGINIA: 'US-VA',
  WA: 'US-WA',
  WASHINGTON: 'US-WA',
  WV: 'US-WV',
  'WEST VIRGINIA': 'US-WV',
  WI: 'US-WI',
  WISCONSIN: 'US-WI',
  WY: 'US-WY',
  WYOMING: 'US-WY',
  DC: 'US-DC',
  'DISTRICT OF COLUMBIA': 'US-DC',
  AS: 'US-AS',
  'AMERICAN SAMOA': 'US-AS',
  GU: 'US-GU',
  GUAM: 'US-GU',
  MP: 'US-MP',
  'NORTHERN MARIANA ISLANDS': 'US-MP',
  PR: 'US-PR',
  'PUERTO RICO': 'US-PR',
  UM: 'US-UM',
  'UNITED STATES MINOR OUTLYING ISLANDS': 'US-UM',
  VI: 'US-VI',
  'VIRGIN ISLANDS, U.S.': 'US-VI',
};
//#endregion Arrays

//#region Enum
export enum CREDIT_CARD_TYPES {
  NONE = 'none',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  AMEX = 'amex',
  DINERS = 'diners',
  DISCOVER = 'discover',
  JCB = 'jcb',
}
export enum PaymentCardType {
  None,
  Credit,
  GiftCard,
  Visa,
  MasterCard,
  Amex,
  Discover,
  Diners,
  JCB,
}
export enum PageName {
  Code = 'Code',
  Check = 'Check',
  Payment = 'Payment',
  PaymentConfirmation = 'PaymentConfirmation',
}
export enum DrawerContentOption {
  CPIFRAME = 'cpIframe',
  FPIFRAME = 'fpIframe',
  PROCESSING = 'processing',
}
export enum FreedomPayErrorCode {
  INCORRECT_EXP_DATE = 202,
  INSUFFICIENT_FUNDS = 204,
  INVALID_CVV = 211,
  INVALID_ACCOUNT_NUMBER = 231,
  WRONG_APPROVED_AMOUNT = 239,
  INVALID_REQUEST_ID = 241,
  TRANSACTION_IS_SETTLED_ALREADY = 243,
  CANNOT_BE_VOIDED = 246,
  ALREADY_VOIDED = 247,
  REQUEST_TIMEOUT = 250,
  INVALID_DATE = 254,
}
export enum FreedomPayBillingInformation {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL_ADDRESS = 'emailAddress',
  PHONE_NUMBER = 'phoneNumber',
  STREET_ADDRESS = 'streetAddress',
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zipCode',
}
//#endregion Enum

//#region CSS Styles
export const googlePayButtonStyle = 'form { padding: 0 10px 0 10px; }';
export const applePayButtonStyle =
  '.fp-apple-pay-button { width: 100%; height: 40px; } form { padding: 0 10px 0 10px; line-height: 0; border: none; }';
//#endregion CSS Styles
