import { CheckRow } from "components/CheckRow";
import styled from "styled-components";

export const SubtotalRow = styled(CheckRow)`
  font-size: 16px;
  line-height: 19px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const TotalRow = styled(CheckRow)`
  font-size: 18px;
  line-height: 21px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 28px;
    line-height: 33px;
  }
`;