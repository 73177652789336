import { useAppDispatch, useAppSelector } from 'app/hooks';
import { isDemo } from 'app/utils';
import { CheckCodeHeader } from 'components/CheckCodeHeader';
import currency from 'currency.js';
import { getAmountPaid } from 'features/Common/commonApi';
import { selectPaymentState, setPaidAmount } from 'pages/Payment/paymentSlice';
import { useEffect } from 'react';
import styled from 'styled-components/macro';

const PaidSuccess = styled.div`
  font-size: 18px;
  text-align: left;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 24px;
  }
`;

const StyledCheckCodeHeader = styled(CheckCodeHeader)`
  font-size: 12px;
  display: flex;
  justify-content: start;
  margin: 12px 0 0 0;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 16px;
    margin: 16px 0 0 0;
  }
`;

const PaidInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaidInfo = () => {
  const { paymentTotal, paidAmount, paymentId } =
    useAppSelector(selectPaymentState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const AmountPaid = async () => {
      await getAmountPaid(paymentId).then((res) =>
        dispatch(setPaidAmount(res.data.AmountPaid))
      );
    };
    if (isNaN(paidAmount)) {
      AmountPaid();
    }
  }, [dispatch, paidAmount, paymentId]);

  return (
    <PaidInfoContainer>
      <PaidSuccess>
        Paid{' '}
        <b>
          {isDemo()
            ? currency(paymentTotal).format()
            : isNaN(paidAmount)
            ? ''
            : currency(paidAmount).format()}
        </b>{' '}
        successfully
      </PaidSuccess>
      <StyledCheckCodeHeader />
    </PaidInfoContainer>
  );
};
