import { ResponseCode } from 'api';
import { assignLoyalty, getLoyaltyByPhoneNumber } from './LoyaltyApi';

export const getLoyaltyMemberProfile = async (
  siteId: number,
  phoneNumber: string
) => {
  try {
    const { data } = await getLoyaltyByPhoneNumber(siteId, phoneNumber);
    return {
      MemberProfile: data.MemberProfile,
      responseCode: ResponseCode.OK,
      Message: data.Message.replace(/-/g, ''),
    };
  } catch (error: any) {
    return {
      responseCode: ResponseCode.Error,
    };
  }
};

export const assignLoyaltyToCheck = async (
  checkCode: string,
  cardNumber: string,
  companyId: number
) => {
  try {
    const { data, status } = await assignLoyalty(
      checkCode,
      cardNumber,
      companyId
    );
    return {
      Message: data.Message,
      Status: data.Status,
      ResponseCode: status,
    };
  } catch (error: any) {
    return {
      ResponseCode: ResponseCode.Error,
      Status: -1,
    };
  }
};
