import { useAppSelector } from 'app/hooks';
import currency from 'currency.js';
import { selectGiftCardState } from 'features/GiftCardSection/GiftCardSlice';
import styled from 'styled-components';

const StyledSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 20px;
  }
`;

const BalanceInfoContainer = styled.div`
  display: block;
  margin-left: 15px;
`;

const RemoveContainer = styled.span<{ disabled?: boolean }>`
  color: #0a84ff;
  cursor: pointer;
  pointer-events: ${({ disabled }) => disabled && `none`};
`;
interface GiftCardSummaryProps {
  removeHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
}

export const GiftCardSummary = ({
  removeHandler,
  disabled,
}: GiftCardSummaryProps) => {
  const {
    totalGiftCardBalance,
    giftCardNumber,
    isGiftCardAmountPaid,
    giftCardAmountApplied,
  } = useAppSelector(selectGiftCardState);
  const fourDigitCardNumber = giftCardNumber?.slice(-4);
  return (
    <StyledSummaryContainer>
      <BalanceInfoContainer>
        {`— ${fourDigitCardNumber} ${
          isGiftCardAmountPaid
            ? `balance applied: ${currency(giftCardAmountApplied).format()}`
            : `available balance: ${currency(totalGiftCardBalance).format()}`
        }`}
      </BalanceInfoContainer>
      {!isGiftCardAmountPaid && (
        <RemoveContainer
          id="removeGiftCardButton"
          onClick={removeHandler}
          disabled={disabled || isGiftCardAmountPaid}
        >
          REMOVE
        </RemoveContainer>
      )}
    </StyledSummaryContainer>
  );
};
