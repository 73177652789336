import { useAppSelector } from 'app/hooks';
import { Divider } from 'components/Divider';
import { selectThemeConfig } from 'features/Common/themeConfigSlice';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';

const WelcomeContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.welcome.name}, sans-serif;
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-top: 48px;
  }
`;

const WelcomeText = styled.div`
  text-transform: uppercase;
  font-weight: 800;
  font-size: 20px;
  margin: 8px 8px 0 8px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
  }
`;

const WelcomeDivider = styled(Divider)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.welcome};

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    width: 288px;
  }
`;

export const Welcome = () => {
  const themeConfig = useAppSelector(selectThemeConfig);
  const defaultWelcomeText = useContext(ThemeContext).text.welcome;

  return (
    <WelcomeContainer>
      <WelcomeDivider />
      <WelcomeText>
        {themeConfig?.welcomeText ?? defaultWelcomeText}
      </WelcomeText>
    </WelcomeContainer>
  );
};
