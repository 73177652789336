import { ResponseCode } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MpInput } from 'components/MpInput';
import { selectCheckCode } from 'features/Common/checkInfoSlice';
import { validateEmail } from 'features/Common/validation';
import {
  EmailInputContainer,
  EmailReceiptInstruction,
  SendReceiptLabel,
  StyledDivider,
  StyledMpButton,
} from 'features/EmailInput/EmailInputComponents';
import { sendReceiptOnDemand } from 'features/EmailInput/sendEmailApi';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import { selectPaymentState } from 'pages/Payment/paymentSlice';
import { useEffect, useState } from 'react';

interface EmailInputProps {
  onEmailSent: (emailId: string) => void;
}

export const EmailInput = (props: EmailInputProps) => {
  const [emailInputValue, setEmailInputValue] = useState<string>('');
  const [enableSendEmail, setEnableSendEmail] = useState<boolean>(false);
  const [emailIsSending, setEmailIsSending] = useState<boolean>(false);
  const checkCode = useAppSelector(selectCheckCode);
  const dispatch = useAppDispatch();
  const paymentState = useAppSelector(selectPaymentState);

  const emailSendSuccess = () => {
    setEmailIsSending(false);
    props.onEmailSent(emailInputValue);
  };

  const emailSendFailed = (message: string) => {
    setEmailIsSending(false);
    dispatch(
      setModalOptions({
        type: 'error',
        header: 'Failed to send',
        text: message,
      })
    );
    dispatch(openModal());
  };

  const handleEmailSend = () => {
    setEmailIsSending(true);
    
    // TODO(NCRMP-1275): if paymentId doesn't exist, don't proceed sending email receipt
    
    sendReceiptOnDemand(
      checkCode,
      paymentState.paymentId ?? 0,
      emailInputValue
    ).then((response) => {
      response.responseCode === ResponseCode.OK
        ? emailSendSuccess()
        : emailSendFailed(response.message);
    });
  };

  useEffect(() => {
    setEnableSendEmail(validateEmail(emailInputValue));
  }, [emailInputValue]);

  return (
    <EmailInputContainer>
      <EmailReceiptInstruction>
        Enter your email address to send a receipt or just close your browser
        window.
      </EmailReceiptInstruction>
      <StyledDivider />
      <SendReceiptLabel>Send Receipt</SendReceiptLabel>
      <MpInput
        id="emailReceiptEmail"
        onChange={(event) => {
          setEmailInputValue(event.target.value);
        }}
        placeholder="Enter Email Address"
        disabled={emailIsSending}
      />
      <StyledMpButton
        id="paymentConfirmationDone"
        type="primary"
        disabled={!enableSendEmail || emailIsSending}
        onClick={handleEmailSend}
        loading={emailIsSending}
      >
        Send Receipt
      </StyledMpButton>
    </EmailInputContainer>
  );
};
