// This component is based heavily on the react-sliding-pane https://github.com/DimitryDushkin/sliding-pane
// I wasn't able to override some of the styles with the provided API, so I had to bring it into the project
// in order to make the necessary modifications

import closeIcon from 'images/close.svg';
import 'pages/Payment/Drawer/Drawer.css';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';

const CLOSE_TIMEOUT = 500;

function useUpdateStateIfMounted<T>(initialValue: T) {
  const isMountedRef = useRef(true);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  const [state, setState] = useState(initialValue);

  const setStateIfMounted = (value: T) => {
    if (isMountedRef.current === true) {
      setState(value);
    }
  };

  return [state, setStateIfMounted] as const;
}

interface DrawerProps {
  isOpen?: boolean;
  isLoading: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  from?: 'left' | 'right' | 'bottom'; // "right" — default
  children: React.ReactNode;
  className?: string;
  overlayClassName?: string;
  width?: string; // CSS string for width
  shouldCloseOnEsc?: boolean;
  hideHeader?: boolean;
  onRequestClose: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
}

export const Drawer = ({
  isOpen,
  isLoading,
  title,
  onRequestClose,
  onAfterOpen,
  onAfterClose,
  children,
  className,
  overlayClassName,
  from = 'right',
  width,
  shouldCloseOnEsc,
  hideHeader = false,
}: DrawerProps) => {
  const directionClass = `slide-pane_from_${from}`;
  const [wasOpen, setWasOpen] = useUpdateStateIfMounted(false);

  const handleAfterOpen = () => {
    // Timeout fixes animation in Safari
    onAfterOpen?.();
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  };

  const handleAfterClose = () => {
    onAfterClose?.();
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  };

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName={{
        base: `slide-pane__overlay ${overlayClassName || ''}`,
        afterOpen: wasOpen ? 'overlay-after-open' : '',
        beforeClose: 'overlay-before-close',
      }}
      className={{
        base: `slide-pane ${directionClass} ${className || ''}`,
        afterOpen: wasOpen ? 'content-after-open' : '',
        beforeClose: 'content-before-close',
      }}
      style={{
        content: {
          width: width || '80%',
          backgroundColor: isLoading ? 'black' : 'white',
        },
      }}
      closeTimeoutMS={CLOSE_TIMEOUT}
      isOpen={isOpen ?? false}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      onRequestClose={onRequestClose}
      contentLabel={`Modal "${title || ''}"`}
    >
      {!hideHeader && (
        <div className="slide-pane__header">
          <div className="slide-pane__title-wrapper">
            <h2 className="slide-pane__title">{title}</h2>
          </div>
          <div
            className="slide-pane__close"
            onClick={onRequestClose}
            role="button"
            tabIndex={0}
          >
            <img src={closeIcon} alt="close icon" />
          </div>
        </div>
      )}
      <div className="slide-pane__content">{children}</div>
    </Modal>
  );
};
