import { CircleLoader } from 'components/MpLoaders';
import {
  StyledFPPayButton,
  StyledFPPayButtonDiv,
} from 'pages/Payment/FreedomPay/FreedomPayIframeComponents';
import { useTheme } from 'styled-components/macro';
import { fpPayButtonId } from 'types/constants';
interface FreedomPayButtonProps {
  onClick: () => void;
  disabled: boolean;
  loading?: boolean;
}

export const FreedomPayButton = ({
  onClick,
  disabled,
  loading,
}: FreedomPayButtonProps) => {
  const theme = useTheme();
  return (
    <StyledFPPayButtonDiv disabled={disabled}>
      <StyledFPPayButton id={fpPayButtonId} onClick={onClick}>
        {loading ? (
          <CircleLoader
            color={theme.colors.body.solid}
            cssOverride="display: inline-block;"
            size={18}
          />
        ) : (
          'Pay'
        )}
      </StyledFPPayButton>
    </StyledFPPayButtonDiv>
  );
};
