import styled from 'styled-components/macro';

interface CheckItemValueProps {
  bold?: boolean;
}

export const CheckItemValue = styled.div<CheckItemValueProps>`
  font-size: ${({ bold }) => (bold ? '16px' : '14px')};
  line-height: 17px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  font-weight: ${({ bold }) => (bold ? '700' : '300')};

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 20px;
    line-height: 24px;
  }
`;
