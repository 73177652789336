import PuffLoader from 'react-spinners/PuffLoader';

export interface CircleLoaderProps {
  size?: number;
  color?: string;
  loading?: boolean;
  cssOverride?: string;
}

export const CircleLoader = ({
  size,
  color,
  loading,
  cssOverride,
}: CircleLoaderProps) => {
  return (
    <PuffLoader
      css={cssOverride}
      loading={loading || true}
      color={color || 'rgb(255, 255, 255, 0.9)'}
      size={size || 25}
    />
  );
};
