import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CustomTipButton } from 'components/TipButtons/CustomTipButton';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import {
  selectPaymentState,
  setTipAndUpdatePaymentTotal,
} from 'pages/Payment/paymentSlice';
import styled from 'styled-components/macro';
import { customTipIds } from 'types/constants';
import { PERCENT } from 'types/DTOs/TipBase';

const CustomTipsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledNoTipButton = styled(CustomTipButton)`
  width: 38%;
`;

const StyledCustomTipButton = styled(CustomTipButton)`
  width: 58%;
`;

interface CustomTipsProps {
  loading: boolean;
}

export const CustomTips = ({ loading }: CustomTipsProps) => {
  const paymentState = useAppSelector(selectPaymentState);
  const dispatch = useAppDispatch();

  const { NONE, CUSTOM } = customTipIds;

  const handleNoTip = () => {
    dispatch(
      setTipAndUpdatePaymentTotal({
        id: NONE,
        percent: 0,
        type: PERCENT,
        amount: 0,
      })
    );
  };

  const handleCustomTipClick = () => {
    dispatch(setModalOptions({ type: 'tip' }));
    dispatch(openModal());
  };

  const customTipSelected = paymentState.selectedTip.id === CUSTOM;

  const getCustomTip = () =>
    customTipSelected ? paymentState.selectedTip : undefined;

  return (
    <CustomTipsContainer>
      <StyledNoTipButton
        id="tipNone"
        onClick={handleNoTip}
        selected={paymentState.selectedTip.id === NONE}
        loading={loading}
      >
        No Tip
      </StyledNoTipButton>
      <StyledCustomTipButton
        id="tipCustom"
        selected={customTipSelected}
        onClick={handleCustomTipClick}
        customTip={getCustomTip()}
        loading={loading}
      >
        Custom Tip
      </StyledCustomTipButton>
    </CustomTipsContainer>
  );
};
