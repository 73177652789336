import currency from 'currency.js';
import styled from 'styled-components/macro';
import { Tip } from 'types';
import { TipButton } from '.';
import { TipBaseSubText } from './TipBaseSubText';

const StyledTipButton = styled(TipButton)`
  min-height: 44px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    min-height: 56px;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.3px;
  }
`;

const CustomTipSubText = styled(TipBaseSubText)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSubTextSpan = styled.span`
  margin: 0 5px 0 5px;
`;

const StyledAsciiDot = styled.span`
  font-size: 5px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 7px;
  }
`;

interface CustomTipButtonProps {
  id?: string;
  selected: boolean;
  customTip?: Tip;
  children?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  loading: boolean;
}

export const CustomTipButton = ({
  id,
  selected,
  customTip,
  children,
  onClick,
  className,
  loading,
}: CustomTipButtonProps) => {
  return (
    <StyledTipButton
      id={id}
      className={className}
      selected={selected}
      onClick={onClick}
      disabled={loading}
    >
      {children}
      {customTip ? (
        <CustomTipSubText>
          <StyledSubTextSpan>
            {currency(customTip.amount).format()}
          </StyledSubTextSpan>
          <StyledAsciiDot>{'\u2B24'}</StyledAsciiDot>
          <StyledSubTextSpan>{customTip.percent}%</StyledSubTextSpan>
        </CustomTipSubText>
      ) : null}
    </StyledTipButton>
  );
};
