import { MpButton } from 'components/MpButton';
import styled from 'styled-components';
import Modal from 'styled-react-modal';

export const StyledBaseModal = Modal.styled`
    background: #333333;
    border-radius: 0;
    text-align: center;
    font-size: 14px;
    padding: 56px 16px 32px 16px;
    min-width: 288px;
    max-width: 305px;
    @media only screen and (min-width: ${(props: any) =>
      props.theme.sizes.screen.small}) {
        font-size: 20px;
        padding: 56px 24px 40px 24px;
    }
`;

export const CloseIconContainer = styled.div`
  position: relative;
`;

export const CloseIcon = styled.img`
  top: -35px;
  right: 5px;
  position: absolute;
  @media only screen and (min-width: $smallest-screen) {
    height: 20px;
    width: 20px;
  }
`;

export const ModalButton = styled(MpButton)`
  background-color: ${({ theme }) => theme.colors.buttonNeutral.background};
  border: 1px solid ${({ theme }) => theme.colors.buttonNeutral.border};
`;

export const ModalText = styled.p`
  margin-bottom: 32px;
`;

export const ModalHeader = styled.h4`
  margin-bottom: 24px;
`;

export const ModalIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalIcon = styled.img``;
