import styled from 'styled-components/macro';

export const ContentContainer = styled.div`
  margin: 0 16px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin: auto;
    max-width: ${({ theme }) => theme.sizes.screen.small};
  }
`;
