import { MpButton } from 'components/MpButton';
import styled from 'styled-components';

export const StyledPayButton = styled(MpButton)`
  margin-top: 32px;
`;

export const StyledIframeContextText = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  margin-top: 15px;
`;
