import { useAppSelector } from 'app/hooks';
import { selectCheckInfo } from 'features/Common/checkInfoSlice';
import styled from 'styled-components/macro';

const CheckCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const CheckCodeLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const CheckCode = styled.div`
  text-transform: uppercase;
`;

interface CheckCodeHeaderProps {
  className?: string;
}

export const CheckCodeHeader = ({ className }: CheckCodeHeaderProps) => {
  const { checkCode } = useAppSelector(selectCheckInfo);

  return (
    <CheckCodeContainer className={className}>
      <CheckCodeLabel>Check</CheckCodeLabel>
      <CheckCode>#{checkCode}</CheckCode>
    </CheckCodeContainer>
  );
};
