import styled from 'styled-components/macro';
import { InputContainer } from 'components/InputContainer';
import { MpInput } from 'components/MpInput';

export const GiftCardSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GiftCardInputContainer = styled(InputContainer)`
  line-height: 14px;
  padding-right: 0px;
  display: flex;
  width: 100%;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    line-height: 18px;
  }
`;

export const StyledGiftCardInput = styled(MpInput)<{ epin: boolean }>`
  ${({ epin }) =>
    epin &&
    `& .MuiOutlinedInput-root {border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  }`}
  height: 16px;
  padding: 14px 0 14px 0;
  width: 100%;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-bottom: 32px;
    height: 20px;
    padding: 24px 0 24px 0;
  }
`;

export const StyledGiftCardPinInput = styled(MpInput)`
  width: 11rem;
  & .MuiOutlinedInput-root {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  height: 16px;
  padding: 14px 0 14px 0;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    height: 20px;
    padding: 20px 0 20px 0;
  }
`;
