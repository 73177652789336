import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export const LoadingComponentContainer = styled.div`
  margin: 17px 0px 16px 0px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin: 24px 0px 23px 0px;
  }
`;

export const LoadingSkeleton = styled(Skeleton)`
  display: block;
  margin: 16px 0px 16px 0px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin: 16px 0px 16px 0px;
  }
`;

export const LoadingSkeletonButton = styled(Skeleton)`
  min-height: 47px;
  border: ${({ theme }) => `1px solid ${theme.colors.buttonDisabled.border}`};
  border-radius: 11px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    min-height: 67px;
  }
`;
