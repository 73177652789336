import { TextField } from '@mui/material';
import styled from 'styled-components/macro';

const StyledTextField = styled(TextField)`
  border-radius: 3px;

  & .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.colors.input.text};
    background-color: ${({ theme }) => theme.colors.input.background};
    border: 1px solid ${({ theme }) => theme.colors.input.border};
    padding: 14px;
    line-height: 16px;

    @media only screen and (min-width: ${({ theme }) =>
        theme.sizes.screen.small}) {
      padding: 24px;
      line-height: 18px;
    }
  }

  & .MuiInputBase-root {
    & .MuiOutlinedInput-notchedOutline {
      border-width: 0px;
    }

    &.Mui-focused {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.button.background};
        border-width: 1px;
      }
    }

    &:focus {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.button.background};
        border-width: 1px;
      }
    }

    &.Mui-error {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.error};
        border-width: 1px;
      }
    }
  }

  & .MuiInputBase-input {
    text-align: center;
    font-size: 14px;
    padding: 0px;
    line-height: 0px;
    border: none;
    height: 24px;

    ::placeholder {
      opacity: 1;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: ${({ theme }) => theme.colors.text.primary};
      transition: background-color 5000s ease-in-out 0s;
    }

    &.Mui-disabled {
      -webkit-text-fill-color: ${({ theme }) => theme.colors.text.primary};
    }

    @media only screen and (min-width: ${({ theme }) =>
        theme.sizes.screen.small}) {
      font-size: 20px;
    }
  }

  &.Mui-error {
    &:focus {
      & .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.error};
      }
    }
  }

  & .MuiFormHelperText-root {
    font-size: 14px;
  }
`;

interface MpInputProps {
  id?: string;
  placeholder?: string;
  name?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  value?: string;
  type?: string;
  inputMode?: 'numeric' | 'text';
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  autoFocus?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

export const MpInput = ({
  id,
  placeholder,
  name,
  value,
  type,
  onChange,
  inputMode,
  className,
  onKeyDown,
  onKeyUp,
  maxLength,
  autoFocus,
  onBlur,
  error,
  helperText,
  disabled,
}: MpInputProps) => {
  return (
    <>
      <StyledTextField
        id={id}
        className={className}
        name={name}
        inputMode={inputMode || 'text'}
        type={type || 'text'}
        autoCorrect="off"
        autoComplete="off"
        spellCheck="false"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        inputProps={{ maxLength: maxLength }}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
    </>
  );
};
