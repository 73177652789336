import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseCode } from 'api';
import { createAxiosResponse } from 'api/utils';
import { RootState } from 'app/store';
import currency from 'currency.js';
import { loadCheckInfo } from 'features/Common/checkInfoSlice';
import { Cookies } from 'react-cookie';
import {
  loyaltyCheckCodeCookie,
  loyaltyIsAssignedCookie,
} from 'types/constants';
import { Totals } from 'types/DTOs';
import {
  getLoyaltyStatus,
  resetLoyaltySavingsAndItemsTotal,
  setLoyaltySavingsAndItemsTotal,
} from './LoyaltyApi';

export interface LoyaltyState {
  preLoyaltyItemsTotal: number; // store pre-loyalty items total to calc loyalty savings on items
  preLoyaltyCharges: number; // store pre-loyalty tax + service charges to calc loyalty savings on charges
  loyaltyDiscountAmountApplied: number; // The amount saved via the loyalty program.
  loyaltyIsProcessing: boolean; // loyalty is being assigned and savings are being processed
  loyaltyIsVerified: boolean; // required to exit loyalty data polling while savings are being processed
  loyaltyIsAssigned: boolean; // loyalty is assigned
  loyaltyTimeoutId: number; // loyalty setTimeout id for verifying loyalty assignment
}

const initialState: LoyaltyState = {
  preLoyaltyItemsTotal: 0,
  preLoyaltyCharges: 0,
  loyaltyDiscountAmountApplied: 0,
  loyaltyIsProcessing: false,
  loyaltyIsVerified: false,
  loyaltyIsAssigned: false,
  loyaltyTimeoutId: 0,
};

// Updates loyatly savings from server on page refresh
export const updateLoyaltySavings = createAsyncThunk<
  void,
  void,
  {
    dispatch: any;
    state: RootState;
  }
>('loyalty/updateLoyaltySavings', async (_: any, { dispatch, getState }) => {
  const rootState = getState();
  if (rootState.checkInfo.checkDetails && rootState.loyalty.loyaltyIsAssigned) {
    var loyaltySavingsOnItems = currency(
      rootState.checkInfo.checkDetails.PreDiscountItemsTotal || 0
    ).subtract(rootState.checkInfo.checkDetails?.Totals.ItemsAndMods).value;
    dispatch(setLoyaltyDiscountAmountApplied(loyaltySavingsOnItems));
  }
});

// TODO (L): Check dispatch type any vs AppDispatch https://github.com/rematch/rematch/issues/568
export const storeLoyaltySavings = createAsyncThunk<
  void,
  void,
  {
    rejectValue: { data: string; status: ResponseCode };
    dispatch: any;
    state: RootState;
  }
>(
  'loyalty/storeLoyaltySavings',
  async (_: any, { getState, rejectWithValue, dispatch }) => {
    const rootState = getState();
    if (rootState.checkInfo.checkDetails) {
      const loyaltySavingsOnItems = currency(
        rootState.loyalty.preLoyaltyItemsTotal
      ).subtract(rootState.checkInfo.checkDetails?.Totals.ItemsAndMods).value;
      const postLoyaltyCharges = currency(
        rootState.checkInfo.checkDetails.Totals.Tax
      ).add(rootState.checkInfo.checkDetails.Totals.ServiceCharge);
      const loyaltySavingsOnOtherCharges = currency(
        rootState.loyalty.preLoyaltyCharges
      ).subtract(postLoyaltyCharges).value;

      await setLoyaltySavingsAndItemsTotal(
        rootState.checkInfo.checkCode,
        loyaltySavingsOnItems,
        loyaltySavingsOnOtherCharges,
        rootState.loyalty.preLoyaltyItemsTotal
      );
      await dispatch(loadCheckInfo(rootState.checkInfo.checkCode));
    } else {
      // TODO: Handle this case better
      return rejectWithValue(
        createAxiosResponse(
          `Error while loyalty process for ${rootState.checkInfo.checkCode}`,
          ResponseCode.Error
        )
      );
    }
  }
);

// NCRMP-980: This covers a rare edge case when combo-card is used as GC after a non-combo loyalty is applied
// The previously assigned loyalty is sometimes unassigned by the POS without notifiying above store MP
// This action will verify if this happened and reset loyalty savings in the server accordingly
export const verifyLoyaltyStatus = createAsyncThunk<
  void,
  void,
  {
    rejectValue: { data: string; status: ResponseCode };
    dispatch: any;
    state: RootState;
  }
>('loyalty/verifyLoyaltyStatus', async (_: any, { dispatch, getState }) => {
  const rootState = getState();
  if (rootState.loyalty.loyaltyIsAssigned) {
    const { data } = await getLoyaltyStatus(rootState.checkInfo.checkCode);
    if (data.Status === 0) {
      await resetLoyaltySavingsAndItemsTotal(rootState.checkInfo.checkCode);
      await dispatch(loadCheckInfo(rootState.checkInfo.checkCode));
      dispatch(resetLoyaltyDiscountAmountApplied());
      const cookies = new Cookies();
      cookies.remove(loyaltyIsAssignedCookie, { path: '/' });
      cookies.remove(loyaltyCheckCodeCookie, { path: '/' });
    }
  }
});

export const setLoyaltyDiscountAmount = createAsyncThunk<
  void,
  void,
  {
    rejectValue: { data: string; status: ResponseCode };
    dispatch: any;
    state: RootState;
  }
>(
  'loyalty/setLoyaltyDiscountAmount',
  async (_: any, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState();
    if (rootState.checkInfo.checkDetails) {
      const loyaltySavingsOnItems = currency(
        rootState.loyalty.preLoyaltyItemsTotal
      ).subtract(rootState.checkInfo.checkDetails?.Totals.ItemsAndMods).value;
      dispatch(setLoyaltyDiscountAmountApplied(loyaltySavingsOnItems));
    } else {
      // TODO: Handle this case better
      return rejectWithValue(
        createAxiosResponse(
          `Error while loyalty process for ${rootState.checkInfo.checkCode}`,
          ResponseCode.Error
        )
      );
    }
  }
);

export const loyaltySlice = createSlice({
  name: 'loyalty',
  initialState,
  reducers: {
    resetLoyaltySlice: () => {
      return { ...initialState };
    },
    updatePreLoyaltyCharges: (state, { payload }: PayloadAction<Totals>) => {
      state.preLoyaltyCharges = currency(payload.Tax || '').add(
        payload.ServiceCharge
      ).value;
      state.preLoyaltyItemsTotal = payload.ItemsAndMods;
    },
    setLoyaltyDiscountAmountApplied: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.loyaltyDiscountAmountApplied = payload;
      state.loyaltyIsAssigned = true;
    },
    resetLoyaltyDiscountAmountApplied: (state) => {
      state.loyaltyDiscountAmountApplied = 0;
      state.loyaltyIsAssigned = false;
    },
    setLoyaltyIsProcessing: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyIsProcessing = payload;
    },
    setLoyaltyIsVerified: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyIsVerified = payload;
    },
    setLoyaltyIsAssigned: (state, { payload }: PayloadAction<boolean>) => {
      state.loyaltyIsAssigned = payload;
    },
    setLoyaltyTimeoutId: (state, { payload }: PayloadAction<number>) => {
      state.loyaltyTimeoutId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeLoyaltySavings.fulfilled, (state) => {
        state.loyaltyIsAssigned = true;
        state.loyaltyIsProcessing = false;
      })
      .addCase(storeLoyaltySavings.pending, (state) => {
        state.loyaltyIsProcessing = true;
        state.loyaltyIsVerified = true;
      });
  },
});

export const {
  updatePreLoyaltyCharges,
  setLoyaltyDiscountAmountApplied,
  resetLoyaltyDiscountAmountApplied,
  setLoyaltyIsProcessing,
  setLoyaltyIsVerified,
  setLoyaltyIsAssigned,
  resetLoyaltySlice,
  setLoyaltyTimeoutId,
} = loyaltySlice.actions;

export const selectLoyaltyState = (state: RootState) => state.loyalty;

export default loyaltySlice.reducer;
