// This is for all the console logging in the error handling
import { getMetaContent, isDev } from 'app/utils';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const proxy = 'https://localhost:44301';

const getUrl = (url: string) => {
  if (process.env.NODE_ENV === 'development') {
    const fullPath = proxy + (url.startsWith('/') ? url : '/' + url);
    return fullPath;
  }

  return url;
};
const axios = Axios.create({
  withCredentials: true,
  xsrfCookieName: '__AntiXsrfToken',
  headers: { 'anti-xsrf-token': getMetaContent('MPRequestVerificationToken') },
});

async function get<T>(url: string): Promise<AxiosResponse<T>> {
  return axios.get(getUrl(url)).catch((error) => {
    throw error.response;
  });
}

// Creates a GET request and uses axios to serialize a JS object (params) as query parameters on the request
async function getWithParams<T>(
  url: string,
  params: unknown
): Promise<AxiosResponse<T>> {
  const config: AxiosRequestConfig = {
    url: getUrl(url),
    method: 'GET',
    params: params,
  };
  return axios(config).catch((error) => {
    throw error.response;
  });
}

async function post<T>(
  url: string,
  payload: unknown,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> {
  return axios.post(getUrl(url), payload, config).catch((error) => {
    throw error.response;
  });
}

const put = (url: string, payload: unknown): Promise<AxiosResponse<unknown>> =>
  axios.put(getUrl(url), payload).catch((error) => {
    throw error;
  });

const deleteItem = (
  url: string,
  payload: unknown
): Promise<AxiosResponse<unknown>> =>
  axios.delete(getUrl(url), { data: payload }).catch((error) => {
    throw error;
  });

const handleHttpError = (error: AxiosError): void => {
  // Since we're just console logging for now just get out of it's not dev
  if (!isDev()) return;
  // TODO: How do we want to handle HTTP Errors? Snackbar? Log it somewhere else? Both?
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
};

enum ResponseCode {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  Error = 500,
}

export {
  get,
  getWithParams,
  post,
  put,
  deleteItem,
  handleHttpError,
  ResponseCode,
};
