import { isDev } from 'app/utils';
import devHeroImage from 'images/dev-hero.jpg';
import { getThemeImages } from 'images/imageUtils';
import defaultHeroImage from 'images/ncr_splash_large.png';
import styled from 'styled-components/macro';

const HeroImg = styled.img`
  flex-shrink: 0;
  min-width: 100%;
  max-width: 100%;
`;

const HeroContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    max-width: ${({ theme }) => theme.sizes.screen.small};
    margin: auto;
  }
`;

export const Hero = () => {
  return (
    <HeroContainer>
      <HeroImg
        src={
          isDev()
            ? devHeroImage
            : getThemeImages().heroImgSrc ?? defaultHeroImage
        }
      />
    </HeroContainer>
  );
};
