import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import loyaltyReducer from 'features/AddLoyaltySection/LoyaltySlice';
import checkInfoReducer from 'features/Common/checkInfoSlice';
import siteConfigReducer from 'features/Common/siteConfigurationSlice';
import themeConfigReducer from 'features/Common/themeConfigSlice';
import userPageReducer from 'features/Common/userPageSlice';
import creditCardInputReducer from 'features/CreditCardInputSection/CreditCardInputSlice';
import giftCardReducer from 'features/GiftCardSection/GiftCardSlice';
import modalReducer from 'features/Modal/modalSlice';
import drawerReducer from 'pages/Payment/Drawer/drawerSlice';
import fpAdditionalInputReducer from 'pages/Payment/FreedomPay/FPAdditionalInputSlice';
import paymentReducer from 'pages/Payment/paymentSlice';

export const store = configureStore({
  reducer: {
    checkInfo: checkInfoReducer,
    payment: paymentReducer,
    siteConfig: siteConfigReducer,
    themeConfig: themeConfigReducer,
    modal: modalReducer,
    creditCardInput: creditCardInputReducer,
    drawer: drawerReducer,
    giftCard: giftCardReducer,
    loyalty: loyaltyReducer,
    userPage: userPageReducer,
    fpAdditionalInput: fpAdditionalInputReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
