import styled from 'styled-components/macro';

export const PaymentSectionHeader = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.colors.text.sectionHeader};
  font-size: 14px;
  line-height: 16px;
  margin: 17px 0px 16px 0px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 20px;
    line-height: 23px;
    font-weight: bold;
    margin: 24px 0px 23px 0px;
  }
`;
