import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import genericCreditCardImage from 'images/card/generic-credit-card-dark.png';
import { CREDIT_CARD_TYPES } from 'types/constants';
import {
  defaultCreditCardCvvLimit,
  defaultCreditCardExpLimit,
  defaultCreditCardNumberLimit,
  defaultCreditCardZipLimit,
} from 'types/DTOs/CreditCardLimit';

export interface CreditCardInputState {
  cardType: CREDIT_CARD_TYPES;
  cardImageSrc: string;
  creditCardNumber: string;
  creditCardExp: string;
  creditCardCvv: string;
  creditCardZip: string;
  creditCardNumberLimit: number;
  creditCardExpLimit: number;
  creditCardCvvLimit: number;
  creditCardZipLimit: number;
  isCCNumberValid: boolean;
  isCCExpValid: boolean;
  isCCCvvValid: boolean;
  isCCZipValid: boolean;
  isCreditCardInputsValid: boolean;
}

const initialState: CreditCardInputState = {
  cardType: CREDIT_CARD_TYPES.NONE,
  cardImageSrc: genericCreditCardImage,
  creditCardNumber: '',
  creditCardExp: '',
  creditCardCvv: '',
  creditCardZip: '',
  creditCardNumberLimit: defaultCreditCardNumberLimit,
  creditCardExpLimit: defaultCreditCardExpLimit,
  creditCardCvvLimit: defaultCreditCardCvvLimit,
  creditCardZipLimit: defaultCreditCardZipLimit,
  isCCNumberValid: false,
  isCCExpValid: false,
  isCCCvvValid: false,
  isCCZipValid: false,
  isCreditCardInputsValid: false,
};

export const CreditCardInputSlice = createSlice({
  name: 'creditCardInput',
  initialState,
  reducers: {
    resetCreditCardInput: () => {
      return { ...initialState };
    },
    setCardType: (state, { payload }: PayloadAction<CREDIT_CARD_TYPES>) => {
      state.cardType = payload;
    },
    setCardImageSrc: (state, { payload }: PayloadAction<string>) => {
      state.cardImageSrc = payload;
    },
    setCreditCardNumber: (state, { payload }: PayloadAction<string>) => {
      state.creditCardNumber = payload;
    },
    setCreditCardExp: (state, { payload }: PayloadAction<string>) => {
      state.creditCardExp = payload;
    },
    setCreditCardCvv: (state, { payload }: PayloadAction<string>) => {
      state.creditCardCvv = payload;
    },
    setCreditCardZip: (state, { payload }: PayloadAction<string>) => {
      state.creditCardZip = payload;
    },
    setCreditCardNumberLimit: (state, { payload }: PayloadAction<number>) => {
      state.creditCardNumberLimit = payload;
    },
    setCreditCardExpLimit: (state, { payload }: PayloadAction<number>) => {
      state.creditCardExpLimit = payload;
    },
    setCreditCardCvvLimit: (state, { payload }: PayloadAction<number>) => {
      state.creditCardCvvLimit = payload;
    },
    setCreditCardZipLimit: (state, { payload }: PayloadAction<number>) => {
      state.creditCardZipLimit = payload;
    },
    setIsCCNumberValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isCCNumberValid = payload;
    },
    setIsCCExpValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isCCExpValid = payload;
    },
    setIsCCCvvValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isCCCvvValid = payload;
    },
    setIsCCZipValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isCCZipValid = payload;
    },
    setIsCreditCardInputsValid: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreditCardInputsValid = payload;
    },
    clearCreditCardState: (state) => {
      state.cardType = CREDIT_CARD_TYPES.NONE;
      state.cardImageSrc = genericCreditCardImage;
      state.creditCardNumber = '';
      state.creditCardExp = '';
      state.creditCardCvv = '';
      state.creditCardZip = '';
      state.creditCardNumberLimit = defaultCreditCardNumberLimit;
      state.creditCardExpLimit = defaultCreditCardExpLimit;
      state.creditCardCvvLimit = defaultCreditCardCvvLimit;
      state.creditCardZipLimit = defaultCreditCardZipLimit;
      state.isCCNumberValid = false;
      state.isCCExpValid = false;
      state.isCCCvvValid = false;
      state.isCCZipValid = false;
      state.isCreditCardInputsValid = false;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  resetCreditCardInput,
  setCardType,
  setCardImageSrc,
  setCreditCardNumber,
  setCreditCardExp,
  setCreditCardCvv,
  setCreditCardZip,
  setCreditCardNumberLimit,
  setCreditCardExpLimit,
  setCreditCardCvvLimit,
  setCreditCardZipLimit,
  setIsCCNumberValid,
  setIsCCExpValid,
  setIsCCCvvValid,
  setIsCCZipValid,
  setIsCreditCardInputsValid,
  clearCreditCardState,
} = CreditCardInputSlice.actions;

export const selectCreditCardInput = (state: RootState) =>
  state.creditCardInput;
export default CreditCardInputSlice.reducer;
