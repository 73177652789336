import { ResponseCode } from 'api';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { store } from 'app/store';
import { ApplyButton } from 'components/ApplyButton';
import { PaymentSectionDivider } from 'components/Divider/PaymentSectionDivider';
import { PaymentSectionHeader } from 'components/PaymentSectionHeader';
import currency from 'currency.js';
import {
  selectLoyaltyState,
  setLoyaltyDiscountAmount,
  setLoyaltyIsAssigned,
  setLoyaltyIsProcessing,
  setLoyaltyIsVerified,
  setLoyaltyTimeoutId,
  updateLoyaltySavings,
} from 'features/AddLoyaltySection/LoyaltySlice';
import { loadCheckInfo, loadPaymentInfo } from 'features/Common/checkInfoSlice';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import {
  formatPhoneNumber,
  removeHyphenFromPhoneNumber,
} from 'features/Common/validation';
import { getBalance } from 'features/GiftCardSection/GiftCardApi';
import {
  resetGiftCardApplied,
  selectGiftCardState,
  setGiftCardAmountApplied,
  setGiftCardNumber,
  setGiftCardPin,
  setIsLoyaltyASVCard,
  setTotalGiftCardBalance,
} from 'features/GiftCardSection/GiftCardSlice';
import {
  ModalOptions,
  openModal,
  setModalOptions,
} from 'features/Modal/modalSlice';
import {
  selectPaymentState,
  updatePaymentTotal,
} from 'pages/Payment/paymentSlice';
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { MpResult } from 'types';
import {
  codeCookie,
  loyaltyCheckCodeCookie,
  loyaltyIsAssignedCookie,
  loyaltyPollingPeriodMs,
  loyaltyPollingTimeoutMs,
} from 'types/constants';
import {
  ambiguousLoyaltyMessage,
  generalErrorMessages,
  loyaltyErrorMessages,
} from 'types/strings';
import { useCheckData } from '../Common/useCheckData';
import { assignLoyaltyToCheck, getLoyaltyMemberProfile } from './applyLoyalty';
import {
  LoyaltyContainer,
  LoyaltyDiscountContainer,
  LoyaltyStatusMessage,
  RemoveContainer,
  StyledMpInput,
  WelcomeContainer,
} from './LoyaltyComponents';
import { storeLoyaltySavings, updatePreLoyaltyCharges } from './LoyaltySlice';

interface AddLoyaltyProps {
  disabled: boolean;
}

export const AddLoyaltySection = ({ disabled }: AddLoyaltyProps) => {
  const cookies = new Cookies();

  // Redux Store
  const dispatch = useAppDispatch();
  const { siteConfig } = useSiteConfig();
  const { checkDetails, loyaltyIsPresentOnCheck, isPartiallyPaid } =
    useCheckData();
  const {
    loyaltyDiscountAmountApplied,
    loyaltyIsAssigned,
    loyaltyIsProcessing,
    loyaltyTimeoutId,
  } = useAppSelector(selectLoyaltyState);
  const { paymentTotal } = useAppSelector(selectPaymentState);
  const giftCardState = useAppSelector(selectGiftCardState);
  const { totalGiftCardBalance, isGiftCardAmountPaid, isGiftCardApplied } =
    useAppSelector(selectGiftCardState);

  // Local States
  const isAmbiguousCard =
    loyaltyIsPresentOnCheck && !isGiftCardAmountPaid && !loyaltyIsAssigned;
  const [loyaltyPhoneNumber, setLoyaltyPhoneNumber] = useState<string>('');
  const [loyaltyIsDisabled, setLoyaltyIsDisabled] = useState<boolean>(true);
  const [giftCardNumberState, setGiftCardNumberState] = useState<string>('');
  const [totalGiftCardBalanceState, setTotalGiftCardBalanceState] =
    useState<number>(0);
  const [isLoyaltyASVCardState, setIsLoyaltyASVCardState] =
    useState<boolean>(false);

  //#region Loyalty Cache Helpers
  const readLoyaltyInfoFromCache = () => {
    const checkCodeAfterLoyaltyCookie = cookies.get(loyaltyCheckCodeCookie);
    const checkCodeCookie = cookies.get(codeCookie);
    if (
      checkCodeCookie &&
      checkCodeAfterLoyaltyCookie &&
      checkCodeCookie === checkCodeAfterLoyaltyCookie &&
      cookies.get<boolean>(loyaltyIsAssignedCookie)
    ) {
      dispatch(setLoyaltyIsAssigned(true));
    }
  };

  const writeLoyaltyInfoToCache = (checkCode: string) => {
    cookies.set(loyaltyIsAssignedCookie, true, {
      path: '/',
      maxAge: 3600 * 24,
    });
    cookies.set(loyaltyCheckCodeCookie, checkCode, {
      path: '/',
      maxAge: 3600 * 24,
    });
  };

  const deleteLoyaltyInfoFromCache = () => {
    cookies.remove(loyaltyIsAssignedCookie, { path: '/' });
    cookies.remove(loyaltyCheckCodeCookie, { path: '/' });
  };
  //#endregion Loyalty Cache Helpers

  // Reload loyalty info from cache on refresh
  // Try to update loyalty savings, if not they will get updated when loyaltyIsPresentOnCheck has a value
  useEffect(
    () => {
      readLoyaltyInfoFromCache();
      dispatch(updateLoyaltySavings());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (loyaltyIsProcessing && loyaltyIsPresentOnCheck) {
      dispatch(setLoyaltyIsAssigned(true));
      dispatch(setLoyaltyDiscountAmount());
      clearTimeout(loyaltyTimeoutId);

      // Set GC information if the loyalty included a valid amount of GC balance
      if (isLoyaltyASVCardState && totalGiftCardBalanceState > 0) {
        removeGiftCardBalance();
        dispatch(setGiftCardNumber(giftCardNumberState));
        dispatch(setTotalGiftCardBalance(totalGiftCardBalanceState));
        dispatch(setGiftCardPin('-1'));
        dispatch(setIsLoyaltyASVCard(isLoyaltyASVCardState));
      }

      // Stop Loyalty Processing
      dispatch(setLoyaltyIsVerified(true));
      dispatch(setLoyaltyIsProcessing(false));
      writeLoyaltyInfoToCache(checkDetails?.Code || '');

      // Store values related to loyalty savings for dashboard
      dispatch(storeLoyaltySavings());
    } else if (loyaltyIsAssigned && loyaltyIsPresentOnCheck) {
      // Update loyalty savings on page refresh
      // loyaltyIsAssigned gets its value from cookie on initial render and savings get updated whenever check data has loaded
      dispatch(updateLoyaltySavings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loyaltyIsPresentOnCheck]);

  //#region Functions
  // Poll check data at regular intervals and check if loyalty info is returned from the site
  // If this fails, we are unable to verify if loyalty is assigned
  const verifyLoyaltyAssignment = (elapsedTime: number) => {
    const loyaltyState = store.getState().loyalty;
    if (
      !loyaltyState.loyaltyIsProcessing ||
      loyaltyState.loyaltyIsVerified ||
      loyaltyState.loyaltyIsAssigned
    ) {
      return;
    }

    // If the check has no loyalty after loyaltyPollingTimeoutMs, show an error.
    if (elapsedTime > loyaltyPollingTimeoutMs) {
      displayModal({
        type: 'error',
        header: 'Error',
        text: loyaltyErrorMessages.loyaltyVerificationFailure,
      });
      dispatch(setLoyaltyIsProcessing(false));
      return;
    }

    // Re-load check/payment info
    dispatch(loadCheckInfo(checkDetails?.Code || ''));
    dispatch(loadPaymentInfo(checkDetails?.Code || ''));

    const timeoutId = setTimeout(
      verifyLoyaltyAssignment,
      loyaltyPollingPeriodMs,
      elapsedTime + loyaltyPollingPeriodMs
    );
    dispatch(setLoyaltyTimeoutId(timeoutId));
  };

  const handleKeyUp = (e: any) => {
    //is backspace key?
    if (e.keyCode !== 8) {
      const number = parseInt(e.key);
      //is number key entered?
      if (number >= 0 || number <= 9 || e.key === 'v') {
        const formattedPhoneNumber = formatPhoneNumber(loyaltyPhoneNumber);
        setLoyaltyPhoneNumber(formattedPhoneNumber);
      }
    } else {
      const formattedPhoneNumber =
        removeHyphenFromPhoneNumber(loyaltyPhoneNumber);
      setLoyaltyPhoneNumber(formattedPhoneNumber);
    }
    //check if full phone number like "123-456-7890" was entered
    //Yes, set isDisabled to false
    //No, set isDisabled to true
    if (loyaltyPhoneNumber.length === 12) {
      setLoyaltyIsDisabled(false);
    } else {
      setLoyaltyIsDisabled(true);
    }
  };

  const handlePhoneNumberChange = (event: any) => {
    const input = event.target.value.replace(/\D/g, '').substring(0, 10);
    const formattedInput = formattPhoneNumber(input);
    setLoyaltyPhoneNumber(formattedInput);
    if (formattedInput.length === 12) {
      setLoyaltyIsDisabled(false);
    } else {
      setLoyaltyIsDisabled(true);
    }
  };
  const formattPhoneNumber = (phoneNumber: any) => {
    // Format phone number with hyphens
    // (Assuming the input is a 10-digit phone number)
    //return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    let formattedNumber = phoneNumber.replace(/\D/g, '').substring(0, 10);
    if (formattedNumber.length > 3) {
      formattedNumber =
        formattedNumber.substring(0, 3) + '-' + formattedNumber.substring(3);
    }
    if (formattedNumber.length > 7) {
      formattedNumber =
        formattedNumber.substring(0, 7) + '-' + formattedNumber.substring(7);
    }
    return formattedNumber;
  };
  const applyGiftCardBalance = () => {
    const balance = giftCardState.totalGiftCardBalance;
    if (balance > 0) {
      const giftCardAmountToBeApplied =
        paymentTotal < balance ? paymentTotal : balance;
      dispatch(setGiftCardAmountApplied(giftCardAmountToBeApplied));
      dispatch(updatePaymentTotal());
    }
  };

  const removeGiftCardBalance = () => {
    if (!giftCardState.isGiftCardAmountPaid) {
      dispatch(resetGiftCardApplied());
      dispatch(updatePaymentTotal());
    }
  };

  const toggleGCBalanceApply = () => {
    if (!giftCardState.isGiftCardAmountPaid) {
      if (isGiftCardApplied) {
        removeGiftCardBalance();
      } else {
        applyGiftCardBalance();
      }
    }
  };

  const checkGiftCard = async (cardNumber: string) => {
    const sendPin = '-1';
    if (siteConfig?.CompanyId && checkDetails?.SiteId) {
      await getBalance(
        siteConfig?.CompanyId,
        checkDetails?.SiteId,
        checkDetails.Code,
        cardNumber,
        sendPin
      ).then((response) => {
        if (response.responseCode === ResponseCode.OK && response.balance > 0) {
          setGiftCardNumberState(cardNumber);
          setTotalGiftCardBalanceState(response.balance);
          setIsLoyaltyASVCardState(true);
        }
      });
    }
  };

  const displayModal = ({ type, header, text }: ModalOptions) => {
    dispatch(
      setModalOptions({
        type: type,
        header: header,
        text: text,
      })
    );
    dispatch(openModal());
  };

  const assignLoyalty = async (): Promise<MpResult> => {
    try {
      if (checkDetails && checkDetails?.SiteId && siteConfig?.CompanyId) {
        const { MemberProfile, Message } = await getLoyaltyMemberProfile(
          checkDetails.SiteId,
          loyaltyPhoneNumber
        );

        if (!Message && MemberProfile) {
          await checkGiftCard(MemberProfile.CardNumber);
          dispatch(updatePreLoyaltyCharges(checkDetails.Totals));
          const loyaltyAssignmentResult = await assignLoyaltyToCheck(
            checkDetails.Code,
            MemberProfile.CardNumber,
            siteConfig?.CompanyId
          );

          if (
            loyaltyAssignmentResult.ResponseCode !== ResponseCode.OK ||
            (loyaltyAssignmentResult.Status < 0 &&
              loyaltyAssignmentResult.Status !== -4)
          ) {
            return {
              success: false,
              message:
                loyaltyAssignmentResult.Message ||
                loyaltyErrorMessages.loyaltyFailure,
            };
          } else {
            dispatch(loadPaymentInfo(checkDetails.Code));
            dispatch(loadCheckInfo(checkDetails.Code));
            setTimeout(
              verifyLoyaltyAssignment,
              loyaltyPollingPeriodMs,
              loyaltyPollingPeriodMs
            );
            return { success: true };
          }
        }
        return { success: false, message: Message };
      }
      return { success: false };
    } catch (error: any) {
      console.log(error);
      return { success: false };
    }
  };

  const handleApplyLoyalty = async () => {
    dispatch(setLoyaltyIsProcessing(true));
    dispatch(setLoyaltyIsVerified(false));
    deleteLoyaltyInfoFromCache();
    const result = await assignLoyalty();
    if (!result.success) {
      dispatch(setLoyaltyIsProcessing(false));
      displayModal({
        type: 'error',
        header: 'Error',
        text: result.message || generalErrorMessages.uhOhMessage,
      });
    }
  };
  //#endregion Functions

  return (
    <>
      <PaymentSectionDivider />
      <PaymentSectionHeader>Add Loyalty Telephone Number</PaymentSectionHeader>
      {(isAmbiguousCard || isPartiallyPaid) && !loyaltyIsProcessing ? (
        <LoyaltyStatusMessage> {ambiguousLoyaltyMessage} </LoyaltyStatusMessage>
      ) : loyaltyIsAssigned ? (
        <WelcomeContainer>
          Welcome back,{' '}
          {!checkDetails?.LoyaltyName
            ? 'Valued Guest'
            : checkDetails?.LoyaltyName.replace(/^.*, */, '')}
          !
          <LoyaltyDiscountContainer>
            You got a {currency(loyaltyDiscountAmountApplied).format()}{' '}
            discount!&nbsp;
            {giftCardState.isLoyaltyASVCard ? (
              <>
                You have a {currency(totalGiftCardBalance).format()} loyalty
                balance. You can apply it now or save it for later.
              </>
            ) : null}
          </LoyaltyDiscountContainer>
          {giftCardState.isLoyaltyASVCard ? (
            <RemoveContainer
              id="applyLoyaltyAsvBalanceButton"
              onClick={toggleGCBalanceApply}
              disabled={isGiftCardAmountPaid || disabled}
            >
              {`${isGiftCardApplied ? `SAVE FOR LATER` : `APPLY BALANCE`}`}
            </RemoveContainer>
          ) : null}
        </WelcomeContainer>
      ) : (
        <LoyaltyContainer>
          <StyledMpInput
            id="loyaltyNumberInput"
            onKeyUp={handleKeyUp}
            onChange={handlePhoneNumberChange}
            placeholder="Loyalty Telephone Number"
            maxLength={12}
            value={loyaltyPhoneNumber}
            disabled={disabled}
          />
          <ApplyButton
            id="applyLoyaltyButton"
            onClick={handleApplyLoyalty}
            disabled={loyaltyIsDisabled || disabled}
            loading={loyaltyIsProcessing}
          />
        </LoyaltyContainer>
      )}
    </>
  );
};
