import { CheckRow } from 'components/CheckRow';
import { CheckDivider } from 'components/Divider/CheckDivider';
import React from 'react';
import { Item } from 'types/DTOs';

interface CheckItemsProps {
  items: Item[];
}

const isValidCheckItem = (item: Item) => {
  return (
    item.Level === 0 &&
    item.ModCode !== 8 &&
    ((item.Type !== 2 && item.Type !== 3 && item.Type !== 4) ||
      (item.Type === 4 && item.Price > 0))
  );
};


export const CheckItems = ({ items }: CheckItemsProps) => (
  <>
    {items
      .filter((item) => isValidCheckItem(item))
      .map((item, index, array) => (
        <React.Fragment key={item.Id}>
          <CheckRow
            id="item-row"
            bold
            label={item.Name}
            price={item.Price}
            showIfZero
          />
          {index < array.length - 1 ? <CheckDivider /> : null}{' '}
        </React.Fragment>
      ))}
  </>
);
