export const defaultCreditCardNumberLimit = 16;
export const minimumCreditCardNumberLimit = 12;
export const defaultCreditCardExpLimit = 5;
export const defaultCreditCardCvvLimit = 3;
export const defaultCreditCardZipLimit = 5;

export interface CreditCardLimit {
  creditCardNumberLimit: number;
  cvvLimit: number;
}
