import { useAppDispatch, useAppSelector } from 'app/hooks';
import currency from 'currency.js';
import { selectLoyaltyState } from 'features/AddLoyaltySection/LoyaltySlice';
import {
  loadCheckInfo,
  loadPaymentInfo,
  selectCheckCode,
  setPaymentInfo,
} from 'features/Common/checkInfoSlice';
import { getAppliedPaymentInfo } from 'features/Common/commonApi';
import { useCheckData } from 'features/Common/useCheckData';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import { selectGiftCardState } from 'features/GiftCardSection/GiftCardSlice';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import { SecurePaymentProcessing } from 'features/SecurePaymentProcessing/SecurePaymentProcessing';
import {
  navigateToCheckPage,
  navigateToHomePage,
  navigateToPaymentConfirmationPage,
} from 'pages/navigation';
import { closeDrawer } from 'pages/Payment/Drawer/drawerSlice';
import {
  completeNCRSecurePaymentsTransaction,
  createNcrSecurePaymentSession,
} from 'pages/Payment/NCRSecurePayment/NCRSecurePaymentApi';
import { StyledIFrame } from 'pages/Payment/NCRSecurePayment/NCRSecurePaymentComponents';
import { CpResponseCodes } from 'pages/Payment/NCRSecurePayment/NCRSecurePaymentResponseCodes';
import {
  selectPaymentState,
  setCpSessionResult,
  setPaidAmount,
  setPaymentId,
  setPaymentStateId,
} from 'pages/Payment/paymentSlice';
import { isIframe } from 'pages/Payment/utils';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { MpResult } from 'types';
import { cpIframeId, DrawerContentOption } from 'types/constants';
import {
  NCRSecurePaymentMakeTransactionRequest,
  NCRSecurePaymentResponse,
  NCRSecurePaymentStartSessionRequest,
} from 'types/DTOs';
import { MakeSecurePaymentRequest } from 'types/DTOs/NCRSecurePayment/MakeSecurePaymentRequest';
import { PaymentMethod } from 'types/DTOs/SiteConfiguration';
import { generalErrorMessages, paymentErrorMessages } from 'types/strings';

interface NCRSecurePaymentIframeProps {
  setIsPaymentProcess: (value: React.SetStateAction<boolean>) => void;
  clearSensitiveData: (clearGCInputs: boolean) => void;
  setDrawerContents: (value: React.SetStateAction<DrawerContentOption>) => void;
  setIsIframeLoading: (value: boolean) => void;
}

export const NCRSecurePaymentIframe = ({
  setIsPaymentProcess,
  clearSensitiveData,
  setDrawerContents,
  setIsIframeLoading,
}: NCRSecurePaymentIframeProps) => {
  //#region Redux Store
  const dispatch = useAppDispatch();
  const { siteConfig } = useSiteConfig();
  const { checkDetails, appliedPaymentInfo, checkDataError } = useCheckData();
  const payment = useAppSelector(selectPaymentState);
  const loyalty = useAppSelector(selectLoyaltyState);
  const checkCode = useAppSelector(selectCheckCode);
  const giftCard = useAppSelector(selectGiftCardState);
  const { paymentStateId } = useAppSelector(selectPaymentState);
  //#endregion Redux Store

  //#region Local States
  const history = useHistory();
  const [iframeUrl, setIframeUrl] = useState<string>();
  const paymentStoreId = String(siteConfig?.CPStoreNumber) || '';
  const tokenRequest: NCRSecurePaymentStartSessionRequest = useMemo(
    () => ({
      ReturnUrl: `${window.location.origin}/callback`,
      CompanyId: siteConfig?.CompanyId,
      CheckId: checkDetails?.CheckId,
      CheckCode: checkCode,
      Balance: giftCard.isGiftCardAmountPaid
        ? giftCard.balanceRemaining
        : appliedPaymentInfo?.Balance,
      Tip: giftCard.isGiftCardAmountPaid
        ? currency(payment.selectedTip.amount).subtract(giftCard.tipPaid).value
        : payment.selectedTip.amount,
      Gratuity: appliedPaymentInfo?.Gratuity,
      Tax: appliedPaymentInfo?.Tax,
      // TODO: Why is this not on siteConfig??
      SiteId: checkDetails?.SiteId,
      PaymentStoreId: paymentStoreId,
      IsSaveNewCard: false,
      // TODO: Look at this again when loyalty is implemented
      LoyaltySavings: loyalty.loyaltyDiscountAmountApplied,
      IsLoyaltyApplied: loyalty.loyaltyIsAssigned,
      TotalAmount: payment.paymentTotal,
      PaymentMethod: PaymentMethod.CP,
      TerminalId: '',
    }),
    [
      siteConfig?.CompanyId,
      checkDetails?.CheckId,
      checkDetails?.SiteId,
      checkCode,
      giftCard.isGiftCardAmountPaid,
      giftCard.balanceRemaining,
      giftCard.tipPaid,
      appliedPaymentInfo?.Balance,
      appliedPaymentInfo?.Gratuity,
      appliedPaymentInfo?.Tax,
      payment.selectedTip.amount,
      loyalty.loyaltyDiscountAmountApplied,
      loyalty.loyaltyIsAssigned,
      payment.paymentTotal,
      paymentStoreId,
    ]
  );
  //#endregion Local States

  //#region Start Secure Payment Session
  const handleSessionError = useCallback(
    (errorMessage?: string) => {
      // Close drawer and open error display
      dispatch(closeDrawer());
      setDrawerContents(DrawerContentOption.CPIFRAME);
      setIsIframeLoading(false);

      dispatch(
        setModalOptions({
          type: 'error',
          header: 'Error',
          text: errorMessage,
        })
      );
      dispatch(openModal());
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const handleCreateCpSession = (response: NCRSecurePaymentResponse) => {
    if (response.RequestUrl === null) {
      handleSessionError(paymentErrorMessages.cannotStartSecurePaymentSession);
    } else {
      setIframeUrl(response.RequestUrl);
      dispatch(setPaymentStateId(response.PaymentStateId));
      setIsIframeLoading(false);
    }
  };

  // Creating Secure Payment Session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await createNcrSecurePaymentSession(tokenRequest);

        // TODO: We need a better status code instead of -1 and 1 and error message from backend
        if (response.status !== 200) {
          throw new Error();
        }

        handleCreateCpSession(response.data);
      } catch (error) {
        handleSessionError(
          paymentErrorMessages.cannotStartSecurePaymentSession
        );
      }
    };
    fetchData();

    return () => {
      setIframeUrl('');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleSessionError, tokenRequest]);
  //#endregion Start Secure Payment Session

  //#region Complete Session
  const makeCPPayment = async (
    request: MakeSecurePaymentRequest
  ): Promise<MpResult> => {
    try {
      const completeSessionRequest: NCRSecurePaymentMakeTransactionRequest = {
        sessionId: request.sessionId || '',
        sessionKey: '',
        paymentStateId: paymentStateId || '',
        checkAssignmentId: checkDetails?.Id || 0,
        siteId: `${checkDetails?.SiteId || 0}`,
        companyId: `${siteConfig?.CompanyId || 0}`,
        paymentKey: '',
        methodType: '',
        maskedCardNumber: '',
        expMonth: '',
        expYear: '',
        zipCode: '',
        paymentMethod: PaymentMethod.CP,
        cardholderFirstName: '',
        cardholderLastName: '',
      };

      const response = await completeNCRSecurePaymentsTransaction(
        completeSessionRequest
      );

      if (response.data.Status > 0) {
        // This should check if there is a balance due and update all the UI accordingly
        const { data } = await getAppliedPaymentInfo(checkCode);

        if (data === null) {
          // If failed to refresh paymentInfo, send user back to check page with an error modal
          navigateToCheckPage(history);
          return {
            success: false,
            message: paymentErrorMessages.refreshPaymentInfoFailure,
          };
        } else {
          if (
            data.Status === -600 &&
            response.data.PaymentId !== undefined &&
            response.data.PaymentId <= 0
          ) {
            return {
              success: false,
              message: response.data.Message,
            };
          }
          dispatch(setPaymentInfo(data));
          dispatch(setPaidAmount(data.AmountPaid));

          if (data.Balance > 0) {
            dispatch(loadCheckInfo(checkCode));
            dispatch(loadPaymentInfo(checkCode));
            // Do not display error modal if balance is remaining
            return {
              success: false,
              message: response.data.Message,
            };
          }

          dispatch(setPaymentId(response.data.PaymentId));
          return Promise.resolve({
            success: true,
            message: response.data.Message,
          });
        }
      }
      return Promise.resolve({
        success: false,
        message: response.data.Message,
      });
    } catch (error: any) {
      return Promise.resolve({
        success: false,
        message: error?.message || 'An error occurred',
      });
    } finally {
      dispatch(closeDrawer());
    }
  };

  const handleNCRSecurePaymentIframeCompleteSession = async (
    statusCode: string,
    sessionId?: string
  ) => {
    setIsPaymentProcess(true);
    setIsIframeLoading(true);
    dispatch(setCpSessionResult({ sessionId, statusCode }));
    try {
      if (statusCode === '100' && sessionId) {
        setDrawerContents(DrawerContentOption.PROCESSING);

        const request: MakeSecurePaymentRequest = { sessionId: sessionId };
        const result = await makeCPPayment(request);
        if (result.success) {
          clearSensitiveData(true);
          navigateToPaymentConfirmationPage(history);
        } else {
          handleSessionError(
            result.message || paymentErrorMessages.processingFailure
          );
        }
      } else if (statusCode === CpResponseCodes.CustomerAborted) {
        dispatch(closeDrawer());
        setDrawerContents(DrawerContentOption.CPIFRAME);
      }
      // TODO are there actual errors that correlate to the CP status codes
      else {
        handleSessionError(paymentErrorMessages.incorrectDataEntered);
      }
    } catch (error) {
      handleSessionError();
    } finally {
      setIsPaymentProcess(false);
    }
  };

  const handleIframeLoad = (e: SyntheticEvent) => {
    const paymentMethod = siteConfig?.PaymentMethod;
    const element = document?.getElementById(cpIframeId);

    if (isIframe(element)) {
      // Trying to access contentWindow of a cross-origin iframe will error to protect from XSS attacks
      // This will happen when the iframe loads with the CP content, but not when they callback with our returnUrl
      try {
        if (paymentMethod === PaymentMethod.CP) {
          const src = element.contentWindow?.location.href;
          const url = new URL(src || '');
          const params = new URLSearchParams(url.searchParams);
          const sessionId = params.get('sessionId') || undefined;
          const statusCode = params.get('statusCode') || '0';
          handleNCRSecurePaymentIframeCompleteSession(statusCode, sessionId);
          return;
        }
      } catch (error) {
        // Since we expect the error when the CP iframe loads, we just ignore it.
        return;
      }
    }
  };
  //#endregion Complete Session

  //#region useEffect
  useEffect(() => {
    if (checkDataError) {
      handleSessionError(generalErrorMessages.checkLoadFailure);
      navigateToHomePage(history);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDataError, history]);
  //#endregion useEffect

  return (
    <>
      {iframeUrl ? (
        <StyledIFrame
          title="NCR Secure Payment Portal"
          id={cpIframeId}
          src={iframeUrl}
          onLoad={handleIframeLoad}
        />
      ) : (
        <SecurePaymentProcessing
          messageDelayMs={2000}
          message="Creating session with Secure Payment"
        />
      )}
    </>
  );
};
