import currency from 'currency.js';
import amexCreditCardImage from 'images/card/amex.png';
import dinerCreditCardImage from 'images/card/diner.png';
import discoverCreditCardImage from 'images/card/disc.png';
import genericCreditCardImage from 'images/card/generic-credit-card-dark.png';
import jcbCreditCardImage from 'images/card/jcb.png';
import mcCreditCardImage from 'images/card/mc.png';
import visaCreditCardImage from 'images/card/visa.png';
import toNumber from 'lodash/toNumber';
import { CREDIT_CARD_TYPES } from 'types/constants';
import { CreditCardLimit } from 'types/DTOs/CreditCardLimit';

export const priceIsValidForDisplay = (
  value: string | number | undefined
): boolean => {
  return toNumber(value) > 0;
};

// Returns a card type of a card number based on regex.
export const getCardType = (cardNumber: string): CREDIT_CARD_TYPES => {
  var re = new RegExp('^4');
  if (cardNumber.match(re) != null) return CREDIT_CARD_TYPES.VISA;

  re = new RegExp('^(34|37)');
  if (cardNumber.match(re) != null) return CREDIT_CARD_TYPES.AMEX;

  re = new RegExp('^5[1-5]');
  if (cardNumber.match(re) != null) return CREDIT_CARD_TYPES.MASTERCARD;

  re = new RegExp('^6011');
  if (cardNumber.match(re) != null) return CREDIT_CARD_TYPES.DISCOVER;

  return CREDIT_CARD_TYPES.NONE;
};

// Returns a image source directory of a card type's assigned card image.
export const getImageSrcForCardType = (cardType: CREDIT_CARD_TYPES): string => {
  if (cardType === CREDIT_CARD_TYPES.VISA) {
    return visaCreditCardImage;
  } else if (cardType === CREDIT_CARD_TYPES.MASTERCARD) {
    return mcCreditCardImage;
  } else if (cardType === CREDIT_CARD_TYPES.AMEX) {
    return amexCreditCardImage;
  } else if (cardType === CREDIT_CARD_TYPES.DINERS) {
    return dinerCreditCardImage;
  } else if (cardType === CREDIT_CARD_TYPES.DISCOVER) {
    return discoverCreditCardImage;
  } else if (cardType === CREDIT_CARD_TYPES.JCB) {
    return jcbCreditCardImage;
  }
  return genericCreditCardImage;
};

// Returns a limit length of a credit card number and cvv.
export const getCreditCardNumberLimits = (
  cardType: CREDIT_CARD_TYPES
): CreditCardLimit => {
  return {
    creditCardNumberLimit: cardType === CREDIT_CARD_TYPES.AMEX ? 15 : 16,
    cvvLimit: cardType === CREDIT_CARD_TYPES.AMEX ? 4 : 3,
  };
};

// Focus on next input in credit card input section
export const focusOnNextInput = (
  currentInputId: string,
  nextInputId: string
) => {
  document.getElementById(currentInputId)?.blur();
  document.getElementById(nextInputId)?.focus();
};

// Some check values should be hidden if they are 0, but some we need to show even if they are 0
const showPrice = (
  value: string | number | undefined,
  showIfZero: boolean | undefined
) => priceIsValidForDisplay(value) || showIfZero;

// The currency lib won't accept undefined as an argument
export const getPriceView = (
  value: string | number | undefined,
  showAsNegative: boolean | undefined,
  showIfZero: boolean | undefined
) =>
  value !== undefined && showPrice(value, showIfZero)
    ? currency(showAsNegative ? -value : value, {
        negativePattern: '-!#',
      }).format()
    : null;

// Adding required js scripts for FreedomPay
export const addFreedomPayScript = (value:string|null|undefined) => {
  // Deleting scripts just in case
  document.getElementById('mp_fp_hpc')?.remove();
  // Adding required scripts for Freedom Pay
  const hpc = document.createElement('script');
  hpc.setAttribute('id', 'mp_fp_hpc');
  hpc.src = value + '/api/v1.5/cdn/hpc_min.js';
  hpc.async = true;
  document.body.appendChild(hpc);
};

// Adding required js scripts for FreedomPay
export const addGooglePayScript = (onGooglePayLoad: any) => {
  // Deleting scripts just in case
  document.getElementById('mp_fp_gpay')?.remove();

  // Adding required scripts for Freedom Pay
  const gPay = document.createElement('script');
  gPay.setAttribute('id', 'mp_fp_gpay');

  gPay.src = 'https://pay.google.com/gp/p/js/pay.js';
  gPay.async = true;
  gPay.onload = onGooglePayLoad;

  document.body.appendChild(gPay);
};

// Removing FreedomPay js scripts
export const removeFreedomPayScript = () => {
  document.getElementById('mp_fp_hpc')?.remove();
};

