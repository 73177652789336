import { useAppDispatch } from 'app/hooks';
import { focusOnNextInput } from 'features/Common/utils';
import { validateCreditCardExp } from 'features/Common/validation';
import { StyledCreditCardExpInput } from 'features/CreditCardInputSection/CreditCardInputComponents';
import {
  setCreditCardExp,
  setIsCCExpValid,
} from 'features/CreditCardInputSection/CreditCardInputSlice';
import { useCreditCardInput } from 'features/CreditCardInputSection/useCreditCardInput';
import { openModal, setModalOptions } from 'features/Modal/modalSlice';
import { KeyboardEvent, useCallback } from 'react';
import { creditCardCvvInputId, creditCardExpInputId } from 'types/constants';

interface CreditCardExpInputProps {
  loading: boolean;
  disabled?: boolean;
}

export const CreditCardExpInput = ({
  loading,
  disabled,
}: CreditCardExpInputProps) => {
  const dispatch = useAppDispatch();
  const { creditCardExp, creditCardExpLimit } = useCreditCardInput();

  // check for validity of CC exp when user has entered exp date but moved to another input
  const handleBlur = () => {
    if (creditCardExp.length > 0) {
      const errorMessage = validateCreditCardExp(creditCardExp);
      if (errorMessage) {
        dispatch(
          setModalOptions({
            type: 'error',
            header: 'Invalid Credit Card',
            text: errorMessage,
          })
        );
        dispatch(openModal());
      }
    }
  };

  const handleKeyUp = useCallback(
    (event: KeyboardEvent<HTMLDivElement>): void => {
      dispatch(setIsCCExpValid(false));
      const { key } = event;
      const isKeyBackspace = key === 'Backspace';

      // Update exp value for display
      const newCreditCardExpLength = creditCardExp.length;
      if (isKeyBackspace && newCreditCardExpLength > 0) {
        if (newCreditCardExpLength === 2) {
          dispatch(setCreditCardExp(creditCardExp.substring(0, 1))); // Excluding the 2nd number and / in the middle
        }
      } else if (newCreditCardExpLength < 5) {
        if (
          newCreditCardExpLength >= 2 &&
          creditCardExp.substring(0, 2) > '12'
        ) {
          dispatch(setCreditCardExp(creditCardExp.substring(0, 2))); // Verifying if the month is correct
        } else if (
          newCreditCardExpLength === 1 &&
          +creditCardExp > 1 &&
          +creditCardExp < 10
        ) {
          dispatch(setCreditCardExp('0' + creditCardExp + '/')); // Adding 0 automatically right before 2-9
        } else if (newCreditCardExpLength === 2) {
          dispatch(setCreditCardExp(creditCardExp + '/')); // Adding / automatically right after the 2nd number is typed
        }
      }

      if (
        creditCardExp.length === creditCardExpLimit &&
        !validateCreditCardExp(creditCardExp)
      ) {
        // If valid exp, focus on cvv input
        dispatch(setIsCCExpValid(true));
        focusOnNextInput(creditCardExpInputId, creditCardCvvInputId);
      }
    },
    [creditCardExp, creditCardExpLimit, dispatch]
  );

  return (
    <StyledCreditCardExpInput
      id="ccInfo_expiration"
      onChange={(event) => dispatch(setCreditCardExp(event.target.value))}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      placeholder="MM/YY"
      maxLength={creditCardExpLimit}
      value={creditCardExp}
      type="tel"
      disabled={disabled || loading}
    />
  );
};
