import { getApi } from 'api/utils';
import {
  FreedomPayKeyRequest,
  NCRSecurePaymentMakeTransactionRequest,
  NCRSecurePaymentStartSessionRequest,
} from 'types/DTOs';

const api = getApi();

export const createNcrSecurePaymentSession = (
  request: NCRSecurePaymentStartSessionRequest
) => api.createNcrSecurePaymentSession(request);

export const completeNCRSecurePaymentsTransaction = (
  request: NCRSecurePaymentMakeTransactionRequest
) => api.completeNCRSecurePaymentsTransaction(request);

export const sendPaymentKey = (request: FreedomPayKeyRequest) =>
  api.sendPaymentKey(request);
