import { PaymentCardType } from 'types/constants';
import { Payment } from 'types/DTOs';
import { Tender } from 'types/DTOs/Tender';

export const getPaymentTypeName = (
  payment: Payment,
  tenders: Tender[] | undefined
) => {
  var paymentType = 'Partial Payment';

  // Try to translate with card type (check info from Couchbase)
  if (payment?.Type > 0) {
    switch (payment?.Type) {
      case PaymentCardType.Credit:
        paymentType = PaymentCardType[PaymentCardType.Credit];
        break;
      case PaymentCardType.GiftCard:
        paymentType = 'Gift Card';
        break;
      case PaymentCardType.Visa:
        paymentType = PaymentCardType[PaymentCardType.Visa];
        break;
      case PaymentCardType.MasterCard:
        paymentType = PaymentCardType[PaymentCardType.MasterCard];
        break;
      case PaymentCardType.Amex:
        paymentType = PaymentCardType[PaymentCardType.Amex];
        break;
      case PaymentCardType.Discover:
        paymentType = PaymentCardType[PaymentCardType.Discover];
        break;
      case PaymentCardType.Diners:
        paymentType = PaymentCardType[PaymentCardType.Diners];
        break;
      case PaymentCardType.JCB:
        paymentType = PaymentCardType[PaymentCardType.JCB];
        break;
    }
  }

  // Try to translate with card type (check info from AOGateway)
  if (payment?.TenderId > 0) {
    tenders?.forEach((tender) => {
      if (tender.Id === payment?.TenderId) {
        if (tender.Type === 'GiftCard') paymentType = 'Gift Card';
        paymentType = tender.Type;
      }
    });
  }

  // Add 4 last digits of used card
  if (payment?.AccountNumber) {
    paymentType +=
      ' -' +
      payment?.AccountNumber.substring(
        payment?.AccountNumber.length - 4,
        payment?.AccountNumber.length
      );
  }

  return paymentType;
};
