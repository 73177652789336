import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useCheckData } from 'features/Common/useCheckData';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import { addFreedomPayScript, addGooglePayScript } from 'features/Common/utils';
import { SecurePaymentProcessing } from 'features/SecurePaymentProcessing/SecurePaymentProcessing';
import { navigateToHomePage } from 'pages/navigation';
import { FPAdditionalInputs } from 'pages/Payment/FreedomPay/FPAdditionalInputs';
import {
  resetFPAdditionalInput,
  selectFPAdditionalInput,
  setBillingCity,
  setBillingEmailAddress,
  setBillingPhoneNumber,
  setBillingState,
  setBillingStreetAddress,
  setBillingZipCode,
  setCardholderFirstName,
  setCardholderLastName,
  setFpSessionKey,
  setIsFpButtonDisabled,
  setIsFpLoading,
} from 'pages/Payment/FreedomPay/FPAdditionalInputSlice';
import { FpDrawerButton } from 'pages/Payment/FreedomPay/FPBillingInfoContinueButton';
import { FpIframeDivider } from 'pages/Payment/FreedomPay/FpIframeDivider';
import { FreedomPayButton } from 'pages/Payment/FreedomPay/FreedomPayButton';
import {
  StyledFPGoogleApplePayIframe,
  StyledFPGoogleDiv,
  StyledFPIFrameSectionDiv,
  StyledFreedomPayIFrame,
} from 'pages/Payment/FreedomPay/FreedomPayIframeComponents';
import { createNcrSecurePaymentSession } from 'pages/Payment/NCRSecurePayment/NCRSecurePaymentApi';
import {
  selectPaymentState,
  setPaymentStateId,
} from 'pages/Payment/paymentSlice';
import {
  generateIsoStateString,
  getFreedomPayIframeSrc,
  isCardholderFirstNameValid,
  isCardholderLastNameValid,
  isFpCityValid,
  isFpEmailAddressValid,
  isFpPhoneNumberValid,
  isFpStateValid,
  isFpStreetAddressValid,
  isFpZipCodeValid,
  isIframe,
  removeDashFromPhoneNumber,
} from 'pages/Payment/utils';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  applePayButtonStyle,
  fpBillingInfoContinueButtonId,
  fpGPayDivId,
  fpIframeId,
  fpPaymentMethodSelectionPageId,
  fpPayWithCreditCardButtonId,
  googlePayButtonStyle,
} from 'types/constants';
import {
  NCRSecurePaymentResponse,
  NCRSecurePaymentStartSessionRequest,
} from 'types/DTOs';
import {
  FPInitType,
  FPPageType,
  PaymentMethod,
} from 'types/DTOs/SiteConfiguration';
import { generalErrorMessages, paymentErrorMessages } from 'types/strings';

interface FreedomPayIframeProps {
  isDrawerOpen: boolean;
  setIsIframeLoading: (value: boolean) => void;
  handlePaymentError: (paymentMethod: PaymentMethod, errorMsg?: string) => void;
  tokenRequest: NCRSecurePaymentStartSessionRequest;
  is3dsEnabled: boolean;
  isGooglePayEnabled: boolean;
  isApplePayEnabled: boolean;
  isApplePaySupported: boolean;
  updateGooglePayRef: (sessionKey: string, paymentStateId: string) => void;
  updateApplePayRef: (sessionKey: string, paymentStateId: string) => void;
  processGooglePayPaymentToken: (
    token: string,
    cardIssuer: string,
    maskedCardNumber: string,
    cardholderFirstName: string
  ) => void;
}

export const FreedomPayIframe = ({
  tokenRequest,
  isDrawerOpen,
  setIsIframeLoading,
  handlePaymentError,
  is3dsEnabled,
  isGooglePayEnabled,
  isApplePayEnabled,
  isApplePaySupported,
  updateGooglePayRef,
  updateApplePayRef,
  processGooglePayPaymentToken,
}: FreedomPayIframeProps) => {
  //#region Redux Store
  const dispatch = useAppDispatch();
  const { siteConfig } = useSiteConfig();
  const { checkDataError, checkDetails } = useCheckData();
  const {
    fpIframeHeight,
    cardholderFirstName,
    cardholderLastName,
    billingEmailAddress,
    billingPhoneNumber,
    billingStreetAddress,
    billingCity,
    billingState,
    billingZipCode,
    isFpLoading,
    isFpButtonDisabled,
  } = useAppSelector(selectFPAdditionalInput);
  const { paymentTotal } = useAppSelector(selectPaymentState);
  //#endregion Redux Store

  //#region Local States
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<FPPageType>(
    FPPageType.LOADING
  );
  const [loadingMsg, setLoadingMsg] = useState<string>('');
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const [isIframeLoaded, setIsIframeLoaded] = useState<boolean>(false);
  const fpApiEndpoint = useMemo(() => siteConfig?.FPApiEndpoint, [siteConfig]);

  const [googlePayBtnUrl, setGooglePayBtnUrl] = useState<string>('');
  const [applePayBtnUrl, setApplePayBtnUrl] = useState<string>('');

  const isFpBillingInfoInputsValid = useMemo(() => {
    if (
      cardholderFirstName === undefined ||
      cardholderLastName === undefined ||
      billingEmailAddress === undefined ||
      billingPhoneNumber === undefined ||
      billingStreetAddress === undefined ||
      billingCity === undefined ||
      billingState === undefined ||
      billingZipCode === undefined
    )
      return false;
    return (
      isCardholderFirstNameValid(cardholderFirstName) &&
      isCardholderLastNameValid(cardholderLastName) &&
      isFpEmailAddressValid(billingEmailAddress) &&
      isFpPhoneNumberValid(billingPhoneNumber) &&
      isFpStreetAddressValid(billingStreetAddress) &&
      isFpCityValid(billingCity) &&
      isFpStateValid(billingState) &&
      isFpZipCodeValid(billingZipCode)
    );
  }, [
    billingCity,
    billingEmailAddress,
    billingPhoneNumber,
    billingState,
    billingStreetAddress,
    billingZipCode,
    cardholderFirstName,
    cardholderLastName,
  ]);

  const isFpIframeInputsValid = useMemo(() => {
    if (is3dsEnabled) {
      return true;
    } else {
      if (cardholderFirstName === undefined || cardholderLastName === undefined)
        return false;
      return (
        isCardholderFirstNameValid(cardholderFirstName) &&
        isCardholderLastNameValid(cardholderLastName)
      );
    }
  }, [cardholderFirstName, cardholderLastName, is3dsEnabled]);
  //#endregion Local States

  //#region Utils
  const handleFpPaymentError = (errorMsg?: string) => {
    handlePaymentError(PaymentMethod.FP, errorMsg);
    dispatch(resetFPAdditionalInput());
  };
  //#endregion Utils

  //#region GooglePay Direct Integration

  //#################################################################################
  //loading
  //#################################################################################
  let googlePayClient: google.payments.api.PaymentsClient;

  const onGooglePayLoaded = () => {
    if (document.getElementsByClassName('gpay-button-fill').length > 0) {
      return;
    }

    googlePayClient = getGooglePaymentsClient();
    googlePayClient
      .isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(function (response) {
        if (response.result) {
          addGooglePayButton();
        }
      })
      .catch(function (err) {
        console.error('loading google pay client was failed');
      });
  };

  const addGooglePayButton = () => {
    const button = googlePayClient.createButton({
      buttonColor: 'black',
      buttonType: 'pay',
      buttonSizeMode: 'fill',
      onClick: onGooglePaymentButtonClicked,
    });

    if (
      document.getElementsByClassName('gpay-card-info-container-fill')
        .length === 0
    ) {
      document.getElementById(fpGPayDivId)?.appendChild(button);
    }
    setGooglePayBtnUrl('gpay_has_been_set');
  };

  const getGooglePaymentsClient = () => {
    if (googlePayClient === null || googlePayClient === undefined) {
      let fpGooglePayEnv: google.payments.api.Environment = 'TEST';

      if (
        siteConfig?.FPGooglePayEnv !== null &&
        siteConfig?.FPGooglePayEnv !== undefined &&
        siteConfig?.FPGooglePayEnv === 'PRODUCTION'
      ) {
        fpGooglePayEnv = 'PRODUCTION';
      }

      googlePayClient = new google.payments.api.PaymentsClient({
        environment: fpGooglePayEnv, //only 'PRODUCTION' or 'TEST'
        paymentDataCallbacks: {
          onPaymentAuthorized: onPaymentAuthorized,
        },
      });
    }

    return googlePayClient;
  };

  //#################################################################################
  //payment request
  //#################################################################################
  const apiVersion = 2;
  const apiVersionMinor = 0;
  const allowedAuthMethods: google.payments.api.CardAuthMethod[] = [
    'PAN_ONLY',
    'CRYPTOGRAM_3DS',
  ];
  const allowedCardNetworks: google.payments.api.CardNetwork[] = [
    'AMEX',
    'DISCOVER',
    'INTERAC',
    'JCB',
    'MASTERCARD',
    'VISA',
  ];

  const isReadyToPayPaymentMethodSpecification: google.payments.api.IsReadyToPayPaymentMethodSpecification =
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: allowedAuthMethods,
        allowedCardNetworks: allowedCardNetworks,
      },
    };

  const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification =
    {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'freedompay',
        gatewayMerchantId: `${siteConfig?.FPGatewayMerchantId}`,
      },
    };

  const paymentMethodSpecification: google.payments.api.PaymentMethodSpecification =
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: allowedAuthMethods,
        allowedCardNetworks: allowedCardNetworks,
      },
      tokenizationSpecification: tokenizationSpecification,
    };

  const getGoogleIsReadyToPayRequest = () => {
    var isReadyToPayRequest: google.payments.api.IsReadyToPayRequest = {
      apiVersion: apiVersion,
      apiVersionMinor: apiVersionMinor,
      allowedPaymentMethods: [isReadyToPayPaymentMethodSpecification],
    };

    return isReadyToPayRequest;
  };

  const getGoogleTransactionInfo = (): google.payments.api.TransactionInfo => {
    return {
      currencyCode: 'USD',
      countryCode: 'US',
      displayItems: [
        {
          label: 'Subtotal',
          type: 'SUBTOTAL',
          price: `${checkDetails?.Subtotal}`,
        },
        {
          label: 'Tax',
          type: 'TAX',
          price: `${checkDetails?.Tax}`,
        },
      ],
      totalPriceStatus: 'FINAL',
      totalPrice: `${paymentTotal}`,
      totalPriceLabel: 'Total',
    };
  };

  const getGooglePaymentDataRequest = () => {
    const paymentDataRequest: google.payments.api.PaymentDataRequest = {
      merchantInfo: {
        merchantId: `${siteConfig?.FPGooglePayMerchantId}`,
        merchantName: `${siteConfig?.StoreName}`,
      },
      apiVersion: apiVersion,
      apiVersionMinor: apiVersionMinor,
      allowedPaymentMethods: [paymentMethodSpecification],
      transactionInfo: getGoogleTransactionInfo(),
      shippingAddressRequired: true,
      callbackIntents: ['PAYMENT_AUTHORIZATION'],
    };

    return paymentDataRequest;
  };

  //#################################################################################
  //process payment
  //#################################################################################

  const onGooglePaymentButtonClicked = () => {
    const paymentDataRequest = getGooglePaymentDataRequest();
    try {
      googlePayClient.loadPaymentData(paymentDataRequest);
    } catch (e) {
      console.log('got error while loading gpay client: ');
    }
  };

  //got authorization with payment token.
  //need to send the token to FP and receive payment key to process a payment.
  const onPaymentAuthorized = (
    paymentData: google.payments.api.PaymentData
  ) => {
    const result: google.payments.api.PaymentAuthorizationResult = {
      transactionState: 'ERROR',
    };

    try {
      let token = paymentData.paymentMethodData.tokenizationData.token;

      const cardIssuer = paymentData.paymentMethodData.info?.cardNetwork ?? ''; //visa, amex, ...
      const maskedCardNumber = paymentData.paymentMethodData.description ?? ''; //1234*****7890
      const cardholderName = paymentData.shippingAddress?.name ?? ''; //US User

      processGooglePayPaymentToken(token, cardIssuer, maskedCardNumber, cardholderName);
    } catch (e) {
      console.log('got an error while processing gpay auth');
      return result;
    }

    result.transactionState = 'SUCCESS';

    return result;
  };

  //#endregion GooglePay Direct Integration

  //#region Handlers
  const handleIframeLoad = (e: SyntheticEvent) => {
    const element = document?.getElementById(fpIframeId);
    if (isIframe(element)) {
      try {
        setIsIframeLoaded(true);

        return;
      } catch (error) {
        // Since we expect the error when the FP iframe loads, we just ignore it.
        return;
      }
    }
  };

  const handleCreateFpSession = (response: NCRSecurePaymentResponse) => {
    if (!response) {
      handleFpPaymentError(
        paymentErrorMessages.cannotStartSecurePaymentSession
      );
      console.log('[FP IFrame Debug] response is null');
      return;
    }
    const freedomPayUrl = getFreedomPayIframeSrc(response);

    // If failed to grab fp iframeUrl, sessionKey, or paymentStateId, close the drawer with error modal
    if (!freedomPayUrl || !response.SessionKey || !response.PaymentStateId) {
      handleFpPaymentError(
        paymentErrorMessages.cannotStartSecurePaymentSession
      );
      console.log('[FP IFrame Debug] Failed to grab fp iframeUrl.');
      return;
    }

    setIframeUrl(freedomPayUrl);
    dispatch(setPaymentStateId(response.PaymentStateId));
    dispatch(setFpSessionKey(response.SessionKey));
  };

  const fpPayBtnClickListener = useCallback(() => {
    if (isFpIframeInputsValid) {
      dispatch(setIsFpLoading(true));
      dispatch(setIsFpButtonDisabled(true));

      const fpIframeElement = document?.getElementById(fpIframeId);
      if (isIframe(fpIframeElement) && fpApiEndpoint) {
        fpIframeElement.contentWindow?.postMessage(
          'getPaymentKeys',
          fpApiEndpoint
        );
      } else {
        handleFpPaymentError();
      }
    } else {
      // Set cardholder first/last name an empty string if they are undefined
      if (cardholderFirstName === undefined) {
        setCardholderFirstName('');
      }
      if (cardholderLastName === undefined) {
        setCardholderLastName('');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFpIframeInputsValid]);

  // BillingInfo page button click listener
  const fpBillingInfoContinueBtnClickListener = useCallback(async () => {
    // If input values are invalid, don't move forward
    if (!isFpBillingInfoInputsValid) {
      if (cardholderFirstName === undefined) {
        dispatch(setCardholderFirstName(''));
      }
      if (cardholderLastName === undefined) {
        dispatch(setCardholderLastName(''));
      }
      if (billingEmailAddress === undefined) {
        dispatch(setBillingEmailAddress(''));
      }
      if (billingPhoneNumber === undefined) {
        dispatch(setBillingPhoneNumber(''));
      }
      if (billingStreetAddress === undefined) {
        dispatch(setBillingStreetAddress(''));
      }
      if (billingCity === undefined) {
        dispatch(setBillingCity(''));
      }
      if (billingState === undefined) {
        dispatch(setBillingState(''));
      }
      if (billingZipCode === undefined) {
        dispatch(setBillingZipCode(''));
      }
      return;
    }

    setIsIframeLoading(true);
    setCurrentPage(FPPageType.LOADING);
    setLoadingMsg('Creating session with Secure Payment');

    const isInitSuccess = await initFpIframe();
    if (isInitSuccess) {
      addFreedomPayScript(siteConfig?.FPApiEndpoint);
      setCurrentPage(FPPageType.CREDIT_CARD_INFO_WITH_3DS);
    } else {
      handleFpPaymentError(
        paymentErrorMessages.cannotStartSecurePaymentSession
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    billingCity,
    billingEmailAddress,
    billingPhoneNumber,
    billingState,
    billingStreetAddress,
    billingZipCode,
    cardholderFirstName,
    cardholderLastName,
    isFpBillingInfoInputsValid,
  ]);

  const fpPayWithCCBtnClickListener = useCallback(async () => {
    if (is3dsEnabled) {
      setCurrentPage(FPPageType.BILLING_INFO);
      return;
    }

    setIsIframeLoading(true);
    setCurrentPage(FPPageType.LOADING);
    setLoadingMsg('Creating session with Secure Payment');
    const isInitSuccess = await initFpIframe();
    if (isInitSuccess) {
      setCurrentPage(FPPageType.CREDIT_CARD_INFO_WITHOUT_3DS);
    } else {
      handleFpPaymentError(
        paymentErrorMessages.cannotStartSecurePaymentSession
      );
    }
    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Creating FreedomPay session
  const initFpIframe = useCallback(async (): Promise<boolean> => {
    var tempTokenRequest = tokenRequest;

    try {
      tempTokenRequest.Styles = undefined;
      if (is3dsEnabled) {
        tempTokenRequest.FPInitType = FPInitType.ThreeDS;
        tempTokenRequest.BillingInformation = {
          FirstName: cardholderFirstName || '',
          LastName: cardholderLastName || '',
          EmailAddress: billingEmailAddress || '',
          PhoneNumber: removeDashFromPhoneNumber(billingPhoneNumber || ''),
          StreetAddress: billingStreetAddress || '',
          City: billingCity || '',
          State: generateIsoStateString(billingState || ''),
          ZipCode: billingZipCode || '',
        };
      } else {
        tempTokenRequest.FPInitType = FPInitType.Standard;
      }

      const response = await createNcrSecurePaymentSession(tempTokenRequest);
      if (response.status !== 200) throw new Error();

      handleCreateFpSession(response.data);
      return true;
    } catch (error) {
      console.log(
        `[FP IFrame Debug] error occurred while createNcrSecurePaymentSession.`
      );
      return false;
    } finally {
      setIsIframeLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cardholderFirstName,
    cardholderLastName,
    billingEmailAddress,
    billingPhoneNumber,
    billingStreetAddress,
    billingCity,
    billingState,
    billingZipCode,
  ]);
  //#endregion Handlers

  //#region useEffect
  useEffect(() => {
    if (!isDrawerOpen) {
      setIsIframeLoaded(false);
    } else {
      // Initialize Google/ApplePay buttons
      const initGoogleApplePayButtons = async (): Promise<boolean> => {
        const tempTokenRequest = tokenRequest;
        var isGooglePayLoaded = false;
        var isApplePayLoaded = false;

        try {
          // Initialize GooglePay
          // Now we create google pay button directly from GPay API directly.
          // However, we need to get session id from FP to send google pay key to FP.
          if (isGooglePayEnabled) {
            tempTokenRequest.FPInitType = FPInitType.GooglePay;
            tempTokenRequest.Styles = googlePayButtonStyle;
            const gPayResponse = await createNcrSecurePaymentSession(
              tempTokenRequest
            );

            const googlePayBtnUrl = getFreedomPayIframeSrc(gPayResponse.data);

            if (
              googlePayBtnUrl &&
              gPayResponse.data.SessionKey &&
              gPayResponse.data.PaymentStateId
            ) {
              updateGooglePayRef(
                gPayResponse.data.SessionKey,
                gPayResponse.data.PaymentStateId
              );
              setGooglePayBtnUrl(googlePayBtnUrl);
              isGooglePayLoaded = true;
            }
          }

          // Initialize ApplePay
          if (isApplePayEnabled) {
            tempTokenRequest.FPInitType = FPInitType.ApplePay;
            tempTokenRequest.Styles = applePayButtonStyle;
            const aPayResponse = await createNcrSecurePaymentSession(
              tempTokenRequest
            );

            const applePayBtnUrl = getFreedomPayIframeSrc(aPayResponse.data);

            if (
              applePayBtnUrl &&
              aPayResponse.data.SessionKey &&
              aPayResponse.data.PaymentStateId
            ) {
              updateApplePayRef(
                aPayResponse.data.SessionKey,
                aPayResponse.data.PaymentStateId
              );
              setApplePayBtnUrl(applePayBtnUrl);
              isApplePayLoaded = true;
            }
          }

          return isGooglePayLoaded || isApplePayLoaded;
        } catch (error) {
          console.log(error);
          return false;
        }
      };

      const handleFpIframeProcess = async () => {
        if (is3dsEnabled) {
          setCurrentPage(FPPageType.BILLING_INFO);
          return;
        }

        setLoadingMsg('Creating session with Secure Payment');
        const isInitSuccess = await initFpIframe();
        if (isInitSuccess) {
          setCurrentPage(FPPageType.CREDIT_CARD_INFO_WITHOUT_3DS);
        } else {
          handleFpPaymentError(
            paymentErrorMessages.cannotStartSecurePaymentSession
          );
        }
        return;
      };

      try {
        setIsIframeLoading(true);

        // When GooglePay or ApplePay is enabled
        if (isGooglePayEnabled || isApplePayEnabled) {
          initGoogleApplePayButtons().then((isInitSuccess) => {
            if (isInitSuccess) {
              setCurrentPage(FPPageType.PAYMENT_METHOD_SELECTION);
              setIsIframeLoading(false);
            } else {
              // If failed to load both, navigate to FP iframe page.
              handleFpIframeProcess();
            }
          });

          return;
        }

        // When GooglePay and ApplePay are disabled
        handleFpIframeProcess();
      } catch (error) {
        handleFpPaymentError(
          paymentErrorMessages.cannotStartSecurePaymentSession
        );
      } finally {
        setIsIframeLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  // Add/Remove event listener for freedompay
  useEffect(() => {
    if (checkDataError) {
      navigateToHomePage(history);
      handleFpPaymentError(generalErrorMessages.checkLoadFailure);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDataError, history]);
  //#endregion useEffect

  //#region FreedomPay Drawer Page Render
  const showPaymentMethodSelectionPage = useCallback(
    () => {
      addGooglePayScript(onGooglePayLoaded);
      return (
        <div id={fpPaymentMethodSelectionPageId}>
          {googlePayBtnUrl && (
            <StyledFPGoogleDiv id={fpGPayDivId} disabled={isFpButtonDisabled} />
          )}
          {isApplePaySupported && applePayBtnUrl && (
            <StyledFPGoogleApplePayIframe
              src={applePayBtnUrl}
              disabled={isFpButtonDisabled}
            />
          )}
          <FpIframeDivider>or</FpIframeDivider>
          <FpDrawerButton
            id={fpPayWithCreditCardButtonId}
            onClick={fpPayWithCCBtnClickListener}
            buttonMsg={'Pay With Credit Card'}
            disabled={isFpButtonDisabled}
          />
        </div>
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googlePayBtnUrl, applePayBtnUrl, isApplePaySupported, isFpButtonDisabled]
  );

  const showBillingInfoPage = useCallback(() => {
    return (
      <>
        <FPAdditionalInputs is3dsEnabled={is3dsEnabled} />
        <FpDrawerButton
          id={fpBillingInfoContinueButtonId}
          onClick={fpBillingInfoContinueBtnClickListener}
          buttonMsg={'Continue to Card Info'}
          disabled={isFpButtonDisabled}
        />
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fpBillingInfoContinueBtnClickListener]);

  const showCCInfoWith3dsPage = useCallback(
    (
      iframeUrl: string,
      iframeHeight: string,
      isIframeLoaded: boolean,
      isFpLoading: boolean
    ) => {
      return (
        <StyledFPIFrameSectionDiv disabled={isFpButtonDisabled}>
          <StyledFreedomPayIFrame
            title="NCR Secure Payment Portal"
            id={fpIframeId}
            src={iframeUrl}
            onLoad={handleIframeLoad}
            height={iframeHeight}
            is3dsEnabled={is3dsEnabled}
          />
          {isIframeLoaded && (
            <FreedomPayButton
              onClick={fpPayBtnClickListener}
              disabled={isFpButtonDisabled}
              loading={isFpLoading}
            />
          )}
        </StyledFPIFrameSectionDiv>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fpPayBtnClickListener, isFpButtonDisabled]
  );

  const showCCInfoWithout3dsPage = useCallback(
    (
      iframeUrl: string,
      iframeHeight: string,
      isIframeLoaded: boolean,
      isFpLoading: boolean
    ) => {
      return (
        <StyledFPIFrameSectionDiv disabled={isFpButtonDisabled}>
          {isIframeLoaded && <FPAdditionalInputs is3dsEnabled={false} />}
          <StyledFreedomPayIFrame
            title="NCR Secure Payment Portal"
            id={fpIframeId}
            src={iframeUrl}
            onLoad={handleIframeLoad}
            height={iframeHeight}
            is3dsEnabled={is3dsEnabled}
          />
          {isIframeLoaded && (
            <FreedomPayButton
              onClick={fpPayBtnClickListener}
              disabled={isFpButtonDisabled}
              loading={isFpLoading}
            />
          )}
        </StyledFPIFrameSectionDiv>
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fpPayBtnClickListener, isFpButtonDisabled]
  );

  const showLoadingPage = (message: string) => {
    return <SecurePaymentProcessing messageDelayMs={2000} message={message} />;
  };

  const showFpIframeContent = useCallback(
    (page: FPPageType) => {
      switch (page) {
        case FPPageType.PAYMENT_METHOD_SELECTION:
          return showPaymentMethodSelectionPage();
        case FPPageType.BILLING_INFO:
          return showBillingInfoPage();
        case FPPageType.CREDIT_CARD_INFO_WITH_3DS:
          return showCCInfoWith3dsPage(
            iframeUrl,
            fpIframeHeight,
            isIframeLoaded,
            isFpLoading
          );
        case FPPageType.CREDIT_CARD_INFO_WITHOUT_3DS:
          return showCCInfoWithout3dsPage(
            iframeUrl,
            fpIframeHeight,
            isIframeLoaded,
            isFpLoading
          );
        case FPPageType.LOADING:
          return showLoadingPage(loadingMsg);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      iframeUrl,
      fpIframeHeight,
      isIframeLoaded,
      loadingMsg,
      isFpLoading,
      showPaymentMethodSelectionPage,
      showBillingInfoPage,
      showCCInfoWith3dsPage,
      showCCInfoWithout3dsPage,
    ]
  );
  //#endregion FreedomPay Drawer Page Render

  return showFpIframeContent(currentPage);
};
