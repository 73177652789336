import styled from 'styled-components/macro';

export const TipBaseSubText = styled.div`
  font-size: 12px;
  line-height: 14px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    font-size: 16px;
    line-height: 21px;
  }
`;
