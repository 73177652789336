import React from 'react';
import styled from 'styled-components/macro';

const Callback = styled.div`
  background-color: ${({ theme }) => theme.colors.body.solid};
  height: 1000px;
  width: 1000px;
  overflow: hidden;
`;

export const CallbackPage = () => <Callback />;
