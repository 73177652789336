import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MpButton } from 'components/MpButton';
import { selectLoyaltyState } from 'features/AddLoyaltySection/LoyaltySlice';
import { BaseMpModal } from 'features/Modal/BaseMpModal';
import { closeModal } from 'features/Modal/modalSlice';
import { setTipAndUpdatePaymentTotal } from 'pages/Payment/paymentSlice';
import { useTipCalculator } from 'pages/Payment/useTipCalculator';
import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { customTipIds } from 'types/constants';
import { TipBase } from 'types/DTOs';
import { DOLLARS, PERCENT } from 'types/DTOs/TipBase';
import { CustomTipInput } from './CustomTipInput';

const ModalButton = styled(MpButton)`
  border: 1px solid ${({ theme }) => theme.colors.buttonNeutral.border};
`;

const StyledCustomTipInput = styled(CustomTipInput)`
  margin-bottom: 16px;
  font-size: 16px;
  padding: 16px;
  min-width: 256px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-bottom: 32px;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

const RadioBox = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 54px;
  border: 1px solid ${({ theme }) => theme.colors.input.border};
  background-color: ${({ active, theme }) =>
    active
      ? theme.colors.button.background
      : theme.colors.buttonNeutral.background};
`;

const LeftRadioBox = styled(RadioBox)`
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const RightRadioBox = styled(RadioBox)`
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const { CUSTOM } = customTipIds;

interface CustomTipModalProps {
  open: boolean;
}

export const CustomTipModal = ({ open }: CustomTipModalProps) => {
  const [customTipInputValue, setcustomTipInputValue] = useState<number>(0);
  const [customTipBase, setCustomTipBase] = useState<TipBase>(DOLLARS);
  const { getPercentFromDollarValue, getDollarValueFromPercent } =
    useTipCalculator();
  const dispatch = useAppDispatch();
  const { loyaltyIsAssigned } = useAppSelector(selectLoyaltyState);

  const resetCustomTipModalValues = () => {
    setcustomTipInputValue(0);
    setCustomTipBase(DOLLARS);
  };

  const setDollarTip = () => {
    const adjustedInputValue = customTipInputValue / 100;
    dispatch(
      setTipAndUpdatePaymentTotal({
        id: CUSTOM,
        amount: adjustedInputValue || 0,
        type: DOLLARS,
        percent: getPercentFromDollarValue(adjustedInputValue || 0),
      })
    );
  };

  const setPercentTip = () => {
    dispatch(
      setTipAndUpdatePaymentTotal({
        id: CUSTOM,
        amount: getDollarValueFromPercent(customTipInputValue) || 0,
        type: PERCENT,
        percent: customTipInputValue,
      })
    );
  };

  const handleSaveClick = () => {
    customTipBase === DOLLARS ? setDollarTip() : setPercentTip();
    dispatch(closeModal());
  };

  const handleCustomTipChange = (value: number) => {
    setcustomTipInputValue(value);
  };

  const handleTipBaseChange = (base: TipBase) => {
    setCustomTipBase(base);
  };

  // Resetting custom tip modal when a loyalty is applied
  useEffect(() => {
    if (loyaltyIsAssigned) {
      resetCustomTipModalValues();
    }
  }, [loyaltyIsAssigned])

  return (
    <BaseMpModal open={open}>
      <StyledCustomTipInput
        id="customTipInput"
        onValueChange={handleCustomTipChange}
        value={customTipInputValue}
        tipBase={customTipBase}
      />
      <RadioContainer>
        <LeftRadioBox
          id="tipBasedPercentButton"
          active={customTipBase === PERCENT}
          onClick={() => handleTipBaseChange(PERCENT)}
        >
          %
        </LeftRadioBox>
        <RightRadioBox
          id="tipBasedDollarsButton"
          active={customTipBase === DOLLARS}
          onClick={() => handleTipBaseChange(DOLLARS)}
        >
          $
        </RightRadioBox>
      </RadioContainer>
      <ModalButton id="applyCustomTipButton" onClick={handleSaveClick}>
        Save
      </ModalButton>
    </BaseMpModal>
  );
};
