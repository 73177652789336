import { AxiosResponse } from 'axios';
import { List } from 'lodash';
import {
  AppliedPaymentInfo,
  Check,
  FreedomPayKeyRequest,
  NCRSecurePaymentMakeTransactionRequest,
  NCRSecurePaymentResponse,
  NCRSecurePaymentStartSessionRequest,
  SiteConfiguration,
  ThemeConfigSettings,
} from 'types/DTOs';
import { GiftCardPayResponse } from 'types/DTOs/GiftCard';
import { GetGiftCardBalanceResponse } from 'types/DTOs/GiftCard/GetGiftCardBalanceResponse';
import { MemberProfileResponse } from 'types/DTOs/MemberProfileResponse';
import { FreedomPayKeyResponse } from 'types/DTOs/NCRSecurePayment/FreedomPayKeyResponse';
import { PaymentResponse } from 'types/DTOs/Payment';
import {
  creditCardPaymentRequest,
  PayNoBalanceRequest,
} from 'types/DTOs/PaymentRequests';
import { Response } from 'types/DTOs/Response';
import { get, getWithParams, post } from '.';
import { GiftCardPayRequest } from './../types/DTOs/GiftCard/GiftCardPayRequest';

//#region POST
const logRefCode = (
  refCode: string,
  checkCode: string
): Promise<AxiosResponse> => post(`/check/ts/${checkCode}/ref`, { refCode });

const setPreDiscountItemsTotal = (
  code: string,
  preDiscountItemsTotal: number
): Promise<AxiosResponse<Response>> =>
  post(`/check/ts/${code}/setprediscountitemstotal`, {
    preDiscountItemsTotal,
  });
//#endregion POST

//#region GET Information
// TODO may get some performance out of using etag
const getCheck = async (
  checkCode: string,
  firstLoad: boolean
): Promise<AxiosResponse<Check>> =>
  getWithParams<Check>(`/check/ts/${checkCode}`, { firstLoad });

const getSiteConfig = async (
  checkCode: string
): Promise<AxiosResponse<SiteConfiguration>> =>
  get<SiteConfiguration>(`/site/${checkCode}`);

const getThemeConfig = (
  subdomain: string
): Promise<AxiosResponse<ThemeConfigSettings>> =>
  get<ThemeConfigSettings>(`/theme/${subdomain}/config`);

const getAppliedPaymentInfo = (
  checkCode: string
): Promise<AxiosResponse<AppliedPaymentInfo>> =>
  get<AppliedPaymentInfo>(`/check/ts/${checkCode}/payment`);
//#endregion GET Information

//#region Loyalty Calls
const getLoyaltyByPhoneNumber = (
  siteId: number,
  phoneNumber: string
): Promise<AxiosResponse<MemberProfileResponse>> =>
  get<MemberProfileResponse>(`/loyalty/${siteId}/${phoneNumber}`);

const assignLoyalty = (
  code: string,
  number: string,
  companyId: number
): Promise<AxiosResponse<Response>> =>
  post(
    `/loyalty/`,
    { code, number },
    { headers: { 'X-API-CompanyCode': companyId, 'X-SessionId': '' } }
  );

const getLoyaltyStatus = (code: string): Promise<AxiosResponse<Response>> =>
  get<Response>(`/loyalty/${code}/status`);

const setLoyaltySavingsAndItemsTotal = (
  code: string,
  loyaltySavingsOnItems: number,
  loyaltySavingsOnOtherCharges: number,
  itemsTotal: number
): Promise<AxiosResponse<void>> =>
  post(`check/ts/${code}/setloyaltysavings`, {
    loyaltySavingsOnItems,
    loyaltySavingsOnOtherCharges,
    itemsTotal,
  });

const resetLoyaltySavingsAndItemsTotal = (
  code: string
): Promise<AxiosResponse<void>> =>
  post(`/check/ts/${code}/resetloyaltysavings`, {});
//#endregion Loyalty Calls

//#region Gift Card
const getEpinPrefixList = (
  companyCode: string
): Promise<AxiosResponse<List<string>>> =>
  get<List<string>>(`/card/prefixlist/${companyCode}`);

const getGiftCardBalance = (
  companyId: number,
  siteId: number,
  checkCode: string,
  cardNumber: string,
  pin: string
): Promise<AxiosResponse<GetGiftCardBalanceResponse>> =>
  get<GetGiftCardBalanceResponse>(
    `/card/giftcardbalance/${companyId}/${siteId}/${checkCode}/${cardNumber}/${pin}`
  );

const giftCardPay = (
  request: GiftCardPayRequest
): Promise<AxiosResponse<GiftCardPayResponse>> =>
  post<GiftCardPayResponse>(`/check/ts/branded/gift/${request.code}`, request);
//#endregion Gift Card

//#region Electornic Draft Captures (EDC)
const newCreditCardPayment = (
  request: creditCardPaymentRequest
): Promise<AxiosResponse<PaymentResponse>> =>
  post(`/check/ts/anon/${request.code}`, request);

const payNoBalance = (
  request: PayNoBalanceRequest
): Promise<AxiosResponse<PaymentResponse>> =>
  post(`/check/ts/${request.code}/cleanup`, request);

const getAmountPaid = (
  paymentId: any
): Promise<AxiosResponse<AppliedPaymentInfo>> =>
  get<AppliedPaymentInfo>(`/check/ts/${paymentId}/payment/amount`);
//#endregion Electornic Draft Captures (EDC)

//#region NCR Secure Payment (CP and FP)
const createNcrSecurePaymentSession = (
  request: NCRSecurePaymentStartSessionRequest
): Promise<AxiosResponse<NCRSecurePaymentResponse>> =>
  post<NCRSecurePaymentResponse>(
    '/ncrsecurepayment/createncrsecurepaymentsessiontoken',
    request
  );

const completeNCRSecurePaymentsTransaction = (
  request: NCRSecurePaymentMakeTransactionRequest
): Promise<AxiosResponse<NCRSecurePaymentResponse>> =>
  post<NCRSecurePaymentResponse>('/ncrsecurepayment/maketransthemed', request);

//this is used to send payment token to FP for google pay
//and receive payment key from FP
const sendPaymentKey = (
  request: FreedomPayKeyRequest
): Promise<AxiosResponse<FreedomPayKeyResponse>> =>
  post<FreedomPayKeyResponse>('/ncrsecurepayment/sendpaymentkey', request);

//#endregion NCR Secure Payment (CP and FP)

//#region Email Receipt
const sendReceiptOnDemand = async (
  checkCode: string,
  paymentId: number,
  emailAddress: string,
  subdomain: string,
  UTCOffset: number
): Promise<AxiosResponse> =>
  post(`/account/receiptOnDemand`, {
    checkCode,
    paymentId,
    emailAddress,
    subdomain,
    UTCOffset,
  });
//#endregion Email Receipt

export const mpApi = {
  getCheck,
  getSiteConfig,
  getThemeConfig,
  logRefCode,
  getAppliedPaymentInfo,
  getEpinPrefixList,
  getGiftCardBalance,
  giftCardPay,
  payNoBalance,
  newCreditCardPayment,
  createNcrSecurePaymentSession,
  completeNCRSecurePaymentsTransaction,
  sendPaymentKey,
  sendReceiptOnDemand,
  getLoyaltyByPhoneNumber,
  assignLoyalty,
  getLoyaltyStatus,
  setPreDiscountItemsTotal,
  setLoyaltySavingsAndItemsTotal,
  resetLoyaltySavingsAndItemsTotal,
  getAmountPaid,
};
