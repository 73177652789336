import { PaymentSectionDivider } from 'components/Divider/PaymentSectionDivider';
import {
  LoadingComponentContainer,
  LoadingSkeleton,
  LoadingSkeletonButton,
} from 'components/PaymentSectionLoading/PaymentSectionLoadingComponents';

export const PaymentSectionLoading = () => {
  return (
    <>
      <PaymentSectionDivider />
      <LoadingComponentContainer>
        <LoadingSkeleton width={'80%'} />
        <LoadingSkeleton width={'60%'} />
        <LoadingSkeleton width={'60%'} />
      </LoadingComponentContainer>
      <PaymentSectionDivider />
      <LoadingComponentContainer>
        <LoadingSkeleton width={'60%'} />
        <LoadingSkeleton width={'50%'} />
        <LoadingSkeleton width={'40%'} />
      </LoadingComponentContainer>
      <PaymentSectionDivider />
      <LoadingComponentContainer>
        <LoadingSkeleton width={'80%'} />
        <LoadingSkeleton width={'40%'} />
        <LoadingSkeleton width={'60%'} />
      </LoadingComponentContainer>
      <LoadingSkeletonButton width={'100%'} height={'100%'} />
    </>
  );
};
