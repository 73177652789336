import styled from "styled-components";

export const SuccessfulPaymentContainer = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    margin-top: 16px;
  }
`;

export const PaidSuccessIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
  margin-left: 20px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizes.screen.small}) {
    width: 80px;
    height: 80px;
    margin-right: 28px;
    margin-left: 20px;
  }
`;