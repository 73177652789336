import styled from 'styled-components/macro';

interface ReceiptSentProps {
  emailId?: string | null;
}

const ReceiptSentContainer = styled.div`
    font-size: 18px;
    text-align: left;
    flex: 0;
    flex-direction: column;
    @media only screen and (min-width: ${({ theme }) =>
        theme.sizes.screen.small}) {
      font-size: 24px;
    }
  `;

export const ReceiptSent = (props: ReceiptSentProps) => {
  return (
    <ReceiptSentContainer>Receipt sent to {props.emailId}</ReceiptSentContainer>
  );
};
