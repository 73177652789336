import {
  StyledFPInput,
  StyledFPInputDiv,
  StyledFPInputLabel,
  StyledFPInputValidationDiv,
} from 'pages/Payment/FreedomPay/FreedomPayIframeComponents';
import React from 'react';

interface FPAdditionalInputProps {
  title: string;
  id: string;
  className: string;
  placeholder: string;
  type: string;
  isValid: boolean;
  errorMsg: string;
  maxLength?: number;
  value?: string;
  onBlur: (name: string) => void;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const FPAdditionalInput = ({
  title,
  id,
  className,
  placeholder,
  type,
  isValid,
  errorMsg,
  maxLength,
  value,
  onBlur,
  onKeyUp,
  onChange,
}: FPAdditionalInputProps) => {
  return (
    <>
      <StyledFPInputLabel>{title}</StyledFPInputLabel>
      <StyledFPInputDiv>
        <StyledFPInput
          id={id}
          className={className}
          placeholder={placeholder}
          type={type}
          maxLength={maxLength}
          value={value}
          onKeyUp={onKeyUp}
          onChange={onChange}
          onBlur={(event) => onBlur(event.target.value)}
        />
      </StyledFPInputDiv>
      {!isValid && (
        <StyledFPInputValidationDiv>{errorMsg}</StyledFPInputValidationDiv>
      )}
    </>
  );
};
