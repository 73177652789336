import { CardImage } from 'components/CardImage';
import { PaymentSectionDivider } from 'components/Divider/PaymentSectionDivider';
import { PaymentSectionHeader } from 'components/PaymentSectionHeader';
import { CreditCardCvvInput } from 'features/CreditCardInputSection/CreditCardCvvInput';
import { CreditCardExpInput } from 'features/CreditCardInputSection/CreditCardExpInput';
import {
  CreditCardInputContainer,
  FirstLineForCreditCardInputContainer,
  SecondLineForCreditCardInputContainer,
} from 'features/CreditCardInputSection/CreditCardInputComponents';
import { CreditCardNumberInput } from 'features/CreditCardInputSection/CreditCardNumberInput';
import { CreditCardZipInput } from 'features/CreditCardInputSection/CreditCardZipInput';

interface CreditCardInputSectionProps {
  loading: boolean;
  disabled?: boolean;
}

export const CreditCardInputSection = ({
  loading,
  disabled,
}: CreditCardInputSectionProps) => {
  return (
    <>
      <PaymentSectionDivider />
      <PaymentSectionHeader>Enter Payment Information</PaymentSectionHeader>
      <CreditCardInputContainer disabled={disabled}>
        <FirstLineForCreditCardInputContainer>
          <CardImage />
          <CreditCardNumberInput loading={loading} disabled={disabled} />
          <CreditCardExpInput loading={loading} disabled={disabled} />
          <CreditCardCvvInput loading={loading} disabled={disabled} />
        </FirstLineForCreditCardInputContainer>
        <SecondLineForCreditCardInputContainer>
          <CreditCardZipInput loading={loading} disabled={disabled} />
        </SecondLineForCreditCardInputContainer>
      </CreditCardInputContainer>
    </>
  );
};
