import { isDemo } from 'app/utils';
import { History } from 'history';
import { paymentConfirmationRoute, checkviewRoute, paymentRoute, rootRoute } from '../routes';

export const navigateToHomePage = (history: History) =>
  isDemo() ? history.push(`/demo/${rootRoute}`) : history.push(`/${rootRoute}`);

export const navigateToCheckPage = (history: History) =>
  isDemo()
    ? history.push(`/demo/${checkviewRoute}`)
    : history.push(`/${checkviewRoute}`);

export const navigateToPaymentPage = (history: History) =>
  isDemo()
    ? history.push(`/demo/${paymentRoute}`)
    : history.push(`/${paymentRoute}`);

export const navigateToPaymentConfirmationPage = (history: History) =>
  isDemo()
    ? history.push(`/demo/${paymentConfirmationRoute}`)
    : history.push(`/${paymentConfirmationRoute}`);
